import * as React from 'react';
import { Label, Separator } from "@fluentui/react"
import PractitionerProtocolActivityComponent from "../PractitionerProtocolActivity/PractitionerProtocolActivity.Component"
import PractitionerProtocolVisitList from "../PractitionerProtocolVisitList/PracittionerProtocolVisit.List.Component"
import { useState } from 'react';
import { Slider } from '@fluentui/react-components';

import './PractitionerActivityFeed.Styles.css';
import ProtocolSelectorComponent from '../../../commons/component/Inputs/ProtocolSelector/ProtocolSelectorComponent';
import { Protocol } from '../../../models/Entities/Protocols/Protocol';

export const PractitionerActivityFeedComponent = (props: { practitioner: string }) => {

    const [Months, setMonths] = useState<number>(1);
    const [Protocols, setProtocols] = useState<number[]>([]);

    const controls = (
        <div className='ActivityControls'>
            <div>
                <Label htmlFor={'ActivityFeedSlider'}>
                    Meses de actividad [ {Months} meses ]
                </Label>

                <Slider
                    size="small"
                    value={Months}
                    min={1}
                    max={12}
                    onChange={(e, value) => setMonths(value.value as number)}
                    id={'ActivityFeedSlider'}
                />
            </div>
            <Separator vertical />
            <div>
                <Label htmlFor={'ProtocolSelector'}>Protocolos</Label>
                <ProtocolSelectorComponent selectionLimit={undefined} onChangeSelection={(protocolsSelected: Protocol[] | undefined) => setProtocols(protocolsSelected?.map((protocol: Protocol) => { return protocol.id}) || [])} />
            </div>
        </div>
    );

    return (
        <>
            {controls}
            <Separator />
            <div className='CalendarContainer'>
                <PractitionerProtocolActivityComponent PractitionerId={props.practitioner} Months={Months} protocolsIds={Protocols} />
            </div>
            <Separator />
            <PractitionerProtocolVisitList PractitionerId={props.practitioner} Months={Months} protocolsIds={Protocols} />
        </>
    )
}