import { FontIcon, FontSizes, Label, Separator, Text } from "@fluentui/react";

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// styles
import './AccountBalance.Styles.css';

export const AccountBalanceComponent = () => {
    const [t] = useTranslation();
    return (
        <>
            <div className="SaldoTotal">
                <small>{t('accountBalance.currentBalance')}</small>
                <Label style={{fontSize: FontSizes.size42}}><span style={{fontSize: FontSizes.size18}}>$</span>10,453,345.00<span style={{fontSize: FontSizes.size18}}>(usd)</span></Label>
            </div>
            <Separator />
            <div>
                <div className="SaldoTotal">
                    <small>{t('accountBalance.outstanding')}</small>
                    <Label style={{fontSize: FontSizes.size32}}>0,00<span style={{fontSize: FontSizes.size18}}>$</span></Label>
                </div>
                <div className="SaldoTotal">
                    <small>{t('accountBalance.pendingCollection')}</small>
                    <Label style={{fontSize: FontSizes.size32}}><span style={{fontSize: FontSizes.size18}}>$</span>2,756,935.00<span style={{fontSize: FontSizes.size18}}>(usd)</span></Label>
                </div>
            </div>
            {/* <div className="textInfo">
                <Text><FontIcon iconName="info" style={{color: 'blue', fontSize: FontSizes.mini}} /> En este momento no hay operaciones registradas</Text>
            </div> */}
        </>
    );
}

export default AccountBalanceComponent;