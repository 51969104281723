import { IResult } from '../models/HttpResult';
import { ContractPractitionerAssociation } from '../models/Entities/Contracts/ContractPractitionerAssociation';
import { NewContractPractitionerAssociation } from '../models/Entities/Contracts/NewContractPractitionerAssociation';

//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';


const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'practitioners/contracts/';

export const GetPractitionerContracts = async (
  practitionerId: string
, token: string): Promise<ContractPractitionerAssociation[]> => {

  const RequestURL: string = BaseURL + practitionerId + '/contracts';

  try {
    const Response: ContractPractitionerAssociation[] = await fetch(RequestURL,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ContractPractitionerAssociation[]>>;
        }
      })
      .then((contracts: IResult<ContractPractitionerAssociation[]>) => {
        return contracts.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetPractitionerContractById = async (
  contractId: number
, token: string): Promise<ContractPractitionerAssociation> => {

  const RequestURL: string = BaseURL + '/contracts/' + contractId;

  try {
    const Response: ContractPractitionerAssociation = await fetch(RequestURL,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ContractPractitionerAssociation>>;
        }
      })
      .then((contract: IResult<ContractPractitionerAssociation>) => {
        return contract.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const CreatePractitionerContract = async (
  practitionerContract: NewContractPractitionerAssociation
, token: string): Promise<ContractPractitionerAssociation> => {
  const RequestURL: string = BaseURL + 'contracts';
  
  try {
    const Response: ContractPractitionerAssociation = await fetch(RequestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(practitionerContract)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ContractPractitionerAssociation>>;
        }
      })
      .then((contract: IResult<ContractPractitionerAssociation>) => {
        return contract.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const UpdatePractitionerContract = async (
  practitionerContract: ContractPractitionerAssociation
, token: string): Promise<ContractPractitionerAssociation> => {
  const RequestURL: string = BaseURL + 'contracts/';
 
  try {
    const Response: ContractPractitionerAssociation = await fetch(RequestURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(practitionerContract)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ContractPractitionerAssociation>>;
        }
      })
      .then((contract: IResult<ContractPractitionerAssociation>) => {
        return contract.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DeletePractitionerContract = async (
  contractId: number
, token: string): Promise<ContractPractitionerAssociation> => {
  const RequestURL: string = BaseURL + 'contract/' + contractId;
 
  try {
    const Response: ContractPractitionerAssociation = await fetch(RequestURL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ContractPractitionerAssociation>>;
        }
      })
      .then((contract: IResult<ContractPractitionerAssociation>) => {
        return contract.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );
    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
