import { Action, Reducer } from 'redux';
import { ContractConceptState, ContractConceptUnloadState } from '../states/ContractConceptState';
import { ContractConcept } from '../../models/Entities/Contracts/ContractConcept';

// actions
import * as Actions from '../actions';

// functions
import * as Functions from '../../functions/common';
import { ContractConceptConcept } from '../../models/Entities/Contracts/ContractConceptConcept';
import { ContractConceptPaymentSchedule } from '../../models/Entities/Contracts/ContractConceptPaymentSchedule';


type KnownAction = 
| Actions.ContractConcepts.ContractConcept_GetAllBycontractId_Request_Action
| Actions.ContractConcepts.ContractConcept_GetAllBycontractId_Receive_Action
| Actions.ContractConcepts.ContractConcept_GetAllBycontractId_Fail_Action
| Actions.ContractConcepts.ContractConcept_UpdateAllByContractId_Request_Action
| Actions.ContractConcepts.ContractConcept_UpdateAllByContractId_Receive_Action
| Actions.ContractConcepts.ContractConcept_UpdateAllByContractId_Fail_Action
| Actions.ContractConcepts.ContractConcept_GetById_Request_Action
| Actions.ContractConcepts.ContractConcept_GetById_Receive_Action
| Actions.ContractConcepts.ContractConcept_GetById_Fail_Action
| Actions.ContractConcepts.ContractConcept_Add_Request_Action
| Actions.ContractConcepts.ContractConcept_Add_Receive_Action
| Actions.ContractConcepts.ContractConcept_Add_Fail_Action
| Actions.ContractConcepts.ContractConcept_Update_Request_Action
| Actions.ContractConcepts.ContractConcept_Update_Receive_Action
| Actions.ContractConcepts.ContractConcept_Update_Fail_Action
| Actions.ContractConcepts.ContractConcept_Delete_Request_Action
| Actions.ContractConcepts.ContractConcept_Delete_Receive_Action
| Actions.ContractConcepts.ContractConcept_Delete_Fail_Action
| Actions.ContractConceptConcept.ContractConceptConcept_Add_Receive_Action
| Actions.ContractConceptConcept.ContractConceptConcept_Delete_Receive_Action
| Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Add_Receive_Action
| Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Delete_Receive_Action;

export const ContractConceptReducer: Reducer<ContractConceptState> = (
    state: ContractConceptState | undefined, incomingAction: Action): ContractConceptState => {

        if (state === undefined) {
            return ContractConceptUnloadState as ContractConceptState;
        }

        const action = incomingAction as KnownAction;

        switch (action.type) {
            case 'CONTRACTCONCEPT_GET_ALL_BY_CONTRACTID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingAll: true,
                    successLoadingAll: false,
                    failOnLoadingAll: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPT_GET_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: true,
                    failOnLoadingAll: false,
                    error: undefined,
                    list: action.contractConcepts
                };
            case 'CONTRACTCONCEPT_GET_ALL_BY_CONTRACTID_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: false,
                    failOnLoadingAll: true,
                    error: action.error
                };
            case 'CONTRACTCONCEPT_UPDATE_ALL_BY_CONTRACTID_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingAll: true,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPT_UPDATE_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: true,
                    FailUpdatingAll: false,
                    error: undefined,
                    list: action.contractConcepts
                };
            case 'CONTRACTCONCEPT_UPDATE_ALL_BY_CONTRACTID_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: true,
                    error: action.error
                };
            case 'CONTRACTCONCEPT_GET_BY_ID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingOne: true,
                    successLoadingOne: false,
                    failOnLoadingOne: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPT_GET_BY_ID_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: true,
                    failOnLoadingOne: false,
                    error: undefined,
                    list: [...state.list.filter((contractConcept: ContractConcept) => contractConcept.id !== action.contractConcept.id ), action.contractConcept]
                };
            case 'CONTRACTCONCEPT_GET_BY_ID_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: false,
                    failOnLoadingOne: true,
                    error: action.error
                };
            case 'CONTRACTCONCEPT_ADD_REQUEST_ACTION':
                return {
                    ...state,
                    isAddingNewOne: true,
                    successAddingNewOne: false,
                    failOnAddingNewOne: false,
                    error: undefined    
                };
            case 'CONTRACTCONCEPT_ADD_RECEIVE_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: true,
                    failOnAddingNewOne: false,
                    error: undefined,
                    list: [...state.list, action.contractConcept]   
                };
            case 'CONTRACTCONCEPT_ADD_FAIL_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: false,
                    failOnAddingNewOne: true,
                    error: action.error
                };
            case 'CONTRACTCONCEPT_UPDATE_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingOne: true,
                    successUpdatingOne: false,
                    failOnUpdatingOne: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPT_UPDATE_RECEIVE_ACTION':
                console.log("pasa por el update", action.contractConcept)
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: true,
                    failOnUpdatingOne: false,
                    error: undefined,
                    list: [...state.list.filter((contractConcept: ContractConcept) => contractConcept.id !== action.contractConcept.id ), action.contractConcept]
                };
            case 'CONTRACTCONCEPT_UPDATE_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: false,
                    failOnUpdatingOne: true,
                    error: action.error
                };
            case 'CONTRACTCONCEPT_DELETE_REQUEST_ACTION':
                return {
                    ...state,
                    isDeletingOne: true,
                    successDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPT_DELETE_RECEIVE_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: true,
                    failOnDeletingOne: false,
                    error: undefined,
                    list: state.list.filter((contractConcept: ContractConcept) => contractConcept.id !== action.contractConceptId )
                };
            case 'CONTRACTCONCEPT_DELETE_FAIL_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: false,
                    failOnDeletingOne: true,
                    error: action.error
                };
            case 'CONTRACTCONCEPTCONCEPT_ADD_RECEIVE_ACTION':
                var auxContractConceptAdd = state.list.find((contractConcept: ContractConcept) => contractConcept.id === action.contractConceptConcept.contractConceptId);
                if(auxContractConceptAdd !== undefined){
                    auxContractConceptAdd.concepts.filter((contractConcept: ContractConceptConcept) => contractConcept.id === action.contractConceptConcept.id )?.push(action.contractConceptConcept);
                }
                return {
                    ...state,
                    list: auxContractConceptAdd ? [...state.list.filter((contractConcept: ContractConcept) => contractConcept.id !== action.contractConceptConcept.contractConceptId ), auxContractConceptAdd] : state.list
                };
            case 'CONTRACTCONCEPTCONCEPT_DELETE_RECEIVE_ACTION':
                var auxContractConceptDelete = state.list.find((contractConcept: ContractConcept) => contractConcept.id === action.contractConceptId);
                if(auxContractConceptDelete !== undefined){
                    auxContractConceptDelete.concepts = auxContractConceptDelete.concepts.filter((contractConcept: ContractConceptConcept) => contractConcept.id === action.contractConceptConceptId );
                }
                return {
                    ...state,
                    list: auxContractConceptDelete ? [...state.list.filter((contractConcept: ContractConcept) => contractConcept.id !== action.contractConceptConceptId ), auxContractConceptDelete] : state.list
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_ADD_RECEIVE_ACTION':
                var auxContractConceptPaymentAdd = state.list.find((contractConcept: ContractConcept) => contractConcept.id === action.contractConceptPaymentSchedule.contractConceptId);
                if(auxContractConceptPaymentAdd !== undefined){
                    auxContractConceptPaymentAdd.paymentSchedule.filter((contractConcept: ContractConceptPaymentSchedule) => contractConcept.id === action.contractConceptPaymentSchedule.id )?.push(action.contractConceptPaymentSchedule);
                }
                return {
                    ...state,
                    list: auxContractConceptPaymentAdd ? [...state.list.filter((contractConcept: ContractConcept) => contractConcept.id !== action.contractConceptPaymentSchedule.contractConceptId ), auxContractConceptPaymentAdd] : state.list
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_DELETE_RECEIVE_ACTION':
                console.log("pasa por el delete", action.contractConceptId);
                var auxContractConceptPaymentDelete = state.list.find((contractConcept: ContractConcept) => contractConcept.id === action.contractConceptId);
                console.log("payment a borrar", auxContractConceptPaymentDelete);
                if(auxContractConceptPaymentDelete !== undefined){
                    auxContractConceptPaymentDelete.paymentSchedule = auxContractConceptPaymentDelete.paymentSchedule.filter((contractConcept: ContractConceptPaymentSchedule) => contractConcept.id === action.contractConceptPaymentScheduleId);
                };
                return {
                    ...state,
                    list: auxContractConceptPaymentDelete ? [...state.list.filter((contractConcept: ContractConcept) => contractConcept.id !== action.contractConceptPaymentScheduleId ), auxContractConceptPaymentDelete] : state.list
                };
            default:
                return state;
        }
}
