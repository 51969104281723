import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractStage } from "../../../../models/Entities/Contracts/ContractStage";
import { ErrorType } from "../../../../models/HttpError";

// stores
import * as ContractStageCycleStore from '../../../../redux/middleware/ContractStageCycleMeddleware';
import * as ContractStore from '../../../../redux/middleware/ContractMiddleware';

//State
import { AppState } from "../../../../redux/reducers";
import { StageCycle } from "../../../../models/Entities/Contracts/StageCycle";


interface IProps {
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    ContractId: number;
    ContractStageCycleId: number | undefined;
}
  
interface ConnectedProps {
    // loading
    isLoading: boolean;
    isLoadingSuccessfully: boolean;
    failOnLoading: boolean;

    // adding
    isAdding: boolean;
    isAddedSuccessfully: boolean;
    failOnAdding: boolean;

    // updating
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    failOnUpdating: boolean;

    error: ErrorType | undefined;

    Contract: Contract;
    ContractStageCycle: StageCycle;
}
  
interface DispatchProps {
    GetContractStageCycleById: typeof ContractStageCycleStore.actionCreators.GetContractStageCycleById;
    AddContractStageCycle: typeof ContractStageCycleStore.actionCreators.AddContractStageCycle;
    UpdateContractStageCycle: typeof ContractStageCycleStore.actionCreators.UpdateContractStageCycle;
    GetContractById: typeof ContractStore.actionCreators.GetContractById;
}
  
export type Props = IProps & ConnectedProps & DispatchProps;
  
export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.ContractStageCycle?.isLoadingOne,
    isUpdating: state.ContractStageCycle?.isUpdatingOne,
    isUpdatedSuccessfully: state.ContractStageCycle?.successUpdatingOne,
    isLoadingSuccessfully: state.ContractStageCycle?.successLoadingOne,
    isAdding: state.ContractStageCycle?.isAddingNewOne,
    isAddedSuccessfully: state.ContractStageCycle?.successAddingNewOne,
    failOnAdding: state.ContractStageCycle?.failOnAddingNewOne,
    failOnUpdating: state.ContractStageCycle?.failOnLoadingAll,
    failOnLoading: state.ContractStageCycle?.failOnLoadingAll,
    error: state.ContractStageCycle?.error,
    Contract: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId ) : undefined,
    ContractStageCycle: ownProps.ContractStageCycleId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId )?.cycles?.find((cycle: StageCycle) => cycle.id === ownProps.ContractStageCycleId ) : undefined

});
  
export const mapDispatchToProps = {
    ...ContractStageCycleStore.actionCreators,
    ...ContractStore.actionCreators
};