// contract stage middleware

import { ErrorType } from '../../models/HttpError';
import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/ContractBranch.Services';

// actions
import * as Actions from '../actions';
import { Contract } from '../../models/Entities/Contracts/Contract';
import { ContractBranch } from '../../models/Entities/Contracts/ContractBranch';
import { NewContractBranch } from '../../models/Entities/Contracts/NewContractBranch';


type KnownAction =
    | Actions.ContractBranch.ContractBranch_GetAllBycontractId_Request_Action
    | Actions.ContractBranch.ContractBranch_GetAllBycontractId_Receive_Action
    | Actions.ContractBranch.ContractBranch_GetAllBycontractId_Error_Action
    | Actions.ContractBranch.ContractBranch_UpdateALLByContractId_Request_Action
    | Actions.ContractBranch.ContractBranch_UpdateALLByContractId_Receive_Action
    | Actions.ContractBranch.ContractBranch_UpdateALLByContractId_Error_Action
    | Actions.ContractBranch.ContractBranch_GetById_Request_Action
    | Actions.ContractBranch.ContractBranch_GetById_Receive_Action
    | Actions.ContractBranch.ContractBranch_GetById_Error_Action
    | Actions.ContractBranch.ContractBranch_Create_Request_Action
    | Actions.ContractBranch.ContractBranch_Create_Receive_Action
    | Actions.ContractBranch.ContractBranch_Create_Error_Action
    | Actions.ContractBranch.ContractBranch_Update_Request_Action
    | Actions.ContractBranch.ContractBranch_Update_Receive_Action
    | Actions.ContractBranch.ContractBranch_Update_Error_Action
    | Actions.ContractBranch.ContractBranch_Delete_Request_Action
    | Actions.ContractBranch.ContractBranch_Delete_Receive_Action
    | Actions.ContractBranch.ContractBranch_Delete_Error_Action;
    

export const actionCreators = {
    GetAllContractsBranchs:
        (contractId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;
      
            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACTBRANCH_GET_ALL_BY_CONTRACTID_ERROR_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            if (state.Contract?.successLoadingAll && 
                state.Contract?.list?.find((contract: Contract) => contract.id === contractId)?.stages?.length === 0 ) {
                dispatch({
                    type: 'CONTRACTBRANCH_GET_ALL_BY_CONTRACTID_REQUEST_ACTION',
                    contractId: contractId
                });

                Services.GetContractsBranchs(contractId, token)
                    .then((contractBranches: ContractBranch[]) => {
                        dispatch({
                            type: 'CONTRACTBRANCH_GET_ALL_BY_CONTRACTID_RECEIVE_ACTION',
                            contractBranches: contractBranches,
                            contractId: contractId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTBRANCH_GET_ALL_BY_CONTRACTID_ERROR_ACTION',
                            error: error
                        })
                    );
            } else {
                dispatch({
                    type: 'CONTRACTBRANCH_UPDATE_ALL_BY_CONTRACTID_REQUEST_ACTION',
                    contractId: contractId
                });

                Services.GetContractsBranchs(contractId, token)
                    .then((contractBranches: ContractBranch[]) => {
                        dispatch({
                            type: 'CONTRACTBRANCH_UPDATE_ALL_BY_CONTRACTID_RECEIVE_ACTION',
                            contractBranches: contractBranches,
                            contractId: contractId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTBRANCH_UPDATE_ALL_BY_CONTRACTID_ERROR_ACTION',
                            error: error
                        })
                    );
            }
    },
    GetContractBranchById:
        (id: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
        
        var state = getState();
        var token: string;
    
        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACTBRANCH_GET_BY_ID_ERROR_ACTION',
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };

        dispatch({
            type: 'CONTRACTBRANCH_GET_BY_ID_REQUEST_ACTION',
            id: id
        });

        Services.GetContractBranchById(id, token)
            .then((contractBranch: ContractBranch) => {
                dispatch({
                    type: 'CONTRACTBRANCH_GET_BY_ID_RECEIVE_ACTION',
                    contractBranch: contractBranch
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACTBRANCH_GET_BY_ID_ERROR_ACTION',
                    error: error
                })
            );
    },
    CreateContractBranch:
        (contractBranch: NewContractBranch): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
        
            var state = getState();
            var token: string;
        
            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACTBRANCH_CREATE_ERROR_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

        dispatch({
            type: 'CONTRACTBRANCH_CREATE_REQUEST_ACTION',
            contractBranch: contractBranch
        });

        Services.CreateContractBranch(contractBranch, token)
            .then((contractBranch: ContractBranch) => {
                dispatch({
                    type: 'CONTRACTBRANCH_CREATE_RECEIVE_ACTION',
                    contractBranch: contractBranch
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACTBRANCH_CREATE_ERROR_ACTION',
                    error: error
                })
            );
    },
    UpdateContractBranch:
        (contractBranch: ContractBranch): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
        
        var state = getState();
        var token: string;

        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACTBRANCH_UPDATE_ERROR_ACTION',
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };

        dispatch({
            type: 'CONTRACTBRANCH_UPDATE_REQUEST_ACTION',
            contractBranch: contractBranch
        });

        Services.UpdateContractBranch(contractBranch, token)
            .then((contractBranch: ContractBranch) => {
                dispatch({
                    type: 'CONTRACTBRANCH_UPDATE_RECEIVE_ACTION',
                    contractBranch: contractBranch
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACTBRANCH_UPDATE_ERROR_ACTION',
                    error: error
                })
            );
    },
    DeleteContractBranch:
    (id: number, contractId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
        
        var state = getState();
        var token: string;

        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACTBRANCH_DELETE_ERROR_ACTION',
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };

        dispatch({
            type: 'CONTRACTBRANCH_DELETE_REQUEST_ACTION',
            id: id
        });

        Services.DeleteContractBranch(id, token)
            .then((id: number) => {
                dispatch({
                    type: 'CONTRACTBRANCH_DELETE_RECEIVE_ACTION',
                    id: id,
                    contractId: contractId
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACTBRANCH_DELETE_ERROR_ACTION',
                    error: error
                })
            );
    }
};