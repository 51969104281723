import { FontWeights, IIconProps, IStackProps, getTheme, mergeStyleSets } from "@fluentui/react";
import { Contract } from "../../../models/Entities/Contracts/Contract";
import { ErrorType } from "../../../models/HttpError";

import * as ContractStore from '../../../redux/middleware/ContractMiddleware'
import { AppState } from "../../../redux/reducers";

interface IProps {
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    ContractId: number;
}

interface ConnectedProps {
    isCloning: boolean;
    isClonedSuccessfully: boolean | undefined;
    failOnCloning: boolean;
    error: ErrorType | undefined;
    Contract: Contract;
}

interface DispatchProps {
    GetContractById: typeof ContractStore.actionCreators.GetContractById;
    CloneContract: typeof ContractStore.actionCreators.CloneContract;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isCloning: state.Contract?.list?.find((item: Contract) => item.id === ownProps.ContractId)?.isCloning || false,
    isClonedSuccessfully: state.Contract?.list?.find((item: Contract) => item.id === ownProps.ContractId)?.isClonedSuccessfully,
    failOnCloning: state.Contract?.list?.find((item: Contract) => item.id === ownProps.ContractId)?.failOnCloning || false,
    error: state.Contract?.list?.find((item: Contract) => item.id === ownProps.ContractId)?.error,
    Contract: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId) : undefined,
});

export const mapDispatchToProps = {
    ...ContractStore.actionCreators
};


// form styles
export const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

export const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 }
    // styles: { root: { width: 150 } }
};

export const theme = getTheme();
export const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        borderRadius: 10
    },
    header: [
        theme.fonts.mediumPlus,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '0px 12px 12px 12px',
            backgroundColor: 'lightgray',
            borderRadius: '10px 10px 0 0'
        }
    ],
    body: {
        flex: '4 4 auto',
        overflowY: 'auto',
        selectors: {
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 }
        },
        padding: '0 1em 0 0',
        height: '85vh'
    },
    footer: {
        //flex: '4 4 auto',
        padding: '0 24px 24px 24px'
    }
});

export const cancelIcon: IIconProps = { iconName: 'Cancel' };

export const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
};