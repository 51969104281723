import { useEffect, useState } from 'react';
import { Settlement } from '../../../models/Entities/Settlements/Settlement';
import { useNavigate } from 'react-router-dom';
import { useBoolean } from '@fluentui/react-hooks';
import { connect } from 'react-redux';
import { DefaultButton, FontWeights, IconButton, Label, MessageBarType, PrimaryButton, SearchBox, Separator, Spinner } from '@fluentui/react';
import { MessageComponent } from '../../../commons/component/MessageComponent';
import { SettlementResume } from '../../../models/Entities/SettlementResume/SettlementResume';


//fucntions
import * as ComponentFunctions from './SettlementResumeList.Functions';

//styles 
import './SettlementResumeList.Styles.css';

// components
import SettlementSummaryItem from '../SettlementSummaryItem/SettlementSummaryItem.Component';
import SettlementDetailsListComponet from '../SettlementDetailsList/SettlementDetailsList.Component';
import SettlementResumeFormComponent from '../SettlementResumeForm/SettlementResumeForm.Component';
import SettlementDetailItem from '../SettlementDetailItem/SettlementDetailItem.Component';

////i18Next
import { useTranslation, Trans } from 'react-i18next';
import { SettlementDetail } from '../../../models/Entities/SettlementDetails/SettlementDetail';


export const SettlementResumeListComponent = (props: ComponentFunctions.Props) => {

  const [selectedSettlement, setSelectedSettlement] = useState<Settlement | undefined>(undefined);
  const [selectedResumeId, setSelectedResumeId] = useState<number | undefined>(undefined);

  //i18Next
  const { t } = useTranslation();

  // panel details 
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  // add resumen form
  const [isOpenResumeForm, { setTrue: openPanelResumeForm, setFalse: dismissPanelResumeForm }] =
    useBoolean(false);

  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    props.GetSettlementById(props.SettlementId);
  }, []);

  useEffect(() => {
    if (!props.Settlement?.resumes)
      props.GetAllSettlementResume(props.SettlementId)
  }, [props.Settlement]);

  //events

  const onSearch = (newValue: string) => {
    setSearchText(newValue);
  };

  const handleClosePanel = () => {
    setSelectedResumeId(undefined);
    dismissPanel();
  }

  const handleCloseResumeForm = () => {
    setSelectedResumeId(undefined);
    dismissPanelResumeForm();
  }

  const onViewSettlementDetails = (settlementResumeId?: number) => {
    setSelectedResumeId(settlementResumeId);
    openPanel();
  }

  const onAddSettlementResume = () => {
    setSelectedResumeId(undefined);
    openPanelResumeForm();
  }

  const onRefreshSettlementEvent = () => {
    props.RefreshSettlementById(props.SettlementId);
    props.GetSettlementDetailById(props.SettlementId);

  }

  const onEditSettlementResume = (settlementResumeId: number) => {
    setSelectedResumeId(settlementResumeId);
    openPanelResumeForm();
  }

  const onDeleteSettlementResume = (settlementResumeId: number) => {
    props.DeleteSettlementResume(props.SettlementId, settlementResumeId);
  }

  const onMasiveExcludeSettlementResume = (settlementResumeId: number) => {
    props.MassExclude(props.SettlementId, settlementResumeId, true);
  }

  const onMasiveIncludeSettlementResume = (settlementResumeId: number) => {
    props.MassExclude(props.SettlementId, settlementResumeId, false);
  }

  const Controls = (
    <>
      <div className='SectionControls'>
        <PrimaryButton
          onClick={() => {
            onAddSettlementResume();
          }}
          className="actionButton"
          text={t('settlementResumeList.buttons.addSummary')}
        />
        <DefaultButton
          onClick={(event) => {
            event.stopPropagation();
            onRefreshSettlementEvent();
          }}
          iconProps={ComponentFunctions.RefreshIcon}
        />
        <DefaultButton
          onClick={() => {
            onViewSettlementDetails(undefined);
          }}
          iconProps={ComponentFunctions.PatientDetailsIcon}
          text={'Ver detalle por paciente'}
        />
      </div>
      <Separator />
    </>
  );


  //#region Subcontent definition

  // component searchbox
  const Searchbox = <SearchBox
    placeholder="Buscar..."
    //onChange={OnSearch}
    underlined={false}
    onChange={(
      event?: React.ChangeEvent<HTMLInputElement> | undefined,
      newValue?: string | undefined
    ) => (newValue ? onSearch(newValue) : undefined)}
    onClear={() => onSearch('')}
  />

  // Principal error message
  const PrincipalErrorMessage = props.failOnLoading && (
    <MessageComponent
      message={
        t('settlementResumeList.errorMessage.message')
      }
      subMessage={props.error?.ErrorMessage || ''}
      type={MessageBarType.error}
      itemList={props.error?.Errors || []}
    />
  );

  // Principal error message
  const ErrorMessage = props.failOnDeleting && (
    <MessageComponent
      message={
        t('settlementResumeList.errorMessage.onDeletingMessage')
      }
      subMessage={props.error?.ErrorMessage || ''}
      type={MessageBarType.error}
      itemList={props.error?.Errors || []}
    />
  );

  // Loading
  const Loading = props.isLoading || props.isUpdating && (
    <div>
      <Spinner
        label={t('settlementResumeList.loading.loadingSettlementSummary')}
        ariaLive="assertive"
        labelPosition="top"
      />
    </div>
  );

  // Table of content
  const TableOfContent = (props.isLoadingSuccessfully || props.isUpdatedSuccessfully) && (
    <table className="Table">
      <thead>
        <tr>
          <th style={{ maxWidth: '5em' }}>{t('settlementResumeList.ColumnHeaders.settlement')}</th>
          <th>{t('settlementResumeList.ColumnHeaders.quantity')}</th>
          <th>{t('settlementResumeList.ColumnHeaders.amount')}</th>
          <th className='optionsColumn'>{t('settlementResumeList.ColumnHeaders.options')}</th>
        </tr>
      </thead>
      <tbody>
        {props.SettlementResumes && props.SettlementResumes.map((item: SettlementResume) =>
          item.description.toLowerCase().includes(searchText.toLowerCase()) ? (
            <tr
              key={item.id}
              className="TableBodyRow"
            >
              <td className={item.haveWarning ? "primary Yellow" : "primary"}>
                <Label style={{ fontWeight: FontWeights.bold }}>{item.description.toUpperCase()}</Label>
                <p>
                  {item.comments}
                </p>
              </td>
              <td>
                {/* <Label style={{ fontWeight: FontWeights.regular }}>{item.amount}</Label> */}
              </td>
              <td>
                {/* {item.currency && item.currency.length > 0 ? <Label style={{ fontWeight: FontWeights.bold }}>{item.total?.toLocaleString('us-US', { style: 'currency', currency: item.currency?.toUpperCase() }) || '0.00'}&nbsp;<small>({item.currency})</small></Label> :
                  <Label>Moneda no definida</Label>} */}
              </td>
              <td>
                <IconButton
                  iconProps={ComponentFunctions.PropertiesIcon}
                  onClick={() => onViewSettlementDetails(item.id)}
                />
                <IconButton
                  iconProps={ComponentFunctions.EditIcon}
                  onClick={() => onEditSettlementResume(item.id)}
                />
                <IconButton
                  iconProps={ComponentFunctions.Exclude}
                  onClick={() => onMasiveExcludeSettlementResume(item.id)}
                />
                <IconButton
                  iconProps={ComponentFunctions.Include}
                  onClick={() => onMasiveIncludeSettlementResume(item.id)}
                />
                <IconButton
                  iconProps={ComponentFunctions.DeleteIcon}
                  onClick={() => onDeleteSettlementResume(item.id)}
                />
              </td>
            </tr>
          ) : null
        )}
        {props.SettlementDetails && props.SettlementDetails.map((item: SettlementDetail) =>
          item.description.toLowerCase().includes(searchText.toLowerCase()) ? (
            <tr
              key={item.id}
              className="TableBodyRow"
            >
              <td className={item.haveWarning ? "primary Yellow" : "primary"}>
                <Label style={{ fontWeight: FontWeights.bold }}>{item.description.toUpperCase()}</Label>
                <p>
                  {item.comments}
                </p>
              </td>
              <td>
                {/* <Label style={{ fontWeight: FontWeights.regular }}>{item.amount}</Label> */}
              </td>
              <td>
                {item.currency && item.currency.length > 0 ? <Label style={{ fontWeight: FontWeights.bold }}>{item.value?.toLocaleString('us-US', { style: 'currency', currency: item.currency?.toUpperCase() }) || '0.00'}&nbsp;<small>({item.currency})</small></Label> :
                  <Label>Moneda no definida</Label>}
              </td>
              <td>
                {/* <IconButton
                  iconProps={ComponentFunctions.PropertiesIcon}
                  onClick={() => onViewSettlementDetails(item.id)}
                />
                <IconButton
                  iconProps={ComponentFunctions.EditIcon}
                  onClick={() => onEditSettlementResume(item.id)}
                />
                <IconButton
                  iconProps={ComponentFunctions.Exclude}
                  onClick={() => onMasiveExcludeSettlementResume(item.id)}
                />
                <IconButton
                  iconProps={ComponentFunctions.Include}
                  onClick={() => onMasiveIncludeSettlementResume(item.id)}
                />
                <IconButton
                  iconProps={ComponentFunctions.DeleteIcon}
                  onClick={() => onDeleteSettlementResume(item.id)}
                /> */}
              </td>
            </tr>
          ) : null
        )}
      </tbody>
    </table>
  );



  const Modals = (
    <>
      <SettlementDetailsListComponet
        isOpen={isOpen}
        openPanel={openPanel}
        dismissPanel={handleClosePanel}
        SettlementId={props.SettlementId}
        SettlementResumeId={selectedResumeId}
      />
      <SettlementResumeFormComponent
        isOpen={isOpenResumeForm}
        openPanel={openPanelResumeForm}
        dismissPanel={handleCloseResumeForm}
        SettlementId={props.SettlementId}
        SettlementResumeId={selectedResumeId} />
    </>
  );
  //#endregion

  return (
    <>
      {Controls}
      {PrincipalErrorMessage}
      {ErrorMessage}
      {props.SettlementResumes && <>
        <Label>Resumen de conceptos por visita</Label>
        <Separator />
        {props.SettlementResumes?.map((summary: SettlementResume) =>
          <SettlementSummaryItem SettlementId={props.SettlementId} SettlementSummaryItemId={summary.id} />
        )}
      </>
      }
      {props.SettlementDetails && <>
        <Label>Otros Conceptos</Label>
        <Separator />
        {props.SettlementDetails?.map((detail: SettlementDetail) =>
          <SettlementDetailItem SettlementId={props.SettlementId} SettlementDetailId={detail.id} />
        )}
      </>
      }
      {Loading}
      {Modals}
    </>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(SettlementResumeListComponent as any);


