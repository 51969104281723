import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractBranch } from "../../../../models/Entities/Contracts/ContractBranch";
import { ErrorType } from "../../../../models/HttpError";

// stores
import * as ContractBranchStore from '../../../../redux/middleware/ContractBranchsMiddleware';

//State
import { AppState } from "../../../../redux/reducers";


interface IProps {
    ContractId: number;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    error: ErrorType | undefined;
    Contract: Contract | undefined;
    branches: ContractBranch[];
  }
  
  interface DispatchProps {
    GetAllContractsBranchs: typeof ContractBranchStore.actionCreators.GetAllContractsBranchs;
    GetContractBranchById: typeof ContractBranchStore.actionCreators.GetContractBranchById;
    DeleteContractBranch: typeof ContractBranchStore.actionCreators.DeleteContractBranch;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.ContractStage?.isLoadingAll,
    isUpdating: state.ContractStage?.isUpdatingAll,
    isUpdatedSuccessfully: state.ContractStage?.isUpdatingAllSuccess,
    isLoadingSuccessfully: state.ContractStage?.successLoadingAll,
    failOnUpdating: state.ContractStage?.FailUpdatingAll,
    failOnLoading: state.ContractStage?.failOnLoadingAll,
    AddRangeFinalized: state.ContractStage?.isAddingFinishedSuccessfully,
    isDeleting: state.ContractStage?.isDeletingOne,
    isDeletedSuccessfully: state.ContractStage?.successDeletingOne,
    failOnDeleting: state.ContractStage?.failOnDeletingOne,
    error: state.ContractStage?.error,
    Contract: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId ) : undefined,
    branches: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId )?.branches : []
  });
  
  export const mapDispatchToProps = {
    ...ContractBranchStore.actionCreators
  };