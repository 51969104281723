import { IIconProps } from "@fluentui/react";
import { ErrorType } from "../../../models/HttpError";

// stores
import * as ContractStore from '../../../redux/middleware/ContractMiddleware';

//State
import { AppState } from "../../../redux/reducers";
import { Contract } from "../../../models/Entities/Contracts/Contract";

interface IProps {
    ContractId: number;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    isDeletedSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    failOnDeleting: boolean;
    contractError: ErrorType | undefined;
    error: ErrorType | undefined;
    Contract: Contract | undefined;
  }
  
  interface DispatchProps {
    GetAllContracts: typeof ContractStore.actionCreators.GetAllContracts;
    GetContractById: typeof ContractStore.actionCreators.GetContractById;
    MakeContractAddendum: typeof ContractStore.actionCreators.MakeContractAddendum;
    DeleteContract: typeof ContractStore.actionCreators.DeleteContract;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.Contract?.isLoadingAll,
    isUpdating: state.Contract?.isLoadingAll,
    isUpdatedSuccessfully: state.Contract?.successLoadingAll,
    isLoadingSuccessfully: state.Contract?.successLoadingAll,
    failOnUpdating: state.Contract?.failOnLoadingAll,
    failOnLoading: state.Contract?.failOnLoadingAll,
    isDeleting: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId )?.isDeleting : false,
    isDeletedSuccessfully: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId )?.isDeletedSuccessfully : false,
    failOnDeleting: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId )?.failOnDeleting : false,
    contractError: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId )?.error : undefined, 
    error: state.Contract?.error,
    Contract: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId ) : undefined
  });
  
  export const mapDispatchToProps = {
    ...ContractStore.actionCreators
  };

  /*
    Contract Status
    _____________________
    Active = 1,
    Inactive = 2,
    Suspended = 3,
    Expired = 4,
    Canceled = 5,
    Terminated = 6
*/

  export const GetContractStatusDescripcion = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return "Undefined";
        case 1:
            return "Active";
        case 2:
            return "Inactive";
        case 3:
            return "Suspended";
        case 4:
            return "Expired";
        case 5:
            return "Canceled";
        case 6:	
            return "Finished";
        default:
        return "Undefined";
    }
};

  export const GetContractStatusClass = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return "";
        case 1:
            return "Active";
        case 2:
            return "Inactive";
        case 3:
            return "Suspended";
        case 4:
            return "Expired";
        case 5:
            return "Canceled";
        case 6:
            return "Terminated";
        default:
        return "";
  }
};


  //#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const LinkContractIcon: IIconProps = { iconName: 'Link' };