import { ErrorType } from '../../models/HttpError';
import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/Contract.Services';

// actions
import * as Actions from '../actions';
import { Contract } from '../../models/Entities/Contracts/Contract';
import { NewContract } from '../../models/Entities/Contracts/NewContract';
import { ContractProtocolAssociation } from '../../models/Entities/Contracts/ContractProtocolAssociation';
import { ContractPractitionerAssociation } from '../../models/Entities/Contracts/ContractPractitionerAssociation';
import { ContractToClone } from '../../models/Entities/Contracts/ContractToClone';
import { ContractAddendum } from '../../models/Entities/Contracts/ContractAddendum';

type KnownAction =
    | Actions.Contract.Contract_GetAll_Request_Action
    | Actions.Contract.Contract_GetAll_Receive_Action
    | Actions.Contract.Contract_GetAll_Fail_Action
    | Actions.Contract.Contract_UpdateAll_Request_Action
    | Actions.Contract.Contract_UpdateAll_Receive_Action
    | Actions.Contract.Contract_UpdateAll_Fail_Action
    | Actions.Contract.Contract_GetById_Request_Action
    | Actions.Contract.Contract_GetById_Receive_Action
    | Actions.Contract.Contract_GetById_Fail_Action
    | Actions.Contract.Contract_Add_Request_Action
    | Actions.Contract.Contract_Add_Receive_Action
    | Actions.Contract.Contract_Add_Fail_Action
    | Actions.Contract.Contract_Update_Request_Action
    | Actions.Contract.Contract_Update_Receive_Action
    | Actions.Contract.Contract_Update_Fail_Action
    | Actions.Contract.Contract_Delete_Request_Action
    | Actions.Contract.Contract_Delete_Receive_Action
    | Actions.Contract.Contract_Delete_Fail_Action
    | Actions.Contract.Contract_Get_Addendums_Request_Action
    | Actions.Contract.Contract_Get_Addendums_Receive_Action
    | Actions.Contract.Contract_Get_Addendums_Fail_Action
    | Actions.Contract.Contract_Associate_To_Protocol_Request_Action
    | Actions.Contract.Contract_Associate_To_Protocol_Receive_Action
    | Actions.Contract.Contract_Associate_To_Protocol_Fail_Action
    | Actions.Contract.Contract_Disassociate_From_Protocol_Request_Action
    | Actions.Contract.Contract_Disassociate_From_Protocol_Receive_Action
    | Actions.Contract.Contract_Disassociate_From_Protocol_Fail_Action
    | Actions.Contract.Contract_Associate_To_Practitioner_Request_Action
    | Actions.Contract.Contract_Associate_To_Practitioner_Receive_Action
    | Actions.Contract.Contract_Associate_To_Practitioner_Fail_Action
    | Actions.Contract.Contract_Disassociate_From_Practitioner_Request_Action
    | Actions.Contract.Contract_Disassociate_From_Practitioner_Receive_Action
    | Actions.Contract.Contract_Disassociate_From_Practitioner_Fail_Action
    | Actions.Contract.Contract_Change_FeeDivision_Request_Action
    | Actions.Contract.Contract_Change_FeeDivision_Receive_Action
    | Actions.Contract.Contract_Change_FeeDivision_Fail_Action
    | Actions.Contract.Clone_Contract_Request_Action
    | Actions.Contract.Clone_Contract_Receive_Action
    | Actions.Contract.Clone_Contract_Fail_Action
    | Actions.Contract.Make_Contract_addendum_Request_Action
    | Actions.Contract.Make_Contract_addendum_Receive_Action
    | Actions.Contract.Make_Contract_addendum_Fail_Action;

export const actionCreators = {
    GetAllContracts:
        (): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_UPDATE_ALL_FAIL_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };
            
            if (!state.Contract?.isLoadingAll && !state.Contract?.successLoadingAll) {
                dispatch({
                    type: 'CONTRACT_GET_ALL_REQUEST_ACTION'
                });

                Services.GetContracts(token)
                    .then((contracts: Contract[]) => {
                        dispatch({
                            type: 'CONTRACT_GET_ALL_RECEIVE_ACTION',
                            contracts: contracts
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACT_GET_ALL_FAIL_ACTION',
                            error: error
                        })
                    );
            } else {
                if(!state.Contract?.isUpdatingAll && !state.Contract?.isUpdatingAllSuccess) {
                    dispatch({
                        type: 'CONTRACT_UPDATE_ALL_REQUEST_ACTION'
                    });

                    Services.GetContracts(token)
                    .then((contracts: Contract[]) => {
                        dispatch({
                            type: 'CONTRACT_UPDATE_ALL_RECEIVE_ACTION',
                            contracts: contracts
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACT_UPDATE_ALL_FAIL_ACTION',
                            error: error
                        })
                    );
                }
            }
        },
    GetContractById:
        (id: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_GET_BY_ID_FAIL_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            var contract = state.Contract?.list?.find((contract: Contract) => contract.id === id);
            
            if(contract === undefined) {
                dispatch({
                    type: 'CONTRACT_GET_BY_ID_REQUEST_ACTION',
                    contractId: id
                });

                Services.GetContractById(id, token)
                    .then((contract: Contract) => {
                        dispatch({
                            type: 'CONTRACT_GET_BY_ID_RECEIVE_ACTION',
                            contract: contract
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACT_GET_BY_ID_FAIL_ACTION',
                            error: error
                        })
                    );
            }
        },
    AddContract:
        (contract: NewContract): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_ADD_FAIL_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACT_ADD_REQUEST_ACTION',
                contract: contract
            });

            Services.CreateContract(contract, token)
                .then((contract: Contract) => {
                    dispatch({
                        type: 'CONTRACT_ADD_RECEIVE_ACTION',
                        contract: contract
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACT_ADD_FAIL_ACTION',
                        error: error
                    })
                );

        },
    UpdateContract:
        (contract: Contract): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_UPDATE_FAIL_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACT_UPDATE_REQUEST_ACTION',
                contract: contract
            });

            Services.UpdateContract(contract, token)
                .then((contract: Contract) => {
                    dispatch({
                        type: 'CONTRACT_UPDATE_RECEIVE_ACTION',
                        contract: contract
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACT_UPDATE_FAIL_ACTION',
                        error: error
                    })
                );

        },
    AssociateContractToProtocol:
        (contractId: number, protocolId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_ASSOCIATE_TO_PROTOCOL_FAIL_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACT_ASSOCIATE_TO_PROTOCOL_REQUEST_ACTION',
                contractId: contractId,
                protocolId: protocolId
            });

            Services.AssociateToProtocol(contractId, protocolId, token)
                .then((contractProtocolAssociation: ContractProtocolAssociation) => {
                    dispatch({
                        type: 'CONTRACT_ASSOCIATE_TO_PROTOCOL_RECEIVE_ACTION',
                        contractProtocolAssociation: contractProtocolAssociation
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACT_ASSOCIATE_TO_PROTOCOL_FAIL_ACTION',
                        error: error
                    })
                );

        },
    DisassociateContractFromProtocol:
        (protocolContract:  ContractProtocolAssociation): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_DISASSOCIATE_FROM_PROTOCOL_FAIL_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACT_DISASSOCIATE_FROM_PROTOCOL_REQUEST_ACTION',
                contractProtocolAssociation: protocolContract
            });

            Services.DisassociateFromProtocol(protocolContract.id, token)
                .then(() => {
                    dispatch({
                        type: 'CONTRACT_DISASSOCIATE_FROM_PROTOCOL_RECEIVE_ACTION',
                        contractId: protocolContract.contractId,
                        contractProtocolId: protocolContract.id,
                        ProtocolId: protocolContract.protocolId
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACT_DISASSOCIATE_FROM_PROTOCOL_FAIL_ACTION',
                        error: error
                    })
                );

        },
    AssociateContractToPractitioner:
        (contractId: number, practitionerId: string): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_ASSOCIATE_TO_PRACTITIONER_FAIL_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACT_ASSOCIATE_TO_PRACTITIONER_REQUEST_ACTION',
                contractId: contractId,
                practitionerId: practitionerId
            });

            Services.AssociateToPractitioner(contractId, practitionerId, token)
                .then((contractPractitionerAssociation: ContractPractitionerAssociation) => {
                    dispatch({
                        type: 'CONTRACT_ASSOCIATE_TO_PRACTITIONER_RECEIVE_ACTION',
                        contractPractitionerAssociation: contractPractitionerAssociation
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACT_ASSOCIATE_TO_PRACTITIONER_FAIL_ACTION',
                        error: error
                    })
                );

        },
    DisassociateContractFromPractitioner:
        (contractPractitionerAssociation:  ContractPractitionerAssociation): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_DISASSOCIATE_FROM_PRACTITIONER_FAIL_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACT_DISASSOCIATE_FROM_PRACTITIONER_REQUEST_ACTION',
                contractPractitionerAssociation: contractPractitionerAssociation
            });

            Services.DisassociateFromPractitioner(contractPractitionerAssociation.id, token)
            .then(() => {
                dispatch({
                    type: 'CONTRACT_DISASSOCIATE_FROM_PRACTITIONER_RECEIVE_ACTION',
                    contractId: contractPractitionerAssociation.contractId,
                    contractPractitionerId: contractPractitionerAssociation.id,
                    practitionerId: contractPractitionerAssociation.practitionerId
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACT_DISASSOCIATE_FROM_PRACTITIONER_FAIL_ACTION',
                    error: error
                })
            );

    },
    ChangeFeeDivision:
        (contractId: number, feeDivision: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_CHANGE_FEEDIVISION_FAIL_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACT_CHANGE_FEEDIVISION_REQUEST_ACTION',
                contractId: contractId,
                feeDivision: feeDivision
            });

            Services.ChangeFeeDivision(contractId, feeDivision, token)
            .then(() => {
                dispatch({
                    type: 'CONTRACT_CHANGE_FEEDIVISION_RECEIVE_ACTION',
                    contractId: contractId,
                    feeDivision: feeDivision
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACT_CHANGE_FEEDIVISION_FAIL_ACTION',
                    error: error
                })
            );

    },
    CloneContract:
        (cloneInfo: ContractToClone): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_CLONE_FAIL_ACTION',
                    contractId: cloneInfo.contractToCloneId,
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACT_CLONE_REQUEST_ACTION',
                cloneInfo: cloneInfo
            });

            Services.CloneContract(cloneInfo, token)
            .then((contract: Contract) => {
                dispatch({
                    type: 'CONTRACT_CLONE_RECEIVE_ACTION',
                    originalContractId: cloneInfo.contractToCloneId,
                    contract: contract
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACT_CLONE_FAIL_ACTION',
                    contractId: cloneInfo.contractToCloneId,
                    error: error
                })
            );

    },
    MakeContractAddendum:
        (contractInfo: ContractToClone): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_MAKE_ADDENDUM_FAIL_ACTION',
                    contractId: contractInfo.contractToCloneId,
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };


            dispatch({
                type: 'CONTRACT_MAKE_ADDENDUM_REQUEST_ACTION',
                contractInfo: contractInfo
            });

            Services.CloneContract(contractInfo, token)
            .then((contract: Contract) => {
                dispatch({
                    type: 'CONTRACT_MAKE_ADDENDUM_RECEIVE_ACTION',
                    originalContractId: contractInfo.contractToCloneId,
                    contract: contract
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACT_MAKE_ADDENDUM_FAIL_ACTION',
                    contractId: contractInfo.contractToCloneId,
                    error: error
                })
            );

    },
    DeleteContract:
        (contractId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_DELETE_FAIL_ACTION',
                    contractId: contractId,
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACT_DELETE_REQUEST_ACTION',
                contractId: contractId
            });

            Services.DeleteContract(contractId, token)
            .then(() => {
                dispatch({
                    type: 'CONTRACT_DELETE_RECEIVE_ACTION',
                    contractId: contractId
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACT_DELETE_FAIL_ACTION',
                    contractId: contractId,
                    error: error
                })
            );

    },
    GetContractAddendumns:
        (contractId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_GET_ADDENDUMS_FAIL_ACTION',
                    contractId: contractId,
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACT_GET_ADDENDUMS_REQUEST_ACTION',
                contractId: contractId
            });

            Services.GetContractAddendum(contractId, token)
            .then((addendums: ContractAddendum) => {
                dispatch({
                    type: 'CONTRACT_GET_ADDENDUMS_RECEIVE_ACTION',
                    contractId: contractId,
                    addendums: addendums
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACT_GET_ADDENDUMS_FAIL_ACTION',
                    contractId: contractId,
                    error: error
                })
            );

    }
};


