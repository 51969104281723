import {
  IPersonaSharedProps,
  Persona,
  PersonaPresence,
  PersonaSize
} from '@fluentui/react';

// Common Function
import * as CommonFunction from '../../../../functions/common';

// styles
import './HeaderPersonaComponent.css';

export interface IProps {
  PersonImage?: string | undefined;
  PersonaName: string;
  secondaryText: string;
}

export const HeaderPersonaComponent = (props: IProps) => {
  const currentPersona: IPersonaSharedProps = {
    imageUrl: props.PersonImage,
    imageInitials: CommonFunction.getNameInitials(props.PersonaName),
    text: props.PersonaName,
    secondaryText: props.secondaryText
  };

  return (
    <Persona
      {...currentPersona}
      size={PersonaSize.size48}
      hidePersonaDetails={false}
      presence={PersonaPresence.none}
      imageAlt={props.PersonaName}
    />
  );
};
