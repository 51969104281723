import { Protocol } from '../../models/Entities/Protocols/Protocol';
import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/MedicalProtocols.Services';

// actions
import * as Actions from '../actions';

// models
import { ProtocolContract } from '../../models/Entities/Protocols/ProtocolContracts';
import { ContractProtocolAssociation } from '../../models/Entities/Contracts/ContractProtocolAssociation';
import { ProtocolPatient } from '../../models/Entities/Protocols/ProtocolPatient';
import { PatientVisit } from '../../models/Entities/Protocols/PatientVisist';
import { ErrorType } from '../../models/HttpError';

type KnownAction =
    | Actions.MedicalProtocols.MedicalProtocols_GetAll_Request_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetAll_Receive_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetAll_Fail_Action
    | Actions.MedicalProtocols.MedicalProtocols_UpdateAll_Request_Action
    | Actions.MedicalProtocols.MedicalProtocols_UpdateAll_Receive_Action
    | Actions.MedicalProtocols.MedicalProtocols_UpdateAll_Fail_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetById_Request_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetById_Receive_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetById_Fail_Action
    | Actions.MedicalProtocols.MedicalProtocols_Contract_Association_Request_Action
    | Actions.MedicalProtocols.MedicalProtocols_Contract_Association_Receive_Action
    | Actions.MedicalProtocols.MedicalProtocols_Contract_Association_Fail_Action
    | Actions.MedicalProtocols.MedicalProtocols_Contract_Disassociation_Request_Action
    | Actions.MedicalProtocols.MedicalProtocols_Contract_Disassociation_Receive_Action
    | Actions.MedicalProtocols.MedicalProtocols_Contract_Disassociation_Fail_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetAll_ContractAssociations_Request_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetAll_ContractAssociations_Receive_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetAll_ContractAssociations_Fail_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetAll_ProtocolPatients_Request_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetAll_ProtocolPatients_Receive_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetAll_ProtocolPatients_Fail_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetByPractitionerId_Request_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetByPractitionerId_Receive_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetByPractitionerId_Fail_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetAll_PatientVisit_ByProtocolId_Request_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetAll_PatientVisit_ByProtocolId_Receive_Action
    | Actions.MedicalProtocols.MedicalProtocols_GetAll_PatientVisit_ByProtocolId_Fail_Action;

export const actionCreators = {
    GetAllMedicalProtocols: (): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'MEDICALPROTOCOLS_GET_ALL_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            if (!state.MedicalProtocols?.successLoadingAll) {
                dispatch({
                    type: 'MEDICALPROTOCOLS_GET_ALL_REQUEST_ACTION'
                });

                Services.GetAllProtocols(token)
                    .then((MedicalProtocols: Protocol[]) => {
                        dispatch({
                            type: 'MEDICALPROTOCOLS_GET_ALL_RECEIVE_ACTION',
                            MedicalProtocols: MedicalProtocols
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'MEDICALPROTOCOLS_GET_ALL_FAIL_ACTION',
                            error: error
                        })
                    );
            } else {
                dispatch({
                    type: 'MEDICALPROTOCOLS_UPDATE_ALL_REQUEST_ACTION'
                });

                Services.GetAllProtocols(token)
                    .then((MedicalProtocols: Protocol[]) => {
                        dispatch({
                            type: 'MEDICALPROTOCOLS_UPDATE_ALL_RECEIVE_ACTION',
                            MedicalProtocols: MedicalProtocols
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'MEDICALPROTOCOLS_UPDATE_ALL_FAIL_ACTION',
                            error: error
                        })
                    );
            }
        },
    GetMedicalProtocolById:
        (protocolId: number): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {

                var state = getState();
                var token: string;

                if (state.oidc?.user?.access_token === undefined) {
                    dispatch({
                        type: 'MEDICALPROTOCOLS_GET_BY_ID_FAIL_ACTION',
                        error: {
                            ErrorCode: 401,
                            ErrorMessage: 'Not authorized',
                            Errors: []
                        }
                    });
                    return;
                } else {
                    token = state.oidc.user.access_token;
                }

                if (state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === protocolId )) return;

                dispatch({
                    type: 'MEDICALPROTOCOLS_GET_BY_ID_REQUEST_ACTION',
                    protocolId: protocolId
                });

                Services.GetProtocolById(protocolId, token)
                    .then((MedicalProtocol: Protocol) => {
                        dispatch({
                            type: 'MEDICALPROTOCOLS_GET_BY_ID_RECEIVE_ACTION',
                            MedicalProtocol: MedicalProtocol
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'MEDICALPROTOCOLS_GET_BY_ID_FAIL_ACTION',
                            error: error
                        })
                    );
            },
    AssociateContract: (
        protocolId: number,
        contractId: number
    ): AppThunkAction<KnownAction> => {
        return async (dispatch, getSate) => {

            var state = getSate();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'MEDICALPROTOCOLS_CONTRACT_ASSOCIATION_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }


            dispatch({
                type: 'MEDICALPROTOCOLS_CONTRACT_ASSOCIATION_REQUEST_ACTION',
                ProtocolId: protocolId,
                ContractId: contractId
            });

            Services.AssociateContract(protocolId, contractId, token)
                .then((contract: ProtocolContract) => {
                    dispatch({
                        type: 'MEDICALPROTOCOLS_CONTRACT_ASSOCIATION_RECEIVE_ACTION',
                        ProtocolContract: contract
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'MEDICALPROTOCOLS_CONTRACT_ASSOCIATION_FAIL_ACTION',
                        error: error
                    })
                );
        };
    },
    DisassociateContract: (
        ProtocolContractId: number,
        ProtocolId: number
    ): AppThunkAction<KnownAction> => {
        return async (dispatch, getState) => {

            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'MEDICALPROTOCOLS_CONTRACT_DISASSOCIATION_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }


            dispatch({
                type: 'MEDICALPROTOCOLS_CONTRACT_DISASSOCIATION_REQUEST_ACTION',
                ProtocolContractId: ProtocolContractId,
                ProtocolId: ProtocolId
            });

            Services.DisassociateContract(ProtocolContractId, token)
                .then((contract: ProtocolContract) => {
                    dispatch({
                        type: 'MEDICALPROTOCOLS_CONTRACT_DISASSOCIATION_RECEIVE_ACTION',
                        ProtocolContractId: ProtocolContractId,
                        ProtocolId: ProtocolId
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'MEDICALPROTOCOLS_CONTRACT_DISASSOCIATION_FAIL_ACTION',
                        error: error
                    })
                );
        };
    },
    GetAllContractAssociations: (
        protocolId: number
    ): AppThunkAction<KnownAction> => {

        return async (dispatch, getState) => {

            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'MEDICALPROTOCOLS_GETALL_CONTRACTASSOCIATIONS_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'MEDICALPROTOCOLS_GETALL_CONTRACTASSOCIATIONS_REQUEST_ACTION',
                ProtocolId: protocolId
            });

            Services.GetProtocolContracts(protocolId, token)
                .then((contract: ContractProtocolAssociation) => {
                    dispatch({
                        type: 'MEDICALPROTOCOLS_GETALL_CONTRACTASSOCIATIONS_RECEIVE_ACTION',
                        contractAssociation: contract,
                        ProtocolId: protocolId
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'MEDICALPROTOCOLS_GETALL_CONTRACTASSOCIATIONS_FAIL_ACTION',
                        error: error
                    })
                );
        };
    },
    GetProtocolPatients: (
        protocolId: number,
        searchText: string | null
    ): AppThunkAction<KnownAction> => {

        return async (dispatch, getState) => {

            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'MEDICALPROTOCOLS_GETALL_PROTOCOLPATIENTS_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'MEDICALPROTOCOLS_GETALL_PROTOCOLPATIENTS_REQUEST_ACTION',
                ProtocolId: protocolId
            });

            Services.GetProtocolPatients(protocolId, searchText, token)
                .then((Patients: ProtocolPatient[]) => {
                    //console.log('Patients', Patients);
                    dispatch({
                        type: 'MEDICALPROTOCOLS_GETALL_PROTOCOLPATIENTS_RECEIVE_ACTION',
                        ProtocolId: protocolId,
                        ProtocolPatients: Patients
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'MEDICALPROTOCOLS_GETALL_PROTOCOLPATIENTS_FAIL_ACTION',
                        error: error
                    })
                );
        };
    },
    GetProtocolsByPractitionerId: (
        practitionerId: string
    ): AppThunkAction<KnownAction> => {
        return async (dispatch, getState) => {

            var state = getState();
            var token: string;

            if(state.Practitioner?.list.find((practitioner) => practitioner.userId === practitionerId)?.Protocols) return;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'MEDICALPROTOCOLS_GET_BY_PRACTITIONER_ID_FAIL_ACTION',
                    practitionerId: practitionerId,
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'MEDICALPROTOCOLS_GET_BY_PRACTITIONER_ID_REQUEST_ACTION',
                practitionerId: practitionerId
            });

            Services.GetProtocolByPractitionerId(practitionerId, token)
                .then((Protocols: Protocol[]) => {
                    dispatch({
                        type: 'MEDICALPROTOCOLS_GET_BY_PRACTITIONER_ID_RECEIVE_ACTION',
                        practitionerId: practitionerId,
                        MedicalProtocols: Protocols
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'MEDICALPROTOCOLS_GET_BY_PRACTITIONER_ID_FAIL_ACTION',
                        practitionerId: practitionerId,
                        error: error
                    })
                );
        };
    },
    GetPatientVisitByProtocolId: (
        protocolId: number
    ): AppThunkAction<KnownAction> => {
        return async (dispatch, getState) => {

            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'MEDICALPROTOCOLS_GETALL_PATIENTVISIT_BYPROTOCOLID_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'MEDICALPROTOCOLS_GETALL_PATIENTVISIT_BYPROTOCOLID_REQUEST_ACTION',
                ProtocolId: protocolId
            });

            Services.GetProtocolVisits(protocolId, token)
                .then((PatientVisits: PatientVisit[]) => {
                    dispatch({
                        type: 'MEDICALPROTOCOLS_GETALL_PATIENTVISIT_BYPROTOCOLID_RECEIVE_ACTION',
                        ProtocolId: protocolId,
                        visits: PatientVisits
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'MEDICALPROTOCOLS_GETALL_PATIENTVISIT_BYPROTOCOLID_FAIL_ACTION',
                        error: error
                    })
                );
        };
    }
};

