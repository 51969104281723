import { useState } from 'react';
import { Field, makeStyles } from "@fluentui/react-components";
import { DatePicker } from '@fluentui/react-datepicker-compat';

// own functions
import * as ownFunctions from './DateTimeInputfunctions';

// own styles
import './DateTimeInput.css';


export interface IDateTimeInputProps {
  label: string | undefined;
  placeHolder: string | undefined;
  selectedDate: Date | undefined;
  isSubmitted: boolean;
  isRequired: boolean;
  errorMessage: string | undefined;
  onSelectDate?: (date: Date | undefined) => void;
}

export const DateTimeInput = (props: IDateTimeInputProps) => {
  //const [firstDayOfWeek] = useState(DayOfWeek.Sunday);

  const onChangeSeletedDateFieldValue = (date: Date | null | undefined) => {
    if (props.onSelectDate) {
      const newDate = date ? new Date(date).setHours(0, 0, 0, 0) : undefined;
      props.onSelectDate(newDate ? new Date(newDate) : undefined);
    }
  };

  return (
    <>
      <Field label={props.label ? props.label : undefined} required={props.isRequired}>
      <DatePicker
          //ref={datePickerRef}
          allowTextInput
          value={props.selectedDate ? new Date(props.selectedDate) : new Date()}
          onSelectDate={onChangeSeletedDateFieldValue}
          formatDate={ownFunctions.onFormatDate}
          //parseDateFromString={onParseDateFromString}
          placeholder="Select a date..."
          className={(props.errorMessage && props.isSubmitted) ? 'error' : ''}
        />
      {(props.errorMessage && props.isSubmitted) && (
        <small className="error">{props.errorMessage}</small>
      )}
      </Field>
    </>
  );
};
