import React from 'react';
import { DefaultButton, FontSizes, Icon, Label, PrimaryButton, Separator, Spinner } from "@fluentui/react"
import { connect } from "react-redux";
import { useBoolean } from '@fluentui/react-hooks';
import { ErrorMessageComponent } from "../../../Error/ErrorMessageComponent";
import { useEffect, useState } from "react";
import { ContractConcept } from "../../../../models/Entities/Contracts/ContractConcept";
import ConceptFormComponent from "../ConceptForm/ConceptForm.Component";
import { ConceptItemComponent } from "./ConceptItem.Component";

// functions
import * as ComponentFunctions from './ConceptList.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// styles
import './ConceptList.Styles.css';

//image 
const emptyResultImage = '../../../../assets/img/empty_state.gif';



export const ConceptListComponent = (props: ComponentFunctions.Props) => {

    // initial values
    const [isOpenInitialSettings, { setTrue: openPanelInitialSettings, setFalse: dismissPanelInitialSettings }] = useBoolean(false);
    const [selectedConcept, setSelectedConcept] = useState<ContractConcept | undefined>(undefined);

    // Add or eddit Concept
    const [isOpenVisit, { setTrue: openPanelConcept, setFalse: dismissPanelConcept }] = useBoolean(false);

    const handleInitialSettingsClosePanel = () => {
        dismissPanelInitialSettings();
    };

    const handleConceptClosePanel = () => {
        setSelectedConcept(undefined);
        dismissPanelConcept();
    };

    const handleChargeVisitInformation = () => {
        openPanelInitialSettings();
    };

    const HandleAddEvent = () => {
        openPanelConcept();
    };

    const HandleEditEvent = (concept: ContractConcept) => {
        setSelectedConcept(concept);
        openPanelConcept();
    }

    const HandleDeleteEvent = (concept: ContractConcept) => {
        props.DeleteContractConcept(concept.id, props.ContractId);
    }

    //useTransaltion()
    const [t] = useTranslation();

    // efects   
    useEffect(() => {
        if (props.Contract) {
            props.GetAllContractsConcept(props.ContractId);
        }
    }, [props.Contract === undefined]);

    // Loading
    const Loading = props.isLoading && (
        <div>
            <Spinner
                label={t('conceptList.loading.loadingConcepts')}
                ariaLive="assertive"
                labelPosition="top"
            />
        </div>
    );

    const ComponentActions = (
        <>
            <PrimaryButton
                secondaryText={t('conceptList.componentActions.addConcept')}
                onClick={HandleAddEvent}
                text={t('conceptList.componentActions.addConcept')}
                iconProps={{ iconName: 'Add' }}
                className="actionButton"
            />
        </>
    )

    const ErrorMessage = props.failOnLoading && props.error && (
        <ErrorMessageComponent Error={props.error} />
    )

    const NoRegistries = (props.isLoadingSuccessfully && props.ContractConcepts?.length === 0 || props.ContractConcepts?.length === undefined) && (
        <div className="NoDataSection">
            <img
                src={require('../../../../assets/img/empty_state.gif')}
                alt="Ichtys"
                style={{ width: '10em', height: '10em', marginBottom: '1em' }}
            ></img>
            <Label>{t('conceptList.noRegistries.noConceptsRegistered.noConceptsRegistered')}</Label>
        </div>
    );

    const Forms = <>
        <ConceptFormComponent isOpen={isOpenVisit}
            openPanel={openPanelConcept}
            dismissPanel={handleConceptClosePanel}
            ContractId={props.ContractId}
            ContractConceptId={selectedConcept?.id}
            CurrentConcept={props.Contract?.concepts || []}
            CurrentStages={props.Contract?.stages || []}
        />
    </>


    return (
        <>
            {ComponentActions}
            <Separator />
            {ErrorMessage}
            {props.isLoading ? Loading : null}
            {props.ContractConcepts?.map((concept: ContractConcept, index) => (
                <ConceptItemComponent
                    Concept={concept} index={index}
                    onEdit={(concept: ContractConcept) => HandleEditEvent(concept)}
                    onDelete={(concept: ContractConcept) => HandleDeleteEvent(concept)}
                    isDeleting={props.isDeleting} 
                    failonDeleting={props.failOnDeleting} 
                    error={props.error}
                />
            ))}
            {NoRegistries}
            {Forms}
        </>
    )
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ConceptListComponent as any);