import { Reducer, Action } from 'redux';
import {
  PractitionerState,
  PractitionerUnloadState
} from '../states/PractitionerState';
import { PractitionerMedicalInsuranceAssociation } from '../../models/Entities/PractitionerAssociations/MedicalInsurances/PractitionerMedicalInsuranceAssociation';
import { Practitioner, PractitionerVisit } from '../../models/Entities/Practitioners/Practitioner';
import { PractitionerAssociationsMedicalService } from '../../models/Entities/PractitionerAssociations/MedicalServices/PractitionerAssociationMedicalService';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';
import { Protocol } from '../../models/Entities/Protocols/Protocol';


type KnownAction =
  | Actions.Practitioner.Practitioners_GetAll_Request_Action
  | Actions.Practitioner.Practitioners_GetAll_Receive_Action
  | Actions.Practitioner.Practitioners_GetAll_Fail_Action
  | Actions.Practitioner.Practitioner_UpdateAll_Request_Action
  | Actions.Practitioner.Practitioner_UpdateAll_Receive_Action
  | Actions.Practitioner.Practitioner_UpdateAll_Fail_Action
  | Actions.Practitioner.Practitioners_GetAll_MedicalInsurances_Request_Action
  | Actions.Practitioner.Practitioners_GetAll_MedicalInsurances_Receive_Action
  | Actions.Practitioner.Practitioners_GetAll_MedicalInsurances_Fail_Action
  | Actions.Practitioner.Practitioners_Add_MedicalInsurances_Request_Action
  | Actions.Practitioner.Practitioners_Add_MedicalInsurances_Receive_Action
  | Actions.Practitioner.Practitioners_Add_MedicalInsurances_Fail_Action
  | Actions.Practitioner.Practitioners_Delete_MedicalInsurances_Request_Action
  | Actions.Practitioner.Practitioners_Delete_MedicalInsurances_Receive_Action
  | Actions.Practitioner.Practitioners_Delete_MedicalInsurances_Fail_Action
  | Actions.Practitioner.Practitioners_GetAll_ContractsAssociations_Receive_Action
  // associated entities
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_ReceiveAll_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_Add_Receive_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_DeleteByMedicalInsuranceId_Receive_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_ReceiveAll_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Add_Receive_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Delete_By_Id_Receive_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Delete_By_Medical_Service_Id_Receive_Action
  | Actions.PractitionerSettings.PractitionerSettings_Receive_Action
  | Actions.PractitionerSettings.PractitionerSettings_Update_Receive_Action
  | Actions.PractitionerSettings.PractitionerSettings_Add_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsGetAll_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsGetById_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsAdd_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsUpdate_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsDelete_Receive_Action
  // protocols
  | Actions.MedicalProtocols.MedicalProtocols_GetByPractitionerId_Request_Action
  | Actions.MedicalProtocols.MedicalProtocols_GetByPractitionerId_Receive_Action
  | Actions.MedicalProtocols.MedicalProtocols_GetByPractitionerId_Fail_Action
  // visitis
  | Actions.PractitionerProtocolVisits.Pratitioner_Protocol_Visits_GetAll_Request_Action
  | Actions.PractitionerProtocolVisits.Pratitioner_Protocol_Visits_GetAll_Receive_Action
  | Actions.PractitionerProtocolVisits.Pratitioner_Protocol_Visits_GetAll_Fail_Action;

export const PractitionerReducer: Reducer<PractitionerState> = (
  state: PractitionerState | undefined,
  incomingAction: Action
): PractitionerState => {
  if (state === undefined) {
    return PractitionerUnloadState as PractitionerState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'PRACTITIONERS_GETALL_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'PRACTITIONERS_GETALL_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        list:
          action.practitioners
            .map((practitioner: Practitioner) => {
              const CurrentPractitioner = state?.list?.find(
                (practitionerFromState: Practitioner) => {
                  return practitionerFromState.userId === practitioner.userId;
                }
              );
              if (CurrentPractitioner !== undefined) {
                return {
                  ...practitioner,
                  Settings: CurrentPractitioner.Settings,
                  MedicalInsurances:
                    CurrentPractitioner.MedicalInsuranceAssociated
                };
              } else return practitioner;
            })
            .sort(Functions.DynamicSort('fullName')) || [],
        error: undefined
      };
    case 'PRACTITIONERS_GETALL_FAIL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'PRACTITIONERS_UPDATE_ALL_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingAll: true,
        UpdatingAllSuccess: undefined,
        FailUpdatingAll: false,
        error: undefined
      };
    case 'PRACTITIONERS_UPDATE_ALL_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingAll: false,
        UpdatingAllSuccess: true,
        FailUpdatingAll: false,
        list:
          action.practitioners
            .map((practitioner: Practitioner) => {
              const CurrentPractitioner = state?.list?.find(
                (practitionerFromState: Practitioner) => {
                  return practitionerFromState.userId === practitioner.userId;
                }
              );
              if (CurrentPractitioner !== undefined) {
                return {
                  ...practitioner,
                  Settings: CurrentPractitioner.Settings,
                  MedicalInsurances:
                    CurrentPractitioner.MedicalInsuranceAssociated
                };
              } else return practitioner;
            })
            .sort(Functions.DynamicSort('fullName')) || [],
        error: undefined
      };
    case 'PRACTITIONERS_UPDATE_ALL_FAIL_ACTION':
      return {
        ...state,
        isUpdatingAll: false,
        UpdatingAllSuccess: false,
        FailUpdatingAll: true,
        error: action.error
      };
    case 'PRACTITIONERS_GETALL_MEDICALINSURANCES_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAllMedicalInsurances: true,
        successLoadingAllMedicalInsurance: undefined,
        failOnLoadingAllMedicalInsurances: false,
        error: undefined
      };
    case 'PRACTITIONERS_GETALL_MEDICALINSURANCES_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingAllMedicalInsurances: false,
        successLoadingAllMedicalInsurance: true,
        failOnLoadingAllMedicalInsurances: false,
        medicalInsuranceAssociations: action.MedicalInsuranceAssociations,
        error: undefined
      };
    case 'PRACTITIONERS_GETALL_MEDICALINSURANCES_FAIL_ACTION':
      return {
        ...state,
        isLoadingAllMedicalInsurances: false,
        successLoadingAllMedicalInsurance: false,
        failOnLoadingAllMedicalInsurances: true,
        error: action.error
      };
    case 'PRACTITIONERS_ADD_MEDICALINSURANCES_REQUEST_ACTION':
      return {
        ...state,
        isAddingOneMedicalInsurance: true,
        successAddingOneMedicalInsurance: undefined,
        failOnAddingOneMedicalInsurance: false,
        error: undefined
      };
    case 'PRACTITIONERS_ADD_MEDICALINSURANCES_RECEIVE_ACTION':
      return {
        ...state,
        isAddingOneMedicalInsurance: false,
        successAddingOneMedicalInsurance: true,
        failOnAddingOneMedicalInsurance: false,
        medicalInsuranceAssociations: [
          ...state.medicalInsuranceAssociations,
          action.MedicalInsuranceAssociation
        ],
        error: undefined
      };
    case 'PRACTITIONERS_ADD_MEDICALINSURANCES_FAIL_ACTION':
      return {
        ...state,
        isAddingOneMedicalInsurance: false,
        successAddingOneMedicalInsurance: false,
        failOnAddingOneMedicalInsurance: true,
        error: action.error
      };
    case 'PRACTITIONERS_DELETE_MEDICALINSURANCES_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOneMedicalInsurance: true,
        successDeletingOneMedicalInsurance: undefined,
        failOnDeletingOneMedicalInsurance: false,
        error: undefined
      };
    case 'PRACTITIONERS_DELETE_MEDICALINSURANCES_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOneMedicalInsurance: false,
        successDeletingOneMedicalInsurance: true,
        failOnAddingOneMedicalInsurance: false,
        medicalInsuranceAssociations: state.medicalInsuranceAssociations.filter(
          (item: PractitionerMedicalInsuranceAssociation) =>
            item.id !== action.MedicalInsuranceAssociation.id
        ),
        error: undefined
      };
    case 'PRACTITIONERS_DELETE_MEDICALINSURANCES_FAIL_ACTION':
      return {
        ...state,
        isDeletingOneMedicalInsurance: false,
        successDeletingOneMedicalInsurance: false,
        failOnDeletingOneMedicalInsurance: true,
        error: action.error
      };
    // medical insurances
    case 'PRACTITIONER_MEDICAL_INSURANCES_RECEIVE_ALL_ACTION':
      return {
        ...state,
        list: state.list.map((item: Practitioner) => {
          if (item.userId === action.practitionerId) {
            return {
              ...item,
              MedicalInsuranceAssociated: action.practitionerMedicalInsurances
            };
          }
          return item;
        })
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_ADD_RECEIVE_ACTION':
      return {
        ...state,
        list: state.list.map((item: Practitioner) => {
          if (
            item.userId === action.practitionerMedicalInsurance.practitionerId
          ) {
            return {
              ...item,
              MedicalInsuranceAssociated: [
                ...item.MedicalInsuranceAssociated,
                action.practitionerMedicalInsurance
              ]
            };
          }
          return item;
        })
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_DELETE_BY_MEDICALINSURANCE_ID_RECEIVE_ACTION':
      return {
        ...state,
        list: state.list.map((item: Practitioner) => {
          if (item.userId === action.practitionerId) {
            return {
              ...item,
              MedicalInsuranceAssociated:
                item.MedicalInsuranceAssociated.filter(
                  (item: PractitionerMedicalInsuranceAssociation) =>
                    item.medicalInsuranceId !== action.MedicalInsuranceId
                )
            };
          }
          return item;
        })
      };
    case 'PRACTITIONER_SETTINGS_RECEIVE_ACTION':
    case 'PRACTITIONER_SETTINGS_ADD_RECEIVE_ACTION':
    case 'PRACTITIONER_SETTINGS_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        list: state.list.map((item: Practitioner) => {
          if (item.userId === action.practitionerSettings.practitionerId) {
            return {
              ...item,
              Settings: action.practitionerSettings
            };
          }
          return item;
        })
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_RECEIVE_ALL_ACTION':
      return {
        ...state,
        list: state.list.map((item: Practitioner) => {
          if (item.userId === action.practitionerId) {
            return {
              ...item,
              MedicalServicesAssociated: action.practitionerMedicalServices
            };
          }
          return item;
        })
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_ADD_RECEIVE_ACTION':
      return {
        ...state,
        list: state.list.map((item: Practitioner) => {
          if (
            item.userId === action.practitionerMedicalService.practitionerId
          ) {
            return {
              ...item,
              MedicalServicesAssociated: [
                ...item.MedicalServicesAssociated,
                action.practitionerMedicalService
              ]
            };
          }
          return item;
        })
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_DELETE_BY_ID_RECEIVE_ACTION':
      return {
        ...state,
        list: state.list.map((item: Practitioner) => {
          if (item.userId === action.practitionerId) {
            return {
              ...item,
              MedicalServicesAssociated: item.MedicalServicesAssociated.filter(
                (item: PractitionerAssociationsMedicalService) =>
                  item.id !== action.practitionerMedicalServiceId
              )
            };
          }
          return item;
        })
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_DELETE_BY_MEDICAL_SERVICE_ID_RECEIVE_ACTION':
      return {
        ...state,
        list: state.list.map((item: Practitioner) => {
          if (item.userId === action.practitionerId) {
            return {
              ...item,
              MedicalServicesAssociated: item.MedicalServicesAssociated.filter(
                (item: PractitionerAssociationsMedicalService) =>
                  item.medicalServiceId !== action.MedicalServiceId
              )
            };
          }
          return item;
        })
      };
    case 'PRACTITIONER_CONTRACTS_ADD_RECEIVE_ACTION':
      return {
        ...state,
        list: state.list.map((item: Practitioner) => {
          if (item.userId === action.practitionerContract.practitionerId) {
            return {
              ...item,
              ContractAssociation: action.practitionerContract
            };
          }
          return item;
        })
      };
    case 'PRACTITIONER_CONTRACTS_DELETE_RECEIVE_ACTION':
      return {
        ...state,
        list: state.list.map((item: Practitioner) => {
          if (item.userId === action.practitionerId) {
            return {
              ...item,
              ContractAssociation: undefined
            };
          }
          return item;
        })
      };
    case 'PRACTITIONER_CONTRACTS_GET_ALL_RECEIVE_ACTION':
      return {
        ...state,
        list: state.list.map((item: Practitioner) => {
          if (item.userId === action.practitionerId) {
            return {
              ...item,
              ContractAssociation: action.practitionerContracts
            };
          }
          return item;
        }) as Practitioner[]
      };
    case 'MEDICALPROTOCOLS_GET_BY_PRACTITIONER_ID_REQUEST_ACTION':
      var PractitionerProtocolRequest = state.list.find(
        (item: Practitioner) => item.userId === action.practitionerId
      );

      if(PractitionerProtocolRequest !== undefined){
        PractitionerProtocolRequest = {...PractitionerProtocolRequest, isLoadingProtocols: true, loadedProtocolsSuccessfully: undefined, failOnLoadProtocols: false, errorOnLoadProtocols: undefined};
      }

      return {
        ...state,
        list: PractitionerProtocolRequest ? [...state.list?.filter((practitioner: Practitioner)=> practitioner.userId !== action.practitionerId), PractitionerProtocolRequest] : state.list
         
      }
    case 'MEDICALPROTOCOLS_GET_BY_PRACTITIONER_ID_RECEIVE_ACTION':
      var PractitionerProtocolReceive = state.list.find(
        (item: Practitioner) => item.userId === action.practitionerId
      );

      if(PractitionerProtocolReceive !== undefined){
        var currentProtocols = PractitionerProtocolReceive.Protocols || [] as Protocol[];
        var incomingProtocols = action.MedicalProtocols;
        var mergeProtocols = incomingProtocols?.map((protocol: Protocol) => 
          {
            var currentProtocol = currentProtocols.find((currentProtocol: Protocol) => currentProtocol.id === protocol.id);
            if(currentProtocol !== undefined){
              return {...currentProtocol, ...protocol};
            }else{
              return protocol;
            }
          }
        );
        console.log('mergeProtocols', mergeProtocols);
        PractitionerProtocolReceive = {...PractitionerProtocolReceive, isLoadingProtocols: false, loadedProtocolsSuccessfully: true, failOnLoadProtocols: false, errorOnLoadProtocols: undefined, Protocols: mergeProtocols};
      }

      return {
        ...state,
        list: PractitionerProtocolReceive ? [...state.list?.filter((practitioner: Practitioner)=> practitioner.userId !== action.practitionerId), PractitionerProtocolReceive] : state.list
      };
    case 'MEDICALPROTOCOLS_GET_BY_PRACTITIONER_ID_FAIL_ACTION':
      var PractitionerProtocolFail = state.list.find(
        (item: Practitioner) => item.userId === action.practitionerId
      );

      if(PractitionerProtocolFail !== undefined){
        PractitionerProtocolFail = {...PractitionerProtocolFail, isLoadingProtocols: false, loadedProtocolsSuccessfully: false, failOnLoadProtocols: true, errorOnLoadProtocols: action.error};
      }

      return {
        ...state,
        list: PractitionerProtocolFail ? [...state.list?.filter((practitioner: Practitioner)=> practitioner.userId !== action.practitionerId), PractitionerProtocolFail] : state.list
      };
    case 'PRECTITIONER_PROTOCOL_VISITS_GET_ALL_REQUEST_ACTION':
      var PractitionerProtocolVisitsRequest = state.list.find(
        (item: Practitioner) => item.userId === action.practitionerId
      );

      if(PractitionerProtocolVisitsRequest !== undefined){
        PractitionerProtocolVisitsRequest = {...PractitionerProtocolVisitsRequest, isLoadingVisits: true, loadedVisitsSuccessfully: undefined, failOnLoadVisits: false, errorOnLoadVisits: undefined};
      }

      return {
        ...state,
        list: PractitionerProtocolVisitsRequest ? [...state.list?.filter((practitioner: Practitioner)=> practitioner.userId !== action.practitionerId), PractitionerProtocolVisitsRequest] : state.list
      };
    case 'PRECTITIONER_PROTOCOL_VISITS_GET_ALL_RECEIVE_ACTION':
      var PractitionerProtocolVisitsReceive = state.list.find(
        (item: Practitioner) => item.userId === action.practitionerId
      );

      if(PractitionerProtocolVisitsReceive !== undefined){
        PractitionerProtocolVisitsReceive = {...PractitionerProtocolVisitsReceive, isLoadingVisits: false, loadedVisitsSuccessfully: true, failOnLoadVisits: false, errorOnLoadVisits: undefined};
      }

      if(action.visits?.length > 0){
        if(PractitionerProtocolVisitsReceive !== undefined){
          PractitionerProtocolVisitsReceive = {...PractitionerProtocolVisitsReceive, ProtocolVisits: [...PractitionerProtocolVisitsReceive.ProtocolVisits?.filter((visit: any) => visit.protocolId !== action.protocolId) || [], {practitionerId: action.practitionerId, protocolId: action.protocolId, visits: action.visits}]};
        }
      }

      return {
        ...state,
        list: PractitionerProtocolVisitsReceive ? [...state.list?.filter((practitioner: Practitioner)=> practitioner.userId !== action.practitionerId), PractitionerProtocolVisitsReceive] : state.list
      };
    case 'PRECTITIONER_PROTOCOL_VISITS_GET_ALL_FAIL_ACTION':
      var PractitionerProtocolVisitsFail = state.list.find(
        (item: Practitioner) => item.userId === action.practitionerId
      );

      if(PractitionerProtocolVisitsFail !== undefined){
        PractitionerProtocolVisitsFail = {...PractitionerProtocolVisitsFail, isLoadingVisits: false, loadedVisitsSuccessfully: false, failOnLoadVisits: true, errorOnLoadVisits: action.error};
      }

      return {
        ...state,
        list: PractitionerProtocolVisitsFail ? [...state.list?.filter((practitioner: Practitioner)=> practitioner.userId !== action.practitionerId), PractitionerProtocolVisitsFail] : state.list
      };
    default:
      return state;
  }
};
