import { EntityActionState } from "../../Common/EntityState/EntityActionState";

export interface NewSettlementDetail extends EntityActionState {
    settlementId: number;
    contratId?: number;
    settlementResumeId?: number | undefined | null;
    internalSummaryIdentifier?: string | undefined | null;
    description: string;
    conceptId: number;
    conceptName: string;
    uniqueControlId: number;
    isSettledAlready: boolean;
    settlementLinked: number;
    contractStage?: number | null;
    contractStageName?: string | null;
    patientRecordNumber?: number | undefined | null;
    patientNumberInProtocol?: string | undefined | null;
    referredId: number;
    referrerName: string;
    referredId2: number;
    referrerName2: string;
    value: number;
    unitValue: number;
    currency: string;
    comments: string;
    haveWarning: boolean;
    warningDescription: string;
    priceOrigin: number;
    originReferenceId: number;
    costValue: number;
    withHoldPercentage: number;
    withHoldAmount: number;
    overheadValue: number;
    overheadPercentage: number;
    overheadUnitValue: number;
    providerId: number;
    isItemized: boolean;
    isRefundable: boolean;
    isExcluded: boolean;
    markOk: boolean;
    markForReview: boolean;
    dueDate: Date;
    settlementDate: Date;
    type: DetailType;
    calculatingType: number;
    unitAmount: number;
    notEnoughInfo: boolean;
}

export const enum DetailType {
    Automatic = 1,
    Manual = 2,
}