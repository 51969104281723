import React from 'react';
import {
    DefaultButton,
    FontWeights,
    getTheme,
    IIconProps,
    IStackProps,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    TextField,
    Toggle
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ErrorMessageComponent } from '../../../Error/ErrorMessageComponent';

// component functions
import * as ComponentFunctions from './StageForm.Functions';
import { ContractStage } from '../../../../models/Entities/Contracts/ContractStage';
import { NewContractStage } from '../../../../models/Entities/Contracts/NewContractStage';
import CycleSelectorComponent from '../../../../commons/component/Inputs/CyclesSelector/CycleSelectorComponent';
import { StageCycle } from '../../../../models/Entities/Contracts/StageCycle';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

  
interface FormValidation {
    success: boolean;
    errors: string[];
}
    
export const ContractStageFormComponent = (props: ComponentFunctions.Props) => {

const [submittedForm, setSubmittedForm] = useState<boolean>(false);
const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
>();

// entity properties
/*
contractId: number;
stageName: string;
order: number;
comments: string;
isOptional: boolean;
isOnSiteMandatory: boolean;
FailSelectionEnabled: boolean;
cycleId?: number;
cycle?: StageCycle;
stageOrder: number;
emrProtocolVisitIdRelated?: number;
*/


const [stageName, setStageName] = useState<string>();
const [comments, setComments] = useState<string>();
const [isOptional, setIsOptional] = useState<boolean>(false);
const [isOnSiteMandatory, setIsOnSiteMandatory] = useState<boolean>(false);
const [cycleId, setCycleId] = useState<number>();
const [FailSelectionEnabled, setFailSelectionEnabled] = useState<boolean>(false);
const [StageOrder, setStageOrder] = useState<number>(0);
const [EmrProtocolVisitIdRelated, setEmrProtocolVisitIdRelated] = useState<number>();

//useTranslation()
const [t] = useTranslation();

//load necesary data
useEffect(() => {
    props.GetContractById(props.ContractId);
    if (props.ContractStageId) props.GetContractStageById(props.ContractStageId);
}, []);


// save control
useEffect(() => {   
    if (submittedForm && props.isAddedSuccessfully || !props.isUpdatedNewOneSuccessfully) {
        ResetForm();
        props.dismissPanel();
    }
}, [submittedForm, props.isAddedSuccessfully, props.isUpdatedNewOneSuccessfully]);


useEffect(() => {
    if (props.Stage) {
        setStageName(props.Stage.stageName);
        setComments(props.Stage.comments);
        setIsOptional(props.Stage.isOptional);
        setIsOnSiteMandatory(props.Stage.isOnSiteMandatory);
        setCycleId(props.Stage.cycleId);
        setFailSelectionEnabled(props.Stage.failSelectionEnabled);
        setStageOrder(props.Stage.stageOrder);
        setEmrProtocolVisitIdRelated(props.Stage.emrProtocolVisitIdRelated);
    }
}, [props.ContractStageId]);

const ResetForm = () => {
    setSubmittedForm(false);
    setStageName('');
    setComments('');
    setIsOptional(false);
    setIsOnSiteMandatory(false);
    setCycleId(undefined);
    setFailSelectionEnabled(false);
    setStageOrder(0);
};

// Events
const onChangeStageNameTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
) => {
    newValue ? setStageName(newValue) : setStageName('');
};

const onChangeOrderTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
) => {
    newValue ? setStageOrder(parseInt(newValue)) : setStageOrder(0);
};

const onChangeCommentsTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
) => {
    newValue ? setComments(newValue) : setComments('');
};

const onChangeIsOptionalToggleValue = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    checked?: boolean | undefined
) => {
    checked ? setIsOptional(true) : setIsOptional(false);
};

const onChangeIsOnSiteMandatoryToggleValue = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    checked?: boolean | undefined
) => {
    checked ? setIsOnSiteMandatory(true) : setIsOnSiteMandatory(false);
};

const onChangeCycleIdTextFieldValue = (cycles: StageCycle[]) => {
    console.log(cycles);
    cycles.length > 0 ? setCycleId(cycles[0].id) : setCycleId(undefined);
};

const onChangeFailSelectionEnabledToggleValue = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    checked?: boolean | undefined
) => {
    checked ? setFailSelectionEnabled(true) : setFailSelectionEnabled(false);
}


const HandleSaveForm = () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
    let CurrentValues: ContractStage | NewContractStage;
    if (props.Stage) {
      CurrentValues = {
        id: props.Stage.id,
        contractId: props.Stage.contractId,
        stageName: stageName!,
        comments: comments!,
        isOptional: isOptional!,
        isOnSiteMandatory: isOnSiteMandatory!,
        failSelectionEnabled: FailSelectionEnabled!,
        cycleId: cycleId!,
        stageOrder: StageOrder!,
        emrProtocolVisitIdRelated: EmrProtocolVisitIdRelated,
      };
      props.UpdateContractStage(CurrentValues as ContractStage);
    } else {
      CurrentValues = {
        contractId: props.ContractId,
        stageName: stageName!,
        comments: comments!,
        isOptional: isOptional!,
        isOnSiteMandatory: isOnSiteMandatory!,
        failSelectionEnabled: FailSelectionEnabled!,
        cycleId: cycleId!,
        stageOrder: StageOrder!,
        emrProtocolVisitIdRelated: EmrProtocolVisitIdRelated,
      };
      props.AddContractStage(CurrentValues as NewContractStage);
    }
    }
};

const HandleCancelForm = () => {
    ResetForm();
    props.dismissPanel();
};

// footer
const onRenderFooterContent = () => {
    return (
    <div className={contentStyles.footer}>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
            <Trans i18nKey={'stageForm.footer.cancelButton'}/>
        </DefaultButton>
        <PrimaryButton
        onClick={HandleSaveForm}
        styles={buttonStyles}
        className="ConfirmAction"
        >
            <Trans i18nKey={'stageForm.footer.saveButton'}/>
        </PrimaryButton>
    </div>
    );
};

const formFields = (
    <div {...columnProps}>
    <label>{t('stageForm.props.stageName')}{stageName}</label>
    <TextField
        label={t('stageForm.props.stageName')}
        required
        value={stageName}
        onChange={onChangeStageNameTextFieldValue}
        errorMessage={
        submittedForm && !stageName ? t('stageForm.props.requiredName') : ''
        }
    />
    <TextField
        label={t('stageForm.props.comments')}
        multiline
        value={comments}
        onChange={onChangeCommentsTextFieldValue}
    />
    <Toggle
        label={t('stageForm.props.optional')}
        onText={t('stageForm.props.yes')}
        offText={t('stageForm.props.no')}
        checked={isOptional}
        onChange={onChangeIsOptionalToggleValue}
    />
    <Toggle
        label={t('stageForm.props.inPerson')} 
        onText={t('stageForm.props.yes')}
        offText={t('stageForm.props.no')}
        checked={isOnSiteMandatory}
        onChange={onChangeIsOnSiteMandatoryToggleValue}
    />
    <Toggle
        label={t('stageForm.props.enableSelectionFailure')} 
        onText={t('stageForm.props.yes')}
        offText={t('stageForm.props.no')}
        checked={FailSelectionEnabled}
        onChange={onChangeFailSelectionEnabledToggleValue}
    />
    <CycleSelectorComponent 
        ContractId={props.ContractId} 
        onSelectedCycles={onChangeCycleIdTextFieldValue}
        CyclesSelected={props.Stage?.cycleId ? [props.Stage.cycleId] : []} 
        MultiselectEnable={false}    
    />
    <TextField 
        label={t('stageForm.props.order')}
        type="number" 
        defaultValue={StageOrder?.toString()}
        onChange={onChangeOrderTextFieldValue}
    />
</div>
    
);

// form validation

const ValidateForm = (): boolean => {
    let validationResult: boolean = true;

    let FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
};

return (
    <Panel
    isOpen={props.isOpen}
    type={PanelType.medium}
    headerText={t('stageForm.panel.visitInformation')}
    onDismiss={props.dismissPanel}
    closeButtonAriaLabel="Close"
    onRenderFooter={onRenderFooterContent}
    isFooterAtBottom={true}
    >
    {/* <div className={contentStyles.header}>
        <span>Información del Proveedor</span>
        <IconButton
        styles={iconButtonStyles}
        iconProps={cancelIcon}
        ariaLabel="Close popup modal"
        onClick={props.dismissPanel}
        />
    </div> */}
    {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {t('stageForm.panel.reviewInformation')}
        <ul>
            {FormValidationStatus?.errors.map(
            (Error: string, index: number) => (
                <li key={index}>{Error}</li>
            )
            )}
        </ul>
        </MessageBar>
    )}
    <div className={contentStyles.body}>
        {props.failOnAdding &&
        props.error && (
            <ErrorMessageComponent Error={props.error} />
        )}
        {formFields}
    </div>
    </Panel>
);
};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ContractStageFormComponent as any);

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
tokens: { childrenGap: 15 }
// styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
},
header: [
    theme.fonts.mediumPlus,
    {
    flex: '1 1 auto',
    color: theme.palette.neutralPrimary,
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '0px 12px 12px 12px',
    backgroundColor: 'lightgray',
    borderRadius: '10px 10px 0 0'
    }
],
body: {
    flex: '4 4 auto',
    overflowY: 'auto',
    selectors: {
    'p:first-child': { marginTop: 0 },
    'p:last-child': { marginBottom: 0 }
    },
    padding: '0 1em 0 0',
    maxHeight: '85vh'
},
footer: {
    //flex: '4 4 auto',
    padding: '0 24px 24px 24px'
}
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
},
rootHovered: {
    color: theme.palette.neutralDark
}
};
  