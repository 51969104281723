import { AppThunkAction } from '../reducers';

// actions
import * as Actions from '../actions';

// Services
import * as services from '../../services/MedicalProtocols.Services';

// models
import { PatientVisit } from '../../models/Entities/Protocols/PatientVisist';
import { ErrorType } from '../../models/HttpError';
import { PractitionerVisit } from '../../models/Entities/Practitioners/Practitioner';

type KnownAction =
    | Actions.PractitionerProtocolVisits.Pratitioner_Protocol_Visits_GetAll_Request_Action
    | Actions.PractitionerProtocolVisits.Pratitioner_Protocol_Visits_GetAll_Receive_Action
    | Actions.PractitionerProtocolVisits.Pratitioner_Protocol_Visits_GetAll_Fail_Action;

export const actionCreators = {
    GetPractitionerProtocolVisits:
        (practitionerId: string, protocolId: number, dateFrom: Date, dateTo: Date): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var state = getState();
        var token: string;

        // console.log('GetPractitionerProtocolVisits', practitionerId, protocolId, dateFrom, dateTo);

        // const PractitionerProtocolVicits = state.Practitioner?.list.find((practitioner) => practitioner.userId === practitionerId)?.ProtocolVisits?.filter(
        //     (protocolvisits: PractitionerVisit) => 
        //         protocolvisits.protocolId === protocolId
        //     );

        // console.log('PractitionerProtocolVicits', PractitionerProtocolVicits);

        if (1 === 1) {

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'PRECTITIONER_PROTOCOL_VISITS_GET_ALL_FAIL_ACTION',
                    practitionerId: practitionerId,
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'PRECTITIONER_PROTOCOL_VISITS_GET_ALL_REQUEST_ACTION',
                practitionerId: practitionerId,
                protocolId: protocolId,
                dateFrom: dateFrom,
                dateTo: dateTo
            });
    
            services
                .GetPractitionerProtocolVisits(practitionerId, protocolId, dateFrom, dateTo, token)
                .then((visits: PatientVisit[]) => {
                    dispatch({
                        type: 'PRECTITIONER_PROTOCOL_VISITS_GET_ALL_RECEIVE_ACTION',
                        practitionerId: practitionerId,
                        protocolId: protocolId,
                        visits: visits
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'PRECTITIONER_PROTOCOL_VISITS_GET_ALL_FAIL_ACTION',
                        practitionerId: practitionerId,
                        error: error
                    })
                );
           
        }       
    }
};