import { IResult } from '../models/HttpResult';

//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { ContractStage } from '../models/Entities/Contracts/ContractStage';
import { NewContractStage } from '../models/Entities/Contracts/NewContractStage';
import { useSelector } from 'react-redux';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'contracts/';

// Get All Concepts of contract
export const GetContractsStages = async (ContractId: number, token: string): Promise<ContractStage[]> => {

    let RequestURL: string = BaseURL + ContractId + '/stages/';

    try {
        const Response: ContractStage[] = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractStage[]>>;
            }
        })
        .then((data: IResult<ContractStage[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Get contract stage By Id
export const GetContractStageById = async (id: number, token: string): Promise<ContractStage> => {

    let RequestURL: string = BaseURL + 'stages/' + id;
    
    try {
        const Response: ContractStage = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractStage>>;
            }
        })
        .then((data: IResult<ContractStage>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

// Create Contract stage
export const CreateContractStage = async (newContractStage: NewContractStage, token: string): Promise<ContractStage> => {

    let RequestURL: string = BaseURL + 'stages/';
    
    try {
        const Response: ContractStage = await fetch(RequestURL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(newContractStage)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractStage>>;
            }
        })
        .then((data: IResult<ContractStage>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

// Update Contract stage
export const UpdateContractStage = async (contractStage: ContractStage, token: string): Promise<ContractStage> => {

    let RequestURL: string = BaseURL + 'stages/';
    
    try {
        const Response: ContractStage = await fetch(RequestURL, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(contractStage)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractStage>>;
            }
        })
        .then((data: IResult<ContractStage>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Delete Contract stage
export const DeleteContractStage = async (id: number, token: string): Promise<number> => {

    let RequestURL: string = BaseURL + 'stages/' + id;
    
    try {
        const Response: number = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<number>>;
            }
        })
        .then((data: IResult<number>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};