import { IBasePickerSuggestionsProps, ITag } from '@fluentui/react';

//models
import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractConcept } from "../../../../models/Entities/Contracts/ContractConcept";
import { ErrorType } from "../../../../models/HttpError";

// stores
import * as ContractConceptStore from '../../../../redux/middleware/ContractConceptMiddleware';

// common functions
import * as Functions from '../../../../functions/common'

//State
import { AppState } from "../../../../redux/reducers";


interface IProps {
  ContractId: number;
  onSelectedContractConcept: (contract: ContractConcept[]) => void;
  ContractConceptsSelected: number[] | undefined;
  MultiselectEnable: boolean;
  noteText?: string;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  Contract: Contract | undefined;
  ContractConcepts: ContractConcept[];
}

interface DispatchProps {
  GetContractConceptById: typeof ContractConceptStore.actionCreators.GetContractConceptById;
  GetAllContractsConcept: typeof ContractConceptStore.actionCreators.GetAllContractsConcept;
}

export type Props = IProps & ConnectedProps & DispatchProps;

/*
isLoading={false} 
isUpdating={false} 
isUpdatedSuccessfully={false} 
isLoadingSuccessfully={false} 
failOnUpdating={false} 
failOnLoading={false} 
error={undefined} 
Contract={undefined} 
ContractConcepts={[]} 
GetContractConceptById={undefined} 
GetAllContractsConcept={undefined}  
*/

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.ContractConcept?.isLoadingAll,
  isUpdating: state.ContractConcept?.isLoadingAll,
  isUpdatedSuccessfully: state.ContractConcept?.successLoadingAll,
  isLoadingSuccessfully: state.ContractConcept?.successLoadingAll,
  failOnUpdating: state.ContractConcept?.failOnLoadingAll,
  failOnLoading: state.ContractConcept?.failOnLoadingAll,
  error: state.ContractConcept?.error,
  Contract: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId ) : undefined,
  ContractConcepts: (ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId )?.concepts : [])?.sort(Functions.DynamicSortNumeric('id'))
});

export const mapDispatchToProps = {
    ...ContractConceptStore.actionCreators 
};

export const GetContractConceptsOptions = (concepts: ContractConcept[]): ITag[] => {
  return concepts?.map((item: ContractConcept) => {
    return {
      key: item.id,
      name: item.concept.conceptName
    };
  }) as ITag[];
};

export const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some((compareTag) => compareTag.key === tag.key);
};

export const GetContractConceptById = (
  contractConceptId: number, concepts: ContractConcept[]
): ContractConcept => {
  const ContractConcept: ContractConcept = concepts.find(
    (item: ContractConcept) => item.id === contractConceptId
  ) as ContractConcept;
  return ContractConcept;
};

export function GetSelectedContractConcept(
  contractConceptsIds: number[], concepts: ContractConcept[]
): ITag[] {

  const contractConcepts: ITag[] = [];

  contractConceptsIds.forEach((id) => {
    var currentConcept = concepts.find((concept: ContractConcept) => concept.id === id);

    if (currentConcept) {
      contractConcepts.push({
        key: currentConcept.id,
        name: currentConcept.concept.conceptName
      });
    };

  });

  return contractConcepts;
}

export const getTextFromItem = (item: ITag) => item.name;

export const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'conceptos sugeridos',
  noResultsFoundText: 'No se encontro concepto'
};
