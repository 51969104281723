import { ContractStage } from '../../../../models/Entities/Contracts/ContractStage';
import { ErrorType } from '../../../../models/HttpError';


// stores
import * as ContractStageStore from '../../../../redux/middleware/ContractStageMiddleware';
import * as ContractStageCycleStore from '../../../../redux/middleware/ContractStageCycleMeddleware';

//State
import { AppState } from "../../../../redux/reducers";


interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  ContractId: number;
}
  
interface ConnectedProps {
    // loading
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    
    // adding
    isAdding: boolean;
    isAddedSuccessfully: boolean;
    failOnAdding: boolean;

    // Ading Range
    AddRangeFinalized: boolean | undefined;
    
    error: ErrorType | undefined;
}
  
  interface DispatchProps {
    AddContractStageRange: typeof ContractStageStore.actionCreators.AddContractStageRange;
    AddContractStageCycleRange: typeof ContractStageCycleStore.actionCreators.AddContractStageCycleRange;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => ({

    // loading
    isLoading: state.ContractStage?.isLoadingAll,
    isLoadingSuccessfully: state.ContractStage?.successLoadingAll,
    failOnLoading: state.ContractStage?.failOnLoadingAll,

    // adding stage
    isAdding: state.ContractStage?.isAddingNewOne,
    isAddedSuccessfully: state.ContractStage?.successAddingNewOne,
    failOnAdding: state.ContractStage?.failOnAddingNewOne,

    // adding cycle
    isAddingCycle: state.ContractStageCycle?.isAddingNewOne,
    isAddedSuccessfullyCycle: state.ContractStageCycle?.successAddingNewOne,
    failOnAddingCycle: state.ContractStageCycle?.failOnAddingNewOne,

    AddRangeFinalized: state.ContractStage?.isAddingFinishedSuccessfully,


    error: state.ContractStage?.error,
    
  });
  
  export const mapDispatchToProps = {
    ...ContractStageStore.actionCreators,
    ...ContractStageCycleStore.actionCreators
  };