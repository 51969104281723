import { connect } from "react-redux"
import { Contract } from "../../../models/Entities/Contracts/Contract"
import { ContractToClone } from "../../../models/Entities/Contracts/ContractToClone"
import { CompoundButton, DefaultButton, FontIcon, Label, Separator, Spinner } from "@fluentui/react"
import { useEffect } from "react"
import { ContractAddendum } from "../../../models/Entities/Contracts/ContractAddendum"
import { useNavigate } from "react-router-dom"

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// component functions
import * as ComponentFunctions from './Addendums.Functions'

// styles
import './Addendums.Styles.css'
import { ErrorMessageComponent } from "../../Error/ErrorMessageComponent"

const AddendumsComponent = (props: ComponentFunctions.Props) => {

    //useTranslation()
    const [t] = useTranslation();

    const navigate = useNavigate();

    // Effects
    useEffect(() => {
        props.GetContractById(props.ContractId);
    }, [props.ContractId]);

    useEffect(() => {
        props.Contract && props.GetContractAddendumns(props.ContractId);
    }, [props.ContractId, props.Contract !== undefined]);

    useEffect(() => {
        if (props.Contract?.isClonedSuccessfully || props.Contract?.failOnCloning) {
            props.GetContractAddendumns(props.ContractId);
        }
    }, [props.Contract?.isClonedSuccessfully, props.Contract?.failOnCloning]);

    // Actions

    const NavigateToContract = (contractId: number) => {
        navigate('/contracts/' + contractId);
    }

    const CloneContract = (contract: Contract) => {
        const info: ContractToClone = {
            contractToCloneId: contract.id,
            contractName: contract.contractNumber,
            comments: contract.contractDescription,
            isChild: true
        }

        props.CloneContract(info);
    }

    // content

    const NoAdendums = (
        <div>

        </div>
    );

    const GetAddendumCSSClass = (addendum: ContractAddendum, contractId: number): string => {
        var CssClass = '';
        CssClass =+ addendum?.contractId === contractId ? 'Active' : '';
    
        const isCurrent : boolean = ComponentFunctions.isCurrentAddendum(addendum);
        const isExpired : boolean = ComponentFunctions.isExpiredAddendum(addendum);
        
    
        if (isCurrent) {
            CssClass += ' CurrentAddendum';
        }

        if (isExpired) {
            CssClass += ' ExpiredAddendum';
        }
    
        return CssClass;
    }

    const AddendumsComponent = (addendum: ContractAddendum, title: string) => {
        return (
            <div className="AddendumsRow">
                <CompoundButton
                    className={GetAddendumCSSClass(addendum, props.ContractId)}
                    secondaryText={t('contractList.Status.' + ComponentFunctions.GetContractStatusDescripcion(addendum.status))}
                    onClick={() => NavigateToContract(addendum.contractId)}
                >
                    {title}
                </CompoundButton>
                {addendum.addendum && <><FontIcon iconName="ChevronRight" />{AddendumsComponent(addendum.addendum, 'Adenda')}</>}
            </div>
        )
    }


    const Actions = (
        <div>
            <DefaultButton
                text="Agregar Adenda"
                iconProps={{ iconName: props.Contract?.isCloning ? 'Sync' : 'ViewOriginal', className: props.Contract?.isCloning ? 'withRotation' : '' }}
                onClick={() => CloneContract(props.Contract!)}
                className='optionButton'
            />
        </div>
    )

    const Loading = (props.isLoading || props.isGettingAddendums) && (
        <div>
            <Spinner
                ariaLive="assertive"
                labelPosition="top"
            />
        </div>
    );

    return (
        <>
            {props.Contract?.error && <ErrorMessageComponent Error={props.Contract?.error} />}
            <div className="AddendumsContent">
                <div className="AddendumsRow">
                    {Loading}
                    {props.Addendums ? AddendumsComponent(props.Addendums, 'Contrato original') : NoAdendums}
                </div>
                {Actions}
            </div>
        </>

    )
}

export default connect(ComponentFunctions.mapStateToProps, ComponentFunctions.mapDispatchToProps)(AddendumsComponent as any)