import { NewContract } from '../models/Entities/Contracts/NewContract';
import { Contract } from '../models/Entities/Contracts/Contract';
import { IResult } from '../models/HttpResult';

//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { ContractProtocolAssociation } from '../models/Entities/Contracts/ContractProtocolAssociation';
import { ContractPractitionerAssociation } from '../models/Entities/Contracts/ContractPractitionerAssociation';
import { ContractToClone } from '../models/Entities/Contracts/ContractToClone';
import { ContractAddendum } from '../models/Entities/Contracts/ContractAddendum';
import { useSelector } from 'react-redux';
import { AppState } from '../redux/reducers';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'contracts/';
const BaseURLProtocols = process.env.REACT_APP_BILLER_API_GATEWAY + 'protocols/contracts/contracts';
const BaseURLPractitioners = process.env.REACT_APP_BILLER_API_GATEWAY + 'practitioners/contracts/contracts';

// Get All Contracts
export const GetContracts = async (token: string): Promise<Contract[]> => {

    let RequestURL: string = BaseURL;

    try {
        const Response: Contract[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                Sentry.captureException(response);
                throw response;
            } else {
                return response.json() as Promise<IResult<Contract[]>>;
            }
        })
        .then((data: IResult<Contract[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Get contract By Id
export const GetContractById = async (id: number, token: string): Promise<Contract> => {

    let RequestURL: string = BaseURL + id;

    try {
        const Response: Contract = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<Contract>>;
                }
            })
            .then((data: IResult<Contract>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Create Contract
export const CreateContract = async (contract: NewContract, token: string): Promise<Contract> => {

    let RequestURL: string = BaseURL;

    try {
        const Response: Contract = await fetch(RequestURL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(contract)
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<Contract>>;
                }
            })
            .then((data: IResult<Contract>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Update Contract
export const UpdateContract = async (contract: Contract, token: string): Promise<Contract> => {

    let RequestURL: string = BaseURL;

    try {
        const Response: Contract = await fetch(RequestURL, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(contract)
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<Contract>>;
                }
            })
            .then((data: IResult<Contract>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Associate to protocol
export const AssociateToProtocol = async (contractId: number, protocolId: number, token: string): Promise<ContractProtocolAssociation> => {

    let RequestURL: string = BaseURLProtocols;

    try {
        const Response: ContractProtocolAssociation = await fetch(RequestURL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify({
                "contractId": contractId,
                "protocolId": protocolId
            })
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractProtocolAssociation>>;
                }
            })
            .then((data: IResult<ContractProtocolAssociation>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// disassociate from protocol
export const DisassociateFromProtocol = async (contractProtocolId: number, token: string): Promise<number> => {

    let RequestURL: string = BaseURLProtocols + '/' + contractProtocolId;

    try {
        const Response: number = await fetch(RequestURL, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<number>>;
                }
            })
            .then((data: IResult<number>) => {
                return contractProtocolId;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Associate to protocol
export const AssociateToPractitioner = async (contractId: number, PractitionerId: string, token: string): Promise<ContractPractitionerAssociation> => {
    
    let RequestURL: string = BaseURLPractitioners;

    try {
        const Response: ContractPractitionerAssociation = await fetch(RequestURL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify({
                "contractId": contractId,
                "practitionerId": PractitionerId
            })
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractPractitionerAssociation>>;
                }
            })
            .then((data: IResult<ContractPractitionerAssociation>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// disassociate from practitioner
export const DisassociateFromPractitioner = async (contractPractitionerId: number, token: string): Promise<number> => {
    
    let RequestURL: string = BaseURLPractitioners + '/' + contractPractitionerId;

    try {
        const Response: number = await fetch(RequestURL, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<number>>;
                }
            })
            .then((data: IResult<number>) => {
                return contractPractitionerId;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// check protocol contract association
export const CheckProtocolContractAssociation = async (contractId: number, token: string): Promise<number | null> => {

    let RequestURL: string = BaseURLProtocols + '/checkassociation?ContractId=' + contractId;

    try {
        const Response: number | null = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<number | null>>;
                }
            })
            .then((data: IResult<number | null>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// check practitioner contract association
export const CheckPractitionerContractAssociation = async (contractId: number, token: string): Promise<string | null> => {

    let RequestURL: string = BaseURLPractitioners + '/checkassociation?ContractId=' + contractId;

    try {
        const Response: string | null = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<string | null>>;
                }
            })
            .then((data: IResult<string | null>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            ).catch((error: any) => {
                throw Functions.HttpErrorHandler(null, error);
            });
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Change fee division
export const ChangeFeeDivision = async (contractId: number, feeDivision: number, token: string): Promise<number> => {

    let RequestURL: string = BaseURL + contractId.toString() + '/feeDivision/' + feeDivision.toString() + '/';

    try {
        const Response: number = await fetch(RequestURL, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify({
                "contractId": contractId,
                "feeDivision": feeDivision
            })
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<number>>;
                }
            })
            .then((data: IResult<number>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// clone contract 
export const CloneContract = async (contract : ContractToClone, token: string): Promise<Contract> => {

    let RequestURL: string = BaseURL + 'clone';

    try {
        const Response: Contract = await fetch(RequestURL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(contract)
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<Contract>>;
                }
            })
            .then((data: IResult<Contract>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// delete contract
export const DeleteContract = async (contractId: number, token: string): Promise<number> => {

    let RequestURL: string = BaseURL + contractId;

    try {
        const Response: number = await fetch(RequestURL, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<number>>;
                }
            })
            .then((data: IResult<number>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// get contract adeendum
export const GetContractAddendum = async (contractId: number, token: string): Promise<ContractAddendum> => {

    let RequestURL: string = BaseURL + contractId + '/addendums';

    try {
        const Response: ContractAddendum = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<Contract>>;
                }
            })
            .then((data: IResult<Contract>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};