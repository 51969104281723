import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractConcept } from "../../../../models/Entities/Contracts/ContractConcept";
import { ErrorType } from "../../../../models/HttpError";

// stores
import * as ContractConceptStore from '../../../../redux/middleware/ContractConceptMiddleware';

// common functions
import * as Functions from '../../../../functions/common'

//State
import { AppState } from "../../../../redux/reducers";


interface IProps {
    ContractId: number;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    isDeletedSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    failOnDeleting: boolean;
    error: ErrorType | undefined;
    Contract: Contract | undefined;
    ContractConcepts: ContractConcept[];
  }
  
  interface DispatchProps {
    GetContractConceptById: typeof ContractConceptStore.actionCreators.GetContractConceptById;
    GetAllContractsConcept: typeof ContractConceptStore.actionCreators.GetAllContractsConcept;
    DeleteContractConcept: typeof ContractConceptStore.actionCreators.DeleteContractConcept;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.ContractConcept?.isLoadingAll,
    isUpdating: state.ContractConcept?.isLoadingAll,
    isUpdatedSuccessfully: state.ContractConcept?.successLoadingAll,
    isLoadingSuccessfully: state.ContractConcept?.successLoadingAll,
    failOnUpdating: state.ContractConcept?.failOnLoadingAll,
    failOnLoading: state.ContractConcept?.failOnLoadingAll,
    isDeleting: state.ContractConcept?.isDeletingOne,
    isDeletedSuccessfully: state.ContractConcept?.successDeletingOne,
    failOnDeleting: state.ContractConcept?.failOnDeletingOne,
    error: state.ContractConcept?.error,
    Contract: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId ) : undefined,
    ContractConcepts: (ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId )?.concepts : [])?.sort(Functions.DynamicSortNumeric('id'))
    
  });
  
  export const mapDispatchToProps = {
    ...ContractConceptStore.actionCreators
  };