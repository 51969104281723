import { NewPriceDetails } from './../../models/Entities/PriceList/NewPriceDetail';
import { PriceDetails } from './../../models/Entities/PriceList/PriceDetail';
import { AppThunkAction } from '../reducers';
import { ErrorType } from '../../models/HttpError';

// services
import * as Services from '../../services/PriceList.Services';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.PriceLists.PriceListAddPrice_Request_Action
  | Actions.PriceLists.PriceListAddPrice_Receive_Action
  | Actions.PriceLists.PriceListAddPrice_Fail_Action
  | Actions.PriceLists.PriceListUpdatePrice_Request_Action
  | Actions.PriceLists.PriceListUpdatePrice_Receive_Action
  | Actions.PriceLists.PriceListUpdatePrice_Fail_Action
  | Actions.PriceLists.PriceListDeletePrice_Request_Action
  | Actions.PriceLists.PriceListDeletePrice_Receive_Action
  | Actions.PriceLists.PriceListDeletePrice_Fail_Action
  | Actions.PriceLists.PriceListChangePrice_Action
  | Actions.PriceLists.PriceListChangeRemotePrice_Action
  | Actions.PriceLists.PriceListChangeFailPrice_Action
  | Actions.PriceLists.PriceListChangeRandomPrice_Action;

export const actionCreators = {
  AddNewPrice:
    (newPrice: NewPriceDetails): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRICE_LIST_ADD_PRICE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRICE_LIST_ADD_PRICE_REQUEST_ACTION',
        newPrice: newPrice
      });

      Services.AddPriceToPriceList(newPrice, token)
        .then((price: PriceDetails) => {
          dispatch({
            type: 'PRICE_LIST_ADD_PRICE_RECEIVE_ACTION',
            Price: price
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRICE_LIST_ADD_PRICE_FAIL_ACTION',
            error: error
          })
        );
    },
  SavePrice:
    (price: PriceDetails): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRICE_LIST_UPDATE_PRICE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRICE_LIST_UPDATE_PRICE_REQUEST_ACTION',
        Price: price
      });
      Services.SavePrice(price, token)
        .then((price: PriceDetails) => {
          dispatch({
            type: 'PRICE_LIST_UPDATE_PRICE_RECEIVE_ACTION',
            Price: price
          });
        })
        .catch((error: ErrorType) => {
          dispatch({
            type: 'PRICE_LIST_UPDATE_PRICE_FAIL_ACTION',
            error: error
          });
        });
    },
  DeletePrice:
    (price: PriceDetails): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRICE_LIST_DELETE_PRICE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRICE_LIST_DELETE_PRICE_REQUEST_ACTION',
        PriceListId: price.pricelistEntityId,
        PriceID: price.id
      });

      Services.DeletePrice(price, token)
        .then((price: PriceDetails) => {
          dispatch({
            type: 'PRICE_LIST_DELETE_PRICE_RECEIVE_ACTION',
            PriceListId: price.pricelistEntityId,
            PriceID: price.id
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRICE_LIST_DELETE_PRICE_FAIL_ACTION',
            error: error
          })
        );
    },
  ChangePrice:
    (price: PriceDetails): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({
        type: 'PRICE_LIST_CHANGE_PRICE_ACTION',
        Price: price
      });
    },
  ChangeRemotePrice:
    (price: PriceDetails): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({
        type: 'PRICE_LIST_CHANGE_REMOTE_PRICE_ACTION',
        Price: price
      });
    },
  ChangeFailPrice:
    (price: PriceDetails): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({
        type: 'PRICE_LIST_CHANGE_FAIL_PRICE_ACTION',
        Price: price
      });
    },
  ChangeRandomPrice:
    (price: PriceDetails): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({
        type: 'PRICE_LIST_CHANGE_RANDOM_PRICE_ACTION',
        Price: price
      });
    }
};
