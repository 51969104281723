import { connect } from "react-redux";
import React, { useState } from "react";

// functions
import * as ComponentFunctions from './PracitionerProtocolActivity.Functions';
import { useEffect } from "react";
import { PractitionerProtocolActivityMonth } from "./PractitionerProtocolActivityMonth";
import { Protocol } from "../../../models/Entities/Protocols/Protocol";


const PractitionerProtocolActivityComponent = (props: ComponentFunctions.Props) => {

    const [FilteredProtocols, setFilteredProtocols] = useState<Protocol[]>([]);

    useEffect(() => {
        props.PractitionerId && props.GetPractitionerById(props.PractitionerId);
    }, [props.PractitionerId]);

    useEffect(() => {
        props.GetProtocolsByPractitionerId(props.PractitionerId);
    }, [props.Practitioner !== undefined]);

    useEffect(() => {
        if (props.Practitioner && props.Protocols.length > 0) {
            var auxDateFrom = new Date();
            var auxDateTo = new Date();
            // set auxDateTo to the last day of the month
            auxDateTo.setDate(0);

            auxDateFrom.setMonth(auxDateFrom.getMonth() - props.Months || 3);
            // set auxDateFrom to the first day of the month
            auxDateFrom.setDate(1);

            var filteredProtocols = props.protocolsIds?.length > 0 ?  props.Protocols?.filter((protocol: Protocol) => props.protocolsIds.some((id: number) => id === protocol.id)) : props.Protocols;
            setFilteredProtocols(filteredProtocols);

            filteredProtocols?.forEach((protocol) => {
                props.GetPractitionerProtocolVisits(props.PractitionerId, protocol.id, auxDateFrom, auxDateTo);
            });
        }

    }, [props.Protocols, props.Months, props.protocolsIds]);


    return (
        <div className="ActivityContainer">
            {ComponentFunctions.getLastXMonths(props.Months || 3).map(month =>
                FilteredProtocols && <PractitionerProtocolActivityMonth month={month.month} year={month.year} visits={props.visitis} protocols={FilteredProtocols} practitionerId={props.PractitionerId} />
            )}
        </div>
    );
};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(PractitionerProtocolActivityComponent as any);