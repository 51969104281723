import { IconButton, Label, PrimaryButton } from "@fluentui/react";

// functions
import * as ComponentFunctions from './AccountTransaction.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';


export const AccountTransactionComponent = () => {
    
    //useTranslation()
    const [t] = useTranslation()
    const NoData = (
        <>
            <p className="NoData">{t('accountTransactionComponent.transaction.noTransaction')} </p>
        </>
    )

    const Controls = (
        <>
            <div className="SectionControls">
                <PrimaryButton>{t('accountTransactionComponent.transaction.addMovement')}</PrimaryButton>
            </div>
        </>
    )

    const Transactions = (
        <>
            <table className="Table">
                <thead>
                    <tr>
                        <th>{t('accountTransactionComponent.transaction.movement')}</th>
                        <th>{t('accountTransactionComponent.transaction.date')}</th>
                        <th>{t('accountTransactionComponent.transaction.amount')}</th>
                        <th style={{ width: '150px' }}>{t('accountTransactionComponent.transaction.options')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                            <td className="primary">
                                <Label>{t('accountTransactionComponent.transaction.liquidation')}</Label>
                            </td>
                            <td className="primary">
                                <Label></Label>
                            </td>
                            <td className="primary">
                                <Label>{(Math.random() * (23000 - 10000) + 10000).toLocaleString('us-US', { style: 'currency', currency: 'USD'?.toUpperCase() }) || '0.00'}&nbsp;<small>({"usd"})</small></Label>
                            </td>
                            <td style={{ width: '150px' }}>
                                <IconButton
                                    iconProps={ComponentFunctions.EditIcon}
                                    title={t('accountTransactionComponent.transaction.modify')}
                                    ariaLabel="Edit"
                                    onClick={(event) => undefined}
                                />
                                <IconButton
                                    iconProps={ComponentFunctions.AgreementsIcon}
                                    title={t('accountTransactionComponent.transaction.agreements')}
                                    ariaLabel="AddAgreements"
                                    onClick={() => undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="primary">
                                <Label>{t('accountTransactionComponent.transaction.liquidation')}</Label>
                            </td>
                            <td className="primary">
                                <Label></Label>
                            </td>
                            <td className="primary">
                                <Label>{(Math.random() * (23000 - 10000) + 10000).toLocaleString('us-US', { style: 'currency', currency: 'USD'?.toUpperCase() }) || '0.00'}&nbsp;<small>({"usd"})</small></Label>
                            </td>
                            <td style={{ width: '150px' }}>
                                <IconButton
                                    iconProps={ComponentFunctions.EditIcon}
                                    title={t('accountTransactionComponent.transaction.modify')}
                                    ariaLabel="Edit"
                                    onClick={(event) => undefined}
                                />
                                <IconButton
                                    iconProps={ComponentFunctions.AgreementsIcon}
                                    title={t('accountTransactionComponent.transaction.agreements')}
                                    ariaLabel="AddAgreements"
                                    onClick={() => undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="primary">
                                <Label>{t('accountTransactionComponent.transaction.liquidation')}</Label>
                            </td>
                            <td className="primary">
                                <Label></Label>
                            </td>
                            <td className="primary">
                                <Label>{(Math.random() * (23000 - 10000) + 10000).toLocaleString('us-US', { style: 'currency', currency: 'USD'?.toUpperCase() }) || '0.00'}&nbsp;<small>({"usd"})</small></Label>
                            </td>
                            <td style={{ width: '150px' }}>
                                <IconButton
                                    iconProps={ComponentFunctions.EditIcon}
                                    title={t('accountTransactionComponent.transaction.modify')}
                                    ariaLabel="Edit"
                                    onClick={(event) => undefined}
                                />
                                <IconButton
                                    iconProps={ComponentFunctions.AgreementsIcon}
                                    title={t('accountTransactionComponent.transaction.agreements')}
                                    ariaLabel="AddAgreements"
                                    onClick={() => undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="primary">
                                <Label>{t('accountTransactionComponent.transaction.liquidation')}</Label>
                            </td>
                            <td className="primary">
                                <Label></Label>
                            </td>
                            <td className="primary">
                                <Label>{(Math.random() * (23000 - 10000) + 10000).toLocaleString('us-US', { style: 'currency', currency: 'USD'?.toUpperCase() }) || '0.00'}&nbsp;<small>({"usd"})</small></Label>
                            </td>
                            <td style={{ width: '150px' }}>
                                <IconButton
                                    iconProps={ComponentFunctions.EditIcon}
                                    title={t('accountTransactionComponent.transaction.modify')}
                                    ariaLabel="Edit"
                                    onClick={(event) => undefined}
                                />
                                <IconButton
                                    iconProps={ComponentFunctions.AgreementsIcon}
                                    title={t('accountTransactionComponent.transaction.agreements')}
                                    ariaLabel="AddAgreements"
                                    onClick={() => undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="primary">
                                <Label>{t('accountTransactionComponent.transaction.liquidation')}</Label>
                            </td>
                            <td className="primary">
                                <Label></Label>
                            </td>
                            <td className="primary">
                                <Label>{(Math.random() * (23000 - 10000) + 10000).toLocaleString('us-US', { style: 'currency', currency: 'USD'?.toUpperCase() }) || '0.00'}&nbsp;<small>({"usd"})</small></Label>
                            </td>
                            <td style={{ width: '150px' }}>
                                <IconButton
                                    iconProps={ComponentFunctions.EditIcon}
                                    title={t('accountTransactionComponent.transaction.modify')}
                                    ariaLabel="Edit"
                                    onClick={(event) => undefined}
                                />
                                <IconButton
                                    iconProps={ComponentFunctions.AgreementsIcon}
                                    title={t('accountTransactionComponent.transaction.agreements')}
                                    ariaLabel="AddAgreements"
                                    onClick={() => undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="primary">
                                <Label>{t('accountTransactionComponent.transaction.liquidation')}</Label>
                            </td>
                            <td className="primary">
                                <Label></Label>
                            </td>
                            <td className="primary">
                                <Label>{(Math.random() * (23000 - 10000) + 10000).toLocaleString('us-US', { style: 'currency', currency: 'USD'?.toUpperCase() }) || '0.00'}&nbsp;<small>({"usd"})</small></Label>
                            </td>
                            <td style={{ width: '150px' }}>
                                <IconButton
                                    iconProps={ComponentFunctions.EditIcon}
                                    title={t('accountTransactionComponent.transaction.modify')}
                                    ariaLabel="Edit"
                                    onClick={(event) => undefined}
                                />
                                <IconButton
                                    iconProps={ComponentFunctions.AgreementsIcon}
                                    title={t('accountTransactionComponent.transaction.agreements')}
                                    ariaLabel="AddAgreements"
                                    onClick={() => undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="primary">
                                <Label>{t('accountTransactionComponent.transaction.liquidation')}</Label>
                            </td>
                            <td className="primary">
                                <Label></Label>
                            </td>
                            <td className="primary">
                                <Label>{(Math.random() * (23000 - 10000) + 10000).toLocaleString('us-US', { style: 'currency', currency: 'USD'?.toUpperCase() }) || '0.00'}&nbsp;<small>({"usd"})</small></Label>
                            </td>
                            <td style={{ width: '150px' }}>
                                <IconButton
                                    iconProps={ComponentFunctions.EditIcon}
                                    title={t('accountTransactionComponent.transaction.modify')}
                                    ariaLabel="Edit"
                                    onClick={(event) => undefined}
                                />
                                <IconButton
                                    iconProps={ComponentFunctions.AgreementsIcon}
                                    title={t('accountTransactionComponent.transaction.agreements')}
                                    ariaLabel="AddAgreements"
                                    onClick={() => undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="primary">
                                <Label>{t('accountTransactionComponent.transaction.liquidation')}</Label>
                            </td>
                            <td className="primary">
                                <Label></Label>
                            </td>
                            <td className="primary">
                                <Label>{(Math.random() * (23000 - 10000) + 10000).toLocaleString('us-US', { style: 'currency', currency: 'USD'?.toUpperCase() }) || '0.00'}&nbsp;<small>({"usd"})</small></Label>
                            </td>
                            <td style={{ width: '150px' }}>
                                <IconButton
                                    iconProps={ComponentFunctions.EditIcon}
                                    title={t('accountTransactionComponent.transaction.modify')}
                                    ariaLabel="Edit"
                                    onClick={(event) => undefined}
                                />
                                <IconButton
                                    iconProps={ComponentFunctions.AgreementsIcon}
                                    title={t('accountTransactionComponent.transaction.agreements')}
                                    ariaLabel="AddAgreements"
                                    onClick={() => undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="primary">
                                <Label>{t('accountTransactionComponent.transaction.liquidation')}</Label>
                            </td>
                            <td className="primary">
                                <Label></Label>
                            </td>
                            <td className="primary">
                                <Label>{(Math.random() * (23000 - 10000) + 10000).toLocaleString('us-US', { style: 'currency', currency: 'USD'?.toUpperCase() }) || '0.00'}&nbsp;<small>({"usd"})</small></Label>
                            </td>
                            <td style={{ width: '150px' }}>
                                <IconButton
                                    iconProps={ComponentFunctions.EditIcon}
                                    title={t('accountTransactionComponent.transaction.modify')}
                                    ariaLabel="Edit"
                                    onClick={(event) => undefined}
                                />
                                <IconButton
                                    iconProps={ComponentFunctions.AgreementsIcon}
                                    title={t('accountTransactionComponent.transaction.agreements')}
                                    ariaLabel="AddAgreements"
                                    onClick={() => undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="primary">
                                <Label>{t('accountTransactionComponent.transaction.liquidation')}</Label>
                            </td>
                            <td className="primary">
                                <Label></Label>
                            </td>
                            <td className="primary">
                                <Label>{(Math.random() * (23000 - 10000) + 10000).toLocaleString('us-US', { style: 'currency', currency: 'USD'?.toUpperCase() }) || '0.00'}&nbsp;<small>({"usd"})</small></Label>
                            </td>
                            <td style={{ width: '150px' }}>
                                <IconButton
                                    iconProps={ComponentFunctions.EditIcon}
                                    title={t('accountTransactionComponent.transaction.modify')}
                                    ariaLabel="Edit"
                                    onClick={(event) => undefined}
                                />
                                <IconButton
                                    iconProps={ComponentFunctions.AgreementsIcon}
                                    title={t('accountTransactionComponent.transaction.agreements')}
                                    ariaLabel="AddAgreements"
                                    onClick={() => undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="primary">
                                <Label>{t('accountTransactionComponent.transaction.liquidation')}</Label>
                            </td>
                            <td className="primary">
                                <Label></Label>
                            </td>
                            <td className="primary">
                                <Label>{(Math.random() * (23000 - 10000) + 10000).toLocaleString('us-US', { style: 'currency', currency: 'USD'?.toUpperCase() }) || '0.00'}&nbsp;<small>({"usd"})</small></Label>
                            </td>
                            <td style={{ width: '150px' }}>
                                <IconButton
                                    iconProps={ComponentFunctions.EditIcon}
                                    title={t('accountTransactionComponent.transaction.modify')}
                                    ariaLabel="Edit"
                                    onClick={(event) => undefined}
                                />
                                <IconButton
                                    iconProps={ComponentFunctions.AgreementsIcon}
                                    title={t('accountTransactionComponent.transaction.agreements')}
                                    ariaLabel="AddAgreements"
                                    onClick={() => undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="primary">
                                <Label>{t('accountTransactionComponent.transaction.liquidation')}</Label>
                            </td>
                            <td className="primary">
                                <Label></Label>
                            </td>
                            <td className="primary">
                                <Label>{(Math.random() * (23000 - 10000) + 10000).toLocaleString('us-US', { style: 'currency', currency: 'USD'?.toUpperCase() }) || '0.00'}&nbsp;<small>({"usd"})</small></Label>
                            </td>
                            <td style={{ width: '150px' }}>
                                <IconButton
                                    iconProps={ComponentFunctions.EditIcon}
                                    title={t('accountTransactionComponent.transaction.modify')}
                                    ariaLabel="Edit"
                                    onClick={(event) => undefined}
                                />
                                <IconButton
                                    iconProps={ComponentFunctions.AgreementsIcon}
                                    title={t('accountTransactionComponent.transaction.agreements')}
                                    ariaLabel="AddAgreements"
                                    onClick={() => undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="primary">
                                <Label>{t('accountTransactionComponent.transaction.liquidation')}</Label>
                            </td>
                            <td className="primary">
                                <Label></Label>
                            </td>
                            <td className="primary">
                                <Label>{(Math.random() * (23000 - 10000) + 10000).toLocaleString('us-US', { style: 'currency', currency: 'USD'?.toUpperCase() }) || '0.00'}&nbsp;<small>({"usd"})</small></Label>
                            </td>
                            <td style={{ width: '150px' }}>
                                <IconButton
                                    iconProps={ComponentFunctions.EditIcon}
                                    title={t('accountTransactionComponent.transaction.modify')}
                                    ariaLabel="Edit"
                                    onClick={(event) => undefined}
                                />
                                <IconButton
                                    iconProps={ComponentFunctions.AgreementsIcon}
                                    title={t('accountTransactionComponent.transaction.agreements')}
                                    ariaLabel="AddAgreements"
                                    onClick={() => undefined}
                                />
                            </td>
                        </tr>
                </tbody>
            </table>
        </>)

    return (
        <>
            {Controls}
            {Transactions}
        </>
    );
}

export default AccountTransactionComponent;