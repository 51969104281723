import { AppState } from '../../../redux/reducers';
import { Contract } from '../../../models/Entities/Contracts/Contract';
import { ErrorType } from '../../../models/HttpError';

//stores 
import * as ContractsStore from '../../../redux/middleware/ContractMiddleware'

//#region Props
interface IProps {
  ContractId: number;
}

interface ConnectedProps {
  isLoading: boolean;
  isLoadingSuccessfully: boolean;
  failOnLoading: boolean;
  isUpdatingFeeDivision: boolean;
  FeeDivisionChangesSuccessfully: boolean | undefined;
  FailChangingFeeDivision: boolean;
  error: ErrorType | undefined;
  Contract: Contract | undefined;
}

interface DispatchProps {
  GetContractById: typeof ContractsStore.actionCreators.GetContractById;
  ChangeFeeDivision: typeof ContractsStore.actionCreators.ChangeFeeDivision;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.Contract?.isLoadingAll,
    isLoadingSuccessfully: state.Contract?.successLoadingAll,
    failOnLoading: state.Contract?.failOnLoadingAll,
    failOnDeleting: state.Contract?.failOnDeletingOne,
    isUpdatingFeeDivision: state.Contract?.isChangingFeeDivision,
    FeeDivisionChangesSuccessfully: state.Contract?.FeeDivisionChangesSuccessfully,
    FailChangingFeeDivision: state.Contract?.FailChangingFeeDivision,
    error: state.Contract?.error,
    Contract: state.Contract?.list?.find(c => c.id === ownProps.ContractId)       
});

export const mapDispatchToProps = {
    ...ContractsStore.actionCreators
};
//#endregion Props