// Contract Concept Middleware

import { ErrorType } from '../../models/HttpError';
import { AppThunkAction } from '../reducers';
import { Contract } from '../../models/Entities/Contracts/Contract';
import { ContractConcept } from '../../models/Entities/Contracts/ContractConcept';
import { NewContractConcept } from '../../models/Entities/Contracts/NewContractConcept';
import { Concept } from '../../models/Entities/Contracts/Concept';

// services
import * as Services from '../../services/ContractConcept.Services';
import * as ConceptConceptsServices from '../../services/ContractConceptConcept.Services';
import * as PaymentScheduleServices from '../../services/ContractConceptPaymentSchedule.Services';

// actions
import * as Actions from '../actions';
import { NewContractConceptConcept } from '../../models/Entities/Contracts/NewContractConceptConcept';
import { NewContractConceptPaymentSchedule } from '../../models/Entities/Contracts/NewContractConceptPaymentSchedule';
import { ContractConceptConcept } from '../../models/Entities/Contracts/ContractConceptConcept';
import { ContractConceptPaymentSchedule } from '../../models/Entities/Contracts/ContractConceptPaymentSchedule';


type KnownAction =
    | Actions.ContractConcepts.ContractConcept_GetAllBycontractId_Request_Action
    | Actions.ContractConcepts.ContractConcept_GetAllBycontractId_Receive_Action
    | Actions.ContractConcepts.ContractConcept_GetAllBycontractId_Fail_Action
    | Actions.ContractConcepts.ContractConcept_UpdateAllByContractId_Request_Action
    | Actions.ContractConcepts.ContractConcept_UpdateAllByContractId_Receive_Action
    | Actions.ContractConcepts.ContractConcept_UpdateAllByContractId_Fail_Action
    | Actions.ContractConcepts.ContractConcept_GetById_Request_Action
    | Actions.ContractConcepts.ContractConcept_GetById_Receive_Action
    | Actions.ContractConcepts.ContractConcept_GetById_Fail_Action
    | Actions.ContractConcepts.ContractConcept_Add_Request_Action
    | Actions.ContractConcepts.ContractConcept_Add_Receive_Action
    | Actions.ContractConcepts.ContractConcept_Add_Fail_Action
    | Actions.ContractConcepts.ContractConcept_Update_Request_Action
    | Actions.ContractConcepts.ContractConcept_Update_Receive_Action
    | Actions.ContractConcepts.ContractConcept_Update_Fail_Action
    | Actions.ContractConcepts.ContractConcept_Delete_Request_Action
    | Actions.ContractConcepts.ContractConcept_Delete_Receive_Action
    | Actions.ContractConcepts.ContractConcept_Delete_Fail_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_Delete_Request_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_Delete_Receive_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_Add_Receive_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Delete_Receive_Action



export const actionCreators = {
    GetAllContractsConcept:
        (contractId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACTCONCEPT_GET_ALL_BY_CONTRACTID_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            if(state.Contract?.list?.find((contract: Contract) => contract.id === contractId)?.concepts?.length === 0) return;

            if (!state.Contract?.successLoadingAll || 
                state.Contract?.list?.find((contract: Contract) => contract.id === contractId)?.concepts?.length === 0 ) {
                dispatch({
                    type: 'CONTRACTCONCEPT_GET_ALL_BY_CONTRACTID_REQUEST_ACTION',
                    contractId: contractId
                });

                Services.GetContractsConcept(contractId, token)
                    .then((contractConcepts: ContractConcept[]) => {
                        dispatch({
                            type: 'CONTRACTCONCEPT_GET_ALL_BY_CONTRACTID_RECEIVE_ACTION',
                            contractConcepts: contractConcepts,
                            contractId: contractId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTCONCEPT_GET_ALL_BY_CONTRACTID_FAIL_ACTION',
                            error: error
                        })
                    );
            } else {
                dispatch({
                    type: 'CONTRACTCONCEPT_UPDATE_ALL_BY_CONTRACTID_REQUEST_ACTION',
                    contractId: contractId
                });

                Services.GetContractsConcept(contractId, token)
                    .then((contractConcepts: ContractConcept[]) => {
                        dispatch({
                            type: 'CONTRACTCONCEPT_UPDATE_ALL_BY_CONTRACTID_RECEIVE_ACTION',
                            contractConcepts: contractConcepts,
                            contractId: contractId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTCONCEPT_GET_ALL_BY_CONTRACTID_FAIL_ACTION',
                            error: error
                        })
                    );
            }
        },
    GetContractConceptById:
        (id: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACTCONCEPT_GET_BY_ID_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            if(
                state.ContractConcept?.list?.find((contractConcept: ContractConcept) => contractConcept.id === id)) return;

            dispatch({
                type: 'CONTRACTCONCEPT_GET_BY_ID_REQUEST_ACTION',
                contractConceptId: id
            });

            Services.GetContractConceptById(id, token)
            .then((contractConcept: ContractConcept) => {
                dispatch({
                    type: 'CONTRACTCONCEPT_GET_BY_ID_RECEIVE_ACTION',
                    contractConcept: contractConcept
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACTCONCEPT_GET_BY_ID_FAIL_ACTION',
                    error: error
                })
            );

        },
    AddContractConcept:
        (newContractConcept: NewContractConcept): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
        var state = getState();
        var token: string;

        if (state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACTCONCEPT_ADD_FAIL_ACTION',
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            });
            return;
        } else {
            token = state.oidc.user.access_token;
        }

        dispatch({
            type: 'CONTRACTCONCEPT_ADD_REQUEST_ACTION',
            newContractConcept: newContractConcept
        });

        Services.CreateConcept(newContractConcept.concept, token).then((concept: Concept) => {
            // set conceptID
            newContractConcept.conceptId = concept.id;
            Services.CreateContractConcept(newContractConcept, token)
            .then((contractConcept: ContractConcept) => {

                // // delete old concepts 
                // if (newContractConcept.concepts !== undefined) {
                //     newContractConcept.concepts.forEach((ItemConcept: ContractConceptConcept | NewContractConceptConcept) => {
                //         if((ItemConcept as ContractConceptConcept).id !== undefined)
                //         {
                //             var currentConcept = (ItemConcept as ContractConceptConcept);
                //             ConceptConceptsServices.DeleteContractConceptConcept(currentConcept.id, token);
                //             dispatch({
                //                 type: 'CONTRACTCONCEPTCONCEPT_DELETE_RECEIVE_ACTION',
                //                 contractConceptConceptId: currentConcept.id,
                //                 contractConceptId: contractConcept.id,
                //                 contractId: contractConcept.contractId
                //             });
                //         }
                        
                //     });
                // }

                // save concepts
                newContractConcept.concepts?.forEach((concept: NewContractConceptConcept) => {
                    concept.contractConceptId = contractConcept.id;
                    ConceptConceptsServices.CreateContractConceptConcept(concept, token).then((contractConceptConcept: ContractConceptConcept) => {
                    
                        dispatch({
                            type: 'CONTRACTCONCEPTCONCEPT_ADD_RECEIVE_ACTION',
                            contractConceptConcept: contractConceptConcept,
                            contractId: contractConcept.contractId
                        });

                    });
                });

                //save payment schedule
                newContractConcept.paymentSchedule?.forEach((paymentSchedule: NewContractConceptPaymentSchedule) => {
                    paymentSchedule.contractConceptId = contractConcept.id;
                    PaymentScheduleServices.CreateContractConceptPaymentSchedule(paymentSchedule, token);
                });

                dispatch({
                    type: 'CONTRACTCONCEPT_ADD_RECEIVE_ACTION',
                    contractConcept: contractConcept
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACTCONCEPT_ADD_FAIL_ACTION',
                    error: error
                })
            );
        })
        .catch((error: ErrorType) =>
            dispatch({
                type: 'CONTRACTCONCEPT_ADD_FAIL_ACTION',
                error: error
            })
        );
    },
    UpdateContractConcept:
        (updatedContractConcept: ContractConcept): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACTCONCEPT_UPDATE_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'CONTRACTCONCEPT_UPDATE_REQUEST_ACTION',
                contractConcept: updatedContractConcept
            });

            Services.UpdateConcept(updatedContractConcept.concept, token).then((concept: Concept) => {
                updatedContractConcept.conceptId = concept.id;
            });

            Services.UpdateContractConcept(updatedContractConcept, token)
                .then((contractConcept: ContractConcept) => {

                    // delete old concepts 

                    var CurrentConceptInState = state.Contract?.list?.find((contract: Contract) => contract.id === contractConcept.contractId)?.concepts?.find((concept: ContractConcept) => concept.id === contractConcept.id);

                    if (CurrentConceptInState?.concepts !== undefined) {
                        CurrentConceptInState?.concepts.forEach((ItemConcept: ContractConceptConcept) => {

                            if((ItemConcept as ContractConceptConcept).id !== undefined)
                            {
                                dispatch({
                                    type: 'CONTRACTCONCEPTCONCEPT_DELETE_REQUEST_ACTION',
                                    contractConceptConceptId: (ItemConcept as ContractConceptConcept).id,
                                });
                                var currentConcept = (ItemConcept as ContractConceptConcept);
                                ConceptConceptsServices.DeleteContractConceptConcept(currentConcept.id, token);
                                dispatch({
                                    type: 'CONTRACTCONCEPTCONCEPT_DELETE_RECEIVE_ACTION',
                                    contractConceptConceptId: currentConcept.id,
                                    contractConceptId: contractConcept.id,
                                    contractId: contractConcept.contractId
                                });
                            }
                            
                        });
                    }

                    // save concepts
                    updatedContractConcept.concepts?.forEach((concept: NewContractConceptConcept) => {
                        concept.contractConceptId = contractConcept.id;
                        ConceptConceptsServices.CreateContractConceptConcept(concept, token);
                    });

                    // delete old payment schedule
                    if (updatedContractConcept.paymentSchedule !== undefined) {
                        updatedContractConcept.paymentSchedule.forEach((ItemConcept: ContractConceptPaymentSchedule | NewContractConceptPaymentSchedule) => {
                            if((ItemConcept as ContractConceptPaymentSchedule).id !== undefined)
                            {
                                var currentConcept = (ItemConcept as ContractConceptPaymentSchedule);
                                PaymentScheduleServices.DeleteContractConceptPaymentSchedule(currentConcept.id, token);
                                dispatch({
                                    type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_DELETE_RECEIVE_ACTION',
                                    contractConceptPaymentScheduleId: currentConcept.id,
                                    contractConceptId: contractConcept.id,
                                    contractId: contractConcept.contractId
                                });
                            }
                            
                        });
                    }

                    //save payment schedule
                    updatedContractConcept.paymentSchedule?.forEach((paymentSchedule: NewContractConceptPaymentSchedule) => {
                        paymentSchedule.contractConceptId = contractConcept.id;
                        PaymentScheduleServices.CreateContractConceptPaymentSchedule(paymentSchedule, token);
                    });

                    console.log("finaliza el grabado de payment schedule");
                    dispatch({
                        type: 'CONTRACTCONCEPT_UPDATE_RECEIVE_ACTION',
                        contractConcept: contractConcept
                    });
                })
                .catch(error => console.log(error));
                // .catch((error: ErrorType) =>
                //     dispatch({
                //         type: 'CONTRACTCONCEPT_UPDATE_FAIL_ACTION',
                //         error: error
                //     })
                // );

        },
    DeleteContractConcept:
        (id: number, contractId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
        
        var state = getState();
        var token: string;

        if (state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACTCONCEPT_DELETE_FAIL_ACTION',
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            });
            return;
        } else {
            token = state.oidc.user.access_token;
        }

        dispatch({
            type: 'CONTRACTCONCEPT_DELETE_REQUEST_ACTION',
            contractConceptId: id,
            contractId: contractId
        });

        Services.DeleteContractConcept(id, token)
            .then((result: boolean) => {
                dispatch({
                    type: 'CONTRACTCONCEPT_DELETE_RECEIVE_ACTION',
                    contractConceptId: id,
                    contractId: contractId
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACTCONCEPT_DELETE_FAIL_ACTION',
                    error: error
                })
            );

        }
};
