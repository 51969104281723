import { Checkbox, TextField, Text, MessageBarType, Label, FontSizes, Separator } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { MessageComponent } from '../../../../../commons/component/MessageComponent';
import { NewContractStage } from '../../../../../models/Entities/Contracts/NewContractStage';
import { ContractConceptPaymentSchedule } from '../../../../../models/Entities/Contracts/ContractConceptPaymentSchedule';
import { ContractStage } from '../../../../../models/Entities/Contracts/ContractStage';

// styles
import '../ProtocolContractFormComponent.css';
import '../../../../../App.css'

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// functions
import * as CommondFunctions from '../../../../../functions/common';

interface ContractStageSelection extends ContractStage {
  isSelected: boolean;
}

export interface IProps {
  stages: ContractStage[];
  inputValue: boolean;
  currency: string;
  overheadAverage?: number;
  currentConceptPaymentSchedule?: ContractConceptPaymentSchedule[];
  onStageValueChange?: (stage: ContractConceptPaymentSchedule[]) => void;
}

export const ProtocolStageMultiSelector = (props: IProps) => {
  const [stagesSelected, setStagesSelected] = useState<
    ContractStageSelection[]
  >([]);

  const [overheadValue, setOverheadValue] = useState<number>(0);

  const [conceptPaymentSchedule, setConceptPaymentSchedule] = useState<ContractConceptPaymentSchedule[]>([]);

  //useTranslation()
  const [t] = useTranslation();
  
  useEffect(() => {
    setConceptPaymentSchedule(props.currentConceptPaymentSchedule || []);
    setStagesSelected(
      props.stages.map((stage: ContractStage) => {
        var isSelected = props.currentConceptPaymentSchedule?.find((stagePrice: ContractConceptPaymentSchedule) => stagePrice.contractStageId === stage.id) ? true : false;
        console.log(isSelected);
        return {
          ...stage,
          isSelected: isSelected
        } as ContractStageSelection;
      })
    );
  }, [props.stages, props.currentConceptPaymentSchedule?.length !== 0]);

  useEffect(() => {
    if (props.onStageValueChange) {
      props.onStageValueChange(conceptPaymentSchedule);
    }
  }, [conceptPaymentSchedule]);

  const onCheckboxChange = (isChecked: boolean, index: number) => {
    var CurrentStage = stagesSelected[index];
    CurrentStage = { ...CurrentStage, isSelected: isChecked };
    const newStagesSelected = [...stagesSelected.filter((stage) => stage.id !== CurrentStage.id), CurrentStage].sort((a, b) => a.stageOrder - b.stageOrder);
    setStagesSelected(newStagesSelected);

    // add stage to conceptPaymentSchedule
    var Stage = conceptPaymentSchedule.find((stagePrice: ContractConceptPaymentSchedule) => stagePrice.contractStageId === stagesSelected[index].id);

    if (Stage) {
      setConceptPaymentSchedule([...conceptPaymentSchedule.filter((item: ContractConceptPaymentSchedule) => item.contractStageId !== Stage?.contractStageId), { ...Stage, contractStageId: stagesSelected[index].id, isSelected: isChecked, isUnselected: !isChecked }]);
    } else {
      var newStagePrice: ContractConceptPaymentSchedule = {
        contractStageId: stagesSelected[index].id,
        price: 0,
        amountSpected: 0,
        screeningFaillurePrice: 0,
        isSelected: isChecked,
        isUnselected: !isChecked
      } as ContractConceptPaymentSchedule;

      setConceptPaymentSchedule([...conceptPaymentSchedule.filter((item: ContractConceptPaymentSchedule) => item.contractStageId !== newStagePrice.contractStageId), newStagePrice]);
    }

  };

  const setStageNormalValue = (value: number, index: number) => {

    var Stage = conceptPaymentSchedule.find((stagePrice: ContractConceptPaymentSchedule) => stagePrice.contractStageId === stagesSelected[index].id);

    if (Stage) {
      setConceptPaymentSchedule([...conceptPaymentSchedule.filter((item: ContractConceptPaymentSchedule) => item.contractStageId !== Stage?.contractStageId), { ...Stage, price: value }]);
    } else {
      var newStagePrice = {
        contractStageId: stagesSelected[index].id,
        price: value

      } as ContractConceptPaymentSchedule;

      setConceptPaymentSchedule([...conceptPaymentSchedule.filter((item: ContractConceptPaymentSchedule) => item.contractStageId !== newStagePrice.contractStageId), newStagePrice]);
    }
  }

  const setStageRemotelValue = (value: number, index: number) => {

    var Stage = conceptPaymentSchedule.find((stagePrice: ContractConceptPaymentSchedule) => stagePrice.contractStageId === stagesSelected[index].id);

    if (Stage) {
      setConceptPaymentSchedule([...conceptPaymentSchedule.filter((item: ContractConceptPaymentSchedule) => item.contractStageId !== Stage?.contractStageId), { ...Stage, remotePrice: value }]);
    } else {
      var newStagePrice = {
        contractStageId: stagesSelected[index].id,
        remotePrice: value

      } as ContractConceptPaymentSchedule;

      setConceptPaymentSchedule([...conceptPaymentSchedule.filter((item: ContractConceptPaymentSchedule) => item.contractStageId !== newStagePrice.contractStageId), newStagePrice]);
    }
  }

  const setStageAmountSpectedValue = (value: number, index: number) => {
    var Stage = conceptPaymentSchedule.find((stagePrice: ContractConceptPaymentSchedule) => stagePrice.contractStageId === stagesSelected[index].id);

    if (Stage) {
      setConceptPaymentSchedule([...conceptPaymentSchedule.filter((item: ContractConceptPaymentSchedule) => item.contractStageId !== Stage?.contractStageId), { ...Stage, amountSpected: value }]);
    } else {
      var newStagePrice = {
        contractStageId: stagesSelected[index].id,
        amountSpected: value

      } as ContractConceptPaymentSchedule;

      setConceptPaymentSchedule([...conceptPaymentSchedule.filter((item: ContractConceptPaymentSchedule) => item.contractStageId !== newStagePrice.contractStageId), newStagePrice]);
    }
  }

  const setStageFailSelectionValue = (value: number, index: number) => {
    var Stage = conceptPaymentSchedule.find((stagePrice: ContractConceptPaymentSchedule) => stagePrice.contractStageId === stagesSelected[index].id);

    if (Stage) {
      setConceptPaymentSchedule([...conceptPaymentSchedule.filter((item: ContractConceptPaymentSchedule) => item.contractStageId !== Stage?.contractStageId), { ...Stage, screeningFaillurePrice: value }]);
    } else {

      var newStagePrice = {
        contractStageId: stagesSelected[index].id,
        screeningFaillurePrice: value

      } as ContractConceptPaymentSchedule;

      setConceptPaymentSchedule([...conceptPaymentSchedule.filter((item: ContractConceptPaymentSchedule) => item.contractStageId !== newStagePrice.contractStageId), newStagePrice]);
    }
  }

  return (
    <>
      <ul>
        {stagesSelected.map((stage: ContractStageSelection, index: number) => (
          <li className={stage.isSelected ? 'StageSelectorItem Active' : 'StageSelectorItem'} key={index}>
            <div style={{ width: '15em', marginRight: '1em' }}>
              <Checkbox
                label={stage.stageName}
                checked={stage.isSelected}
                onChange={(
                  ev?:
                    | React.FormEvent<HTMLElement | HTMLInputElement>
                    | undefined,
                  checked?: boolean | undefined
                ) => onCheckboxChange(checked || false, index)}
              />
              {stage.isSelected && <>
                <Separator />
                <Text style={{ fontSize: FontSizes.small }}>{t('protocolStageMultiSelector.stagesSelected.typeVisit')}</Text>
                <Label>{stage.isOnSiteMandatory ? t('protocolStageMultiSelector.stagesSelected.inPersonVisit') : t('protocolStageMultiSelector.stagesSelected.remoteVisit')}</Label>
                <Text className='badge mini blue'>{ t('protocolStageMultiSelector.stagesSelected.visitOrder')+ stage.stageOrder}</Text>
              </>}

            </div>
            {stage.isSelected && (
              <TextField style={{ maxWidth: '4em' }} label={t('protocolStageMultiSelector.stagesSelected.quantity')} suffix='un' placeholder={t('protocolStageMultiSelector.stagesSelected.quantity')} defaultValue={(props.currentConceptPaymentSchedule?.find((stagePrice: ContractConceptPaymentSchedule) => stagePrice.contractStageId === stage.id)?.amountSpected || 1).toString()} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => newValue ? setStageAmountSpectedValue(Number(newValue), index) : undefined} />
            )}
            <div>
              {props.inputValue && stage.isSelected && (<>
                <TextField suffix={props.currency + '/un'} label={t('protocolStageMultiSelector.stagesSelected.inPersonPrice')} style={{ maxWidth: '10em' }} placeholder={t('protocolStageMultiSelector.stagesSelected.price')}  defaultValue={(props.currentConceptPaymentSchedule?.find((stagePrice: ContractConceptPaymentSchedule) => stagePrice.contractStageId === stage.id)?.price || 0).toString()} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => newValue ? setStageNormalValue(Number(newValue), index) : undefined} />
                {/* {props.overheadAverage ? (<>
                  <Label>
                    Valor ovehead: {((setStageNormalValue / (100 + props.overheadAverage)) * props.overheadAverage).toFixed(2) + ' ' + props.currency}
                  </Label>
                  <Label>
                    Valor sin ovehead: {(((props.currentConceptPaymentSchedule?.find((stagePrice: ContractConceptPaymentSchedule) => stagePrice.contractStageId === stage.id)?.price || 0) * 100) / (100 + props.overheadAverage)).toFixed(2) + ' ' + props.currency}
                  </Label>
                </>) : <></>} */}
              </>)}
              {props.inputValue && stage.isSelected && (
                <TextField suffix={props.currency + '/un'} label={t('protocolStageMultiSelector.stagesSelected.remotePrice')} style={{ maxWidth: '10em' }} placeholder={t('protocolStageMultiSelector.stagesSelected.remotePrice')} defaultValue={(props.currentConceptPaymentSchedule?.find((stagePrice: ContractConceptPaymentSchedule) => stagePrice.contractStageId === stage.id)?.remotePrice || 0).toString()} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => newValue ? setStageRemotelValue(Number(newValue), index) : undefined} />
              )}
            </div>
            {stage.failSelectionEnabled && stage.isSelected && props.inputValue  && (
              <TextField suffix={props.currency + '/un'} label={t('protocolStageMultiSelector.stagesSelected.selectionFailurePrice')} style={{ maxWidth: '10em' }} placeholder= {t('protocolStageMultiSelector.stagesSelected.selectionFailure')} defaultValue={(props.currentConceptPaymentSchedule?.find((stagePrice: ContractConceptPaymentSchedule) => stagePrice.contractStageId === stage.id)?.screeningFaillurePrice || 0).toString()} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => newValue ? setStageFailSelectionValue(Number(newValue), index) : undefined} />
            )}

          </li>
        ))}
      </ul>
      {stagesSelected.length === 0 && (
        <MessageComponent
          message={t('protocolStageMultiSelector.stagesSelected.informedProtocolVisits')} 
          type={MessageBarType.info}
        />
      )}
    </>
  );
};
