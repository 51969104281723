import { PageHeader } from "../../../commons/component/PageHeader/PageHeader";
import ContractHeaderComponent from "../../../components/Contracts/Header/ContractHeader.Component";
import { Label, Pivot, PivotItem, Separator } from "@fluentui/react";
import StageListComponent from "../../../components/Contracts/Stages/StageList/StageList.Component";
import CycleListComponent from "../../../components/Contracts/Cycles/CycleList/CycleList.Component";
import ConceptListComponent from "../../../components/Contracts/Concept/ConceptList/ConceptList.Component";
import BranchListComponent from "../../../components/Contracts/Branchs/BranchList/BranchList.Component";
import SettlementListComponent from "../../../components/Settlements/SettlementList/SettlementList.Component";
import ContractAssociationsComponent from "../../../components/Contracts/ContractAssociations/ContractAssociations.Component";
import { connect } from "react-redux";
import { withRouter } from "../../../commons/component/withRouterCustom";
import ProtocolParactitionersListComponent from "../../../components/Protocols/Practitioners/PractitionerList/ProtocolPractitiones.List.Component";
import { ProtocolConceptMultiSelectorComponent } from "../../../components/Contracts/Protocols/ProtocolContractForm/ProtocolContractConcept/ProtocolConceptMultiSelector/ProtocolConceptMultiselectorComponent";
import FeeDivisionContractComponent from "../../../components/Contracts/FeeDivision/FeeDivisionContract.Component";
import { ContractConcept } from "../../../models/Entities/Contracts/ContractConcept";
import { NewContractCommissionConcept } from "../../../models/Entities/Contracts/NewContractCommissionConcetp";
import { useEffect } from "react";
import AddendumsComponent from "../../../components/Contracts/Addendums/Addendums.Components";

// styles
import './ContractDetail.Styles.css';

// functions
import * as ComponentFunctions from './ContractDetails.Functions';


////i18Next
import { useTranslation, Trans } from 'react-i18next';

// components
import CommissionAgentAssociatedComponent from "../../../components/Contracts/CommissionAgentAssociated/CommissionAgentAssociated.Component";

const ContractDetailPage = (props: ComponentFunctions.Props) => {

    //useTranslation()
    const [t] = useTranslation();

    useEffect(() => {
        props.GetAllContractsConcept(props.ContractId);
        props.GetAllContractCommissionConcepts(props.ContractId);
        props.GetAllContractsStage(props.ContractId);
    }, [props.Contract !== undefined]);

    //events
    const AddConcept = (concept: ContractConcept) => {

        // add new contract commission concept 
        const newContractCommissionConcept: NewContractCommissionConcept = {
            contractId: props.ContractId,
            conceptId: concept.id,
            commissionPercentage: 0,
            costParticipation: false,
            costParticipationPercentage: 0
        }

        props.CreateContractCommissionConcept(props.ContractId, newContractCommissionConcept);
    }

    const DeleteConcept = (concept: ContractConcept) => {
        const contractCommissionConcept = props.ContractCommissionConcepts.find((contractCommissionConcept) => contractCommissionConcept.conceptId === concept.id);
        contractCommissionConcept && props.DeleteContractCommissionConcept(props.ContractId, contractCommissionConcept.id);
    }

    const ChangeParticipationPercentage = (percentage: number, conceptId: number) => {
        const contractCommissionConcept = props.ContractCommissionConcepts.find((contractCommissionConcept) => contractCommissionConcept.conceptId === conceptId);
        contractCommissionConcept && props.UpdateContractCommissionConcept(props.ContractId, {
            ...contractCommissionConcept,
            commissionPercentage: percentage
        });
    }

    const ChangeCostParticipationActive = (isActive: boolean, conceptId: number) => {
        const contractCommissionConcept = props.ContractCommissionConcepts.find((contractCommissionConcept) => contractCommissionConcept.conceptId === conceptId);
        contractCommissionConcept && props.UpdateContractCommissionConcept(props.ContractId, {
            ...contractCommissionConcept,
            costParticipation: isActive
        });
    }

    const ChangeCostParticipationPercentage = (percentage: number, conceptId: number) => {
        const contractCommissionConcept = props.ContractCommissionConcepts.find((contractCommissionConcept) => contractCommissionConcept.conceptId === conceptId);
        contractCommissionConcept && props.UpdateContractCommissionConcept(props.ContractId, {
            ...contractCommissionConcept,
            costParticipationPercentage: percentage
        });
    }

    return (
        <>
            <PageHeader title={t('contractDetailPage.contractDetailtitle')} />
            <ContractHeaderComponent ContractId={props.ContractId} />
            <div className="Section">
                <AddendumsComponent ContractId={props.ContractId} />
            </div>
            <div className="ContractPageContent">
                <div className="Section">
                    <Pivot>
                        {(!props.Practitioner && props.Contract?.commissionAgentsAssociated?.length === 0) && <PivotItem headerText={t('contractDetailPage.contractDetailVisits')}>
                            <div className="VisitasSection">
                                <div>
                                    <StageListComponent ContractId={props.ContractId} />
                                </div>
                                <div>
                                    <Label>{t('contractDetailPage.contractDetailCycles')}</Label>
                                    <CycleListComponent ContractId={props.ContractId} />
                                    <Separator />
                                    <Label>{t('contractDetailPage.contractDetailBranchs')}</Label>
                                    <BranchListComponent ContractId={props.ContractId} />
                                </div>
                            </div>
                        </PivotItem>}
                        <PivotItem headerText={t('contractDetailPage.contractDetailItem.conceptsSettled')}>
                            <ConceptListComponent
                                ContractId={props.ContractId}
                            />
                        </PivotItem>
                        <PivotItem headerText={t('contractDetailPage.contractDetailItem.liquidations')}>
                            <SettlementListComponent ContractId={props.ContractId} />
                        </PivotItem>
                        {props.Protocol && props.Contract && <PivotItem headerText={t('contractDetailPage.contractDetailItem.medicalEquipment')}>
                            <>
                                <h5>{t('contractDetailPage.contractDetailFeeDivision')}</h5>
                                <FeeDivisionContractComponent ContractId={props.Contract.id} />
                                <Separator />
                                <ProtocolParactitionersListComponent showParticipation={(props.feeDivision || 0) === 0} ContractId={props.ContractId} ProtocolId={props.Protocol?.id} />
                                {/* <Pivot>
                                    <PivotItem headerText={t('contractDetailPage.contractDetailItem.financialParticipation')}>
                                        <ProtocolConceptMultiSelectorComponent
                                            concepts={props.ContractConcepts}
                                            selectedConceptIds={props.ContractCommissionConcepts?.map((contractCommissionConcept) => contractCommissionConcept.conceptId) || []}
                                            showPercentage={true}
                                            onAddConcept={AddConcept}
                                            onDeleteConcept={DeleteConcept}
                                        //onChangeParticipationPercentage={ChangeParticipationPercentage}
                                        //onCostParticipationActiveChange={ChangeCostParticipationActive}
                                        //onCostParticipationPercentageChange={ChangeCostParticipationPercentage}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText={t('contractDetailPage.contractDetailItem.teamMembers')}>
                                       
                                    </PivotItem>
                                </Pivot> */}
                            </>
                        </PivotItem>}
                    </Pivot>

                </div>
                <div className="Section">
                    {props.Contract?.commissionAgentsAssociated?.length === 0 &&
                        <ContractAssociationsComponent ContractId={props.ContractId} />
                    }

                    <CommissionAgentAssociatedComponent ContractId={props.ContractId} />
                </div>
            </div>

        </>);
}

export default withRouter(connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ContractDetailPage as any));