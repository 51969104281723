import { AppState } from "../../../redux/reducers";

// store
import * as PractitionerProtocolVisitsStore from '../../../redux/middleware/PractitionerProtocolVisitsMiddleware';
import * as ProtocolStore from '../../../redux/middleware/MedicalProtocolMiddleware';
import * as PractitionerStore from '../../../redux/middleware/PractitionerMiddleware';

// models
import { PatientVisit } from "../../../models/Entities/Protocols/PatientVisist";
import { ErrorType } from "../../../models/HttpError";
import { Protocol } from "../../../models/Entities/Protocols/Protocol";
import { Practitioner, PractitionerVisit } from "../../../models/Entities/Practitioners/Practitioner";
import moment from "moment";

interface IProps {
    PractitionerId: string;
    protocolsIds: number[];
    Months: number;
}
  
interface ConnectedProps {
//status
IsLoading: boolean;
LoadedSuccessfully: boolean;
FailOnLoad: boolean;
ErrorOnLoad: ErrorType;

visitis: PractitionerVisit[];
Protocols: Protocol[];
Practitioner: Practitioner;
}

interface DispatchProps {
    GetPractitionerProtocolVisits: typeof PractitionerProtocolVisitsStore.actionCreators.GetPractitionerProtocolVisits;
    GetProtocolsByPractitionerId: typeof ProtocolStore.actionCreators.GetProtocolsByPractitionerId;
    GetPractitionerById: typeof PractitionerStore.actionCreators.GetPractitionerById;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    // status
    IsLoading: state.PractitionerSettings?.isLoading,
    LoadedSuccessfully: state.PractitionerSettings?.loadedSuccessfully,
    FailOnLoad: state.PractitionerSettings?.failOnLoad,
    ErrorOnLoad: state.PractitionerSettings?.error,

    Protocols: state.Practitioner?.list.find((practitioner: Practitioner) => practitioner.userId === ownProps.PractitionerId)?.Protocols || [],
    visitis: state.Practitioner?.list.find((practitioner: Practitioner) => practitioner.userId === ownProps.PractitionerId)?.ProtocolVisits || [],
    Practitioner: state.Practitioner?.list.find((practitioner: Practitioner) => practitioner.userId === ownProps.PractitionerId)
});

export const mapDispatchToProps = {
...PractitionerProtocolVisitsStore.actionCreators,
...ProtocolStore.actionCreators,
...PractitionerStore.actionCreators
};

// functions 

// get name of month of a date 
export const getMonthName = (mont: number) => {
    return new Date(0, mont).toLocaleString('default', { month: 'long' });
};

// get week days name
export const getWeekDays = () => {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
};

function getDatesBetween(startDate: Date, endDate: Date): Date[] {
    let dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
}

export function getWeeksOfMonth(month: number, year: number): Date[][] {
    let weeks: Date[][] = [];
    let firstDayOfMonth = new Date(year, month, 1);
    let lastDayOfMonth = new Date(year, month + 1, 0);

    // Encuentra el primer domingo antes o igual al primer día del mes
    let firstSunday = new Date(firstDayOfMonth);
    firstSunday.setDate(firstSunday.getDate() - firstSunday.getDay());

    // Encuentra el último sábado después del último día del mes
    let lastSaturday = new Date(lastDayOfMonth);
    lastSaturday.setDate(lastSaturday.getDate() + (6 - lastSaturday.getDay()));

    // Obtener todos los días entre el primer domingo y el último sábado
    let allDays = getDatesBetween(firstSunday, lastSaturday);

    // Dividir los días en semanas
    for (let i = 0; i < allDays.length; i += 7) {
        weeks.push(allDays.slice(i, i + 7));
    }

    return weeks;
}

// // get week of month having in count days of the prevoius month at the beginning and the next month at the end
// export const getWeekOfMonth = (month: number, year: number) => {
//     let weeks = [];
//     let firstDayofTheWeek = new Date(year, month, 1).getDay();
//     let firstDay = new Date(year, month, 1 - firstDayofTheWeek);
//     let lastDay = new Date(year, month + 1, 0);
//     let days = getDatesBetween(firstDay, lastDay);
//     let week: any = [];
//     let count = 0;
//     let day = firstDay;
//     while (day.getDay() > 0) {
//         day.setDate(day.getDate() - 1);
//         week.push(new Date(day));
//         count++;
//     }
//     week.reverse();
//     days.forEach((date: Date) => {
//         week.push(date);
//         count++;
//         if (count % 7 === 0) {
//             weeks.push(week);
//             week = [];
//         }
//     });
//     while (week.length < 7) {
//         day.setDate(day.getDate() + 1);
//         week.push(new Date(day));
//     }
//     weeks.push(week);
//     return weeks;
// };

// get last tree months number starting finish at the current month
export const getLastXMonths = (x: number) => {
    let months = [];
    for (let i = 0; i < x; i++) {
        let date = new Date();
        date.setMonth(date.getMonth() - i);
        months.push({ month: date.getMonth(), year: date.getFullYear() });
    }
    return months.reverse();
}

// in base an a date return true or false if a visit occurs in that date using dates without time
export const visitOccurs = (date: Date, visits: Date[]): boolean => {

    const referenceDay = new Date(date).getDate();
    const referenceMonth = new Date(date).getMonth();
    const referenceYear = new Date(date).getFullYear();

    const ReferenceDate = new Date(date).setHours(0, 0, 0, 0);

    //console.log('ReferenceDate', moment(ReferenceDate)?.format('DD/MM/YYYY'));

    let isTrue = false;

    const visitDate = visits.find((visit: Date) => {
        
        let exist: boolean = false;

        if(exist) {
            return true;
        }

        const currentDateDay = new Date(visit).getDate();
        const currentDateMonth = new Date(visit).getMonth();
        const currentDateYear = new Date(visit).getFullYear();

        //console.log('Visit', moment(visit)?.format('DD/MM/YYYY'));

        if(currentDateDay === referenceDay && currentDateMonth === referenceMonth && currentDateYear === referenceYear) {
            //console.log('currentday' + currentDateDay + '|' + referenceDay + '|' + 'currentMonth' + currentDateMonth + '|' + referenceMonth + '|' + 'currentYear' + currentDateYear + '|' + referenceYear );
            //console.log(true + '|' + moment(ReferenceDate)?.format('DD/MM/YYYY') + '|' + moment(visit)?.format('DD/MM/YYYY'));
            exist = true;
            return true;
        } else {
            console.log(false);
            return false;
        }
        
    });

    isTrue = visitDate !== undefined;
     

    return isTrue;

};