import { ContractBranch } from '../models/Entities/Contracts/ContractBranch';
import { IResult } from '../models/HttpResult';
import { NewContractBranch } from '../models/Entities/Contracts/NewContractBranch';

//Functions
import * as Functions from '../functions/common';
import { useSelector } from 'react-redux';


const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'contracts/';

// Get All Concepts of contract
export const GetContractsBranchs = async (ContractId: number, token: string): Promise<ContractBranch[]> => {

    let RequestURL: string = BaseURL + ContractId + '/branches/';

    try {
        const Response: ContractBranch[] = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<ContractBranch[]>>;
            }
        })
        .then((data: IResult<ContractBranch[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// Get contract branch By Id

export const GetContractBranchById = async (id: number, token: string): Promise<ContractBranch> => {

    let RequestURL: string = BaseURL + 'branches/' + id;
    
    try {
        const Response: ContractBranch = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<ContractBranch>>;
            }
        })
        .then((data: IResult<ContractBranch>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// Create new contract branch
export const CreateContractBranch = async (contractBranch: NewContractBranch, token: string): Promise<ContractBranch> => {

    let RequestURL: string = BaseURL + 'branches/';

    try {
        const Response: ContractBranch = await fetch(RequestURL, {
        method: 'POST',
        body: JSON.stringify(contractBranch),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<ContractBranch>>;
            }
        })
        .then((data: IResult<ContractBranch>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// Update contract branch
export const UpdateContractBranch = async (contractBranch: ContractBranch, token: string): Promise<ContractBranch> => {

    let RequestURL: string = BaseURL + 'branches/';

    try {
        const Response: ContractBranch = await fetch(RequestURL, {
        method: 'PUT',
        body: JSON.stringify(contractBranch),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<ContractBranch>>;
            }
        })
        .then((data: IResult<ContractBranch>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// Delete contract branch
export const DeleteContractBranch = async (id: number, token: string): Promise<number> => {

    let RequestURL: string = BaseURL + 'branches/' + id;

    try {
        const Response: number = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return id;
            }
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};