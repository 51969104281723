import { ErrorType } from "../../../models/HttpError";
import { Settlement } from "../../../models/Entities/Settlements/Settlement";
import { SettlementDetail } from "../../../models/Entities/SettlementDetails/SettlementDetail";

// stores
import * as SettlementStore from '../../../redux/middleware/SettlementMiddleware';
import * as SettlementDetailStore from '../../../redux/middleware/SettlementDetailsMiddleware';

//State
import { AppState } from "../../../redux/reducers";

export const enum CharType {
    Line = 1,
    Donut = 2,
}

interface IProps {
    SettlementId: number;
    SettlementSummaryItemId?: number | undefined;
    CurrencyCode: string;
    ChartType: CharType;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    error: ErrorType | undefined;
    Settlement: Settlement | undefined;
    SettlementDetails: SettlementDetail[] | undefined;
    currencies: string[] | undefined;
    TotalToSettle: number | undefined;
    TotalWithholdings: number | undefined;
    TotalOvehead: number | undefined;
    TotalCost: number | undefined;
  }
  
  interface DispatchProps {
    GetSettlementById: typeof SettlementStore.actionCreators.GetSettlementById;
    GetAllSettlementDetails: typeof SettlementDetailStore.actionCreators.GetAllSettlementDetails;
    ChangeExcludeStatus: typeof SettlementDetailStore.actionCreators.ChangeExcludeStatus;
    MarkOk: typeof SettlementDetailStore.actionCreators.MarkOk;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.SettlementDetails?.isLoadingAll,
    isUpdating: state.SettlementDetails?.isLoadingAll,
    isUpdatedSuccessfully: state.SettlementDetails?.successLoadingAll,
    isLoadingSuccessfully: state.SettlementDetails?.successLoadingAll,
    failOnUpdating: state.SettlementDetails?.failOnLoadingAll,
    failOnLoading: state.SettlementDetails?.failOnLoadingAll,
    isDeleting: state.SettlementDetails?.isDeletingOne,
    isDeletedSuccessfully: state.SettlementDetails?.successDeletingOne,
    failOnDeleting: state.SettlementDetails?.failOnDeletingOne,
    error: state.SettlementDetails?.error,
    Settlement: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId ) : undefined,
    SettlementDetails: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details : [],
    currencies: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.map((detail: SettlementDetail) => detail.currency).filter(uniqueFilter) : [],
    TotalToSettle: ownProps.SettlementSummaryItemId ? ownProps.SettlementId && 
        state.Settlement && 
            state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((item: SettlementDetail )=> item.settlementResumeId !== undefined 
                && item.settlementResumeId === ownProps.SettlementSummaryItemId &&  item.currency === ownProps.CurrencyCode && item.isExcluded === false).reduce((sum, current) => sum + current.value, 0) : 0 :
                ownProps.SettlementId && 
        state.Settlement && 
            state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((item: SettlementDetail )=> item.currency === ownProps.CurrencyCode && item.isExcluded === false).reduce((sum, current) => sum + current.value, 0) : 0,
    TotalWithholdings: ownProps.SettlementSummaryItemId ? ownProps.SettlementId &&
        state.Settlement && 
            state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((item: SettlementDetail )=> item.settlementResumeId !== undefined 
                && item.settlementResumeId === ownProps.SettlementSummaryItemId &&  item.currency === ownProps.CurrencyCode && item.isExcluded === false).reduce((sum, current) => sum + current.withHoldAmount, 0) : 0 :
                ownProps.SettlementId &&
        state.Settlement && 
            state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((item: SettlementDetail )=> item.currency === ownProps.CurrencyCode && item.isExcluded === false).reduce((sum, current) => sum + current.withHoldAmount, 0) : 0,
    TotalOvehead: ownProps.SettlementSummaryItemId ? ownProps.SettlementId &&
        state.Settlement && 
            state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((item: SettlementDetail )=> item.settlementResumeId !== undefined 
                && item.settlementResumeId === ownProps.SettlementSummaryItemId &&  item.currency === ownProps.CurrencyCode && item.isExcluded === false).reduce((sum, current) => sum + current.overheadValue, 0) : 0 : 
                ownProps.SettlementId &&
        state.Settlement && 
            state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((item: SettlementDetail )=> item.currency === ownProps.CurrencyCode && item.isExcluded === false).reduce((sum, current) => sum + current.overheadValue, 0) : 0,
    TotalCost: ownProps.SettlementSummaryItemId ? ownProps.SettlementId &&
        state.Settlement && 
            state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((item: SettlementDetail )=> item.settlementResumeId !== undefined 
                && item.settlementResumeId === ownProps.SettlementSummaryItemId &&  item.currency === ownProps.CurrencyCode && item.isExcluded === false).reduce((sum, current) => sum + current.costValue, 0) : 0 :
                ownProps.SettlementId &&
        state.Settlement && 
            state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((item: SettlementDetail )=> item.currency === ownProps.CurrencyCode && item.isExcluded === false).reduce((sum, current) => sum + current.costValue, 0) : 0   
  });
  
  export const mapDispatchToProps = {
    ...SettlementStore.actionCreators,
    ...SettlementDetailStore.actionCreators
  };

  function uniqueFilter(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  };

  export const GetPatientStageTotal = (props: Props, patient: string, stage: string, currencyCode: string):number | undefined => {
     var sum = props.SettlementDetails?.filter((item: SettlementDetail )=> item.patientNumberInProtocol === patient && item.contractStageName === stage && item.currency === currencyCode && item.isExcluded === false)
          .reduce((sum, current) => sum + current.value, 0);
      return sum;
  };

  export const GetPatientStageCurrencies = (props: Props, patient: string, stage: string):string[] | undefined => {
    var currencies = props.SettlementDetails?.filter((item: SettlementDetail )=> item.patientNumberInProtocol === patient && item.contractStageName === stage)
         .map((item: SettlementDetail) => item.currency).filter(uniqueFilter);
     return currencies;
  };