// services
import { Contract } from '../../models/Entities/Contracts/Contract';
import { ContractConcept } from '../../models/Entities/Contracts/ContractConcept';
import { ContractConceptConcept } from '../../models/Entities/Contracts/ContractConceptConcept';
import { NewContractConceptConcept } from '../../models/Entities/Contracts/NewContractConceptConcept';
import { ErrorType } from '../../models/HttpError';
import * as Services from '../../services/ContractConceptConcept.Services';

// actions
import * as Actions from '../actions';
import { AppThunkAction } from '../reducers';

type KnownAction =
    | Actions.ContractConceptConcept.ContractConceptConcept_GetAllByContractConceptId_Fail_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_GetAllByContractConceptId_Receive_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_GetAllByContractConceptId_Request_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_UpdateAllByContractConceptId_Fail_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_UpdateAllByContractConceptId_Receive_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_UpdateAllByContractConceptId_Request_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_Add_Request_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_Add_Receive_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_Add_Fail_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_Update_Request_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_Update_Receive_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_Update_Fail_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_Delete_Request_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_Delete_Receive_Action
    | Actions.ContractConceptConcept.ContractConceptConcept_Delete_Fail_Action;

export const actionCreators = {
    GetAllContractConceptConcepts:
        (contractId: number ,contractConceptId: number): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {
                var state = getState();
                var token: string;

                if (state.oidc?.user?.access_token === undefined) {
                    dispatch({
                        type: 'CONTRACTCONCEPTCONCEPT_GET_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION',
                        error: {
                            ErrorCode: 401,
                            ErrorMessage: 'Not authorized',
                            Errors: []
                        }
                    });
                    return;
                } else {
                    token = state.oidc.user.access_token;
                };

                if (!state.ContractConceptConcept?.successLoadingAll ||
                    state.ContractConceptConcept?.list?.find((contractConceptConcept: ContractConceptConcept) => contractConceptConcept.contractConceptId === contractConceptId) === undefined) {
                    dispatch({
                        type: 'CONTRACTCONCEPTCONCEPT_GET_ALL_BY_CONTRACTCONCEPTID_REQUEST_ACTION',
                        contractConceptId: contractConceptId
                    });

                    Services.GetContractsConceptConcepts(contractConceptId, token)
                        .then((contractConceptConcepts: ContractConceptConcept[]) => {
                            dispatch({
                                type: 'CONTRACTCONCEPTCONCEPT_GET_ALL_BY_CONTRACTCONCEPTID_RECEIVE_ACTION',
                                contractConceptConcepts: contractConceptConcepts,
                                contractConceptId: contractConceptId,
                                contractId: contractId
                            });
                        })
                        .catch((error: ErrorType) =>
                            dispatch({
                                type: 'CONTRACTCONCEPTCONCEPT_GET_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION',
                                error: error
                            })
                        );
                } else {
                    dispatch({
                        type: 'CONTRACTCONCEPTCONCEPT_GET_ALL_BY_CONTRACTCONCEPTID_RECEIVE_ACTION',
                        contractConceptConcepts: state.ContractConceptConcept.list.filter((contractConceptConcept: ContractConceptConcept) => contractConceptConcept.contractConceptId === contractConceptId),
                        contractConceptId: contractConceptId,
                        contractId: contractId
                    });
                }
            },
    // UpdateAllContractConceptConcepts:
    //     (contractConceptId: number): AppThunkAction<KnownAction> =>
    //         async (dispatch, getState) => {
    //             var state = getState();

    //             dispatch({
    //                 type: 'CONTRACTCONCEPTCONCEPT_UPDATE_ALL_BY_CONTRACTCONCEPTID_REQUEST_ACTION',
    //                 contractConceptId: contractConceptId
    //             });

    //             Services.UpdateContractsConceptConcepts(contractConceptId)
    //                 .then((contractConceptConcepts: ContractConceptConcept[]) => {
    //                     dispatch({
    //                         type: 'CONTRACTCONCEPTCONCEPT_UPDATE_ALL_BY_CONTRACTCONCEPTID_RECEIVE_ACTION',
    //                         contractConceptConcepts: contractConceptConcepts,
    //                         contractConceptId: contractConceptId
    //                     });
    //                 })
    //                 .catch((error: ErrorType) =>
    //                     dispatch({
    //                         type: 'CONTRACTCONCEPTCONCEPT_UPDATE_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION',
    //                         error: error
    //                     })
    //                 );
    //         },
    // GetContractConceptConceptById:
    //     (id: number): AppThunkAction<KnownAction> =>
    //     async (dispatch, getState) => {
    //         var state = getState();

    //             dispatch({
    //                 type: 'CONTRACTCONCEPTCONCEPT_GET_BY_ID_REQUEST_ACTION',
    //                 contractConceptConceptId: id
    //             });

    //             Services.GetContractConceptConceptById(id)
    //                 .then((contractConceptConcept: ContractConceptConcept) => {
    //                     dispatch({
    //                         type: 'CONTRACTCONCEPTCONCEPT_GET_BY_ID_RECEIVE_ACTION',
    //                         contractConceptConcept: contractConceptConcept
    //                     });
    //                 })
    //                 .catch((error: ErrorType) =>
    //                     dispatch({
    //                         type: 'CONTRACTCONCEPTCONCEPT_GET_BY_ID_FAIL_ACTION',
    //                         error: error
    //                     })
    //                 );
    //     },
    AddRangeContractConceptConcepts:
        (contractId: number, contractConceptId: number, newContractConceptConcepts: NewContractConceptConcept[]): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {
                
                var state = getState();
                var token: string;

                if (state.oidc?.user?.access_token === undefined) {
                    dispatch({
                        type: 'CONTRACTCONCEPTCONCEPT_GET_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION',
                        error: {
                            ErrorCode: 401,
                            ErrorMessage: 'Not authorized',
                            Errors: []
                        }
                    });
                    return;
                } else {
                    token = state.oidc.user.access_token;
                };

                // Delete all contractConceptConcepts
                var CurrentConcepts = state.Contract?.list.find((contract: Contract) => contract.id === contractId)?.concepts.find((contractConcept: ContractConcept) => contractConcept.id === contractConceptId)?.concepts;

                if (CurrentConcepts !== undefined) {
                    CurrentConcepts.forEach((ItemConcept: ContractConceptConcept | NewContractConceptConcept) => {
                        if((ItemConcept as ContractConceptConcept).id !== undefined)
                        {
                            var currentConcept = (ItemConcept as ContractConceptConcept);
                            Services.DeleteContractConceptConcept(currentConcept.id, token);
                            dispatch({
                                type: 'CONTRACTCONCEPTCONCEPT_DELETE_RECEIVE_ACTION',
                                contractConceptConceptId: currentConcept.id,
                                contractConceptId: contractConceptId,
                                contractId: contractId
                            });
                        }
                        
                    });
                }

                // Add contractConceptConcepts
                newContractConceptConcepts.forEach((ItemConcept: NewContractConceptConcept) => {

                dispatch({
                    type: 'CONTRACTCONCEPTCONCEPT_ADD_REQUEST_ACTION',
                    newContractConceptConcept: ItemConcept
                });

                Services.CreateContractConceptConcept(ItemConcept, token)
                    .then((contractConceptConcept: ContractConceptConcept) => {
                        dispatch({
                            type: 'CONTRACTCONCEPTCONCEPT_ADD_RECEIVE_ACTION',
                            contractConceptConcept: contractConceptConcept,
                            contractId: contractId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTCONCEPTCONCEPT_ADD_FAIL_ACTION',
                            error: error
                        })
                    );
                });
            },
    AddContractConceptConcept:
        (contractId: number, newContractConceptConcept: NewContractConceptConcept): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {
                
                var state = getState();
                var token: string;

                if (state.oidc?.user?.access_token === undefined) {
                    dispatch({
                        type: 'CONTRACTCONCEPTCONCEPT_GET_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION',
                        error: {
                            ErrorCode: 401,
                            ErrorMessage: 'Not authorized',
                            Errors: []
                        }
                    });
                    return;
                } else {
                    token = state.oidc.user.access_token;
                };

                dispatch({
                    type: 'CONTRACTCONCEPTCONCEPT_ADD_REQUEST_ACTION',
                    newContractConceptConcept: newContractConceptConcept
                });

                Services.CreateContractConceptConcept(newContractConceptConcept, token)
                    .then((contractConceptConcept: ContractConceptConcept) => {
                        dispatch({
                            type: 'CONTRACTCONCEPTCONCEPT_ADD_RECEIVE_ACTION',
                            contractConceptConcept: contractConceptConcept,
                            contractId: contractId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTCONCEPTCONCEPT_ADD_FAIL_ACTION',
                            error: error
                        })
                    );
            },
    UpdateContractConceptConcept:
        (contractId: number, contractConceptConcept: ContractConceptConcept): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {
                
                var state = getState();
                var token: string;

                if (state.oidc?.user?.access_token === undefined) {
                    dispatch({
                        type: 'CONTRACTCONCEPTCONCEPT_GET_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION',
                        error: {
                            ErrorCode: 401,
                            ErrorMessage: 'Not authorized',
                            Errors: []
                        }
                    });
                    return;
                } else {
                    token = state.oidc.user.access_token;
                };

                dispatch({
                    type: 'CONTRACTCONCEPTCONCEPT_UPDATE_REQUEST_ACTION',
                    contractConceptConcept: contractConceptConcept
                });

                Services.UpdateContractConceptConcept(contractConceptConcept, token)
                    .then((contractConceptConcept: ContractConceptConcept) => {
                        dispatch({
                            type: 'CONTRACTCONCEPTCONCEPT_UPDATE_RECEIVE_ACTION',
                            contractConceptConcept: contractConceptConcept,
                            contractId: contractId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTCONCEPTCONCEPT_UPDATE_FAIL_ACTION',
                            error: error
                        })
                    );
            },
    DeleteContractConceptConcept:
        (contractId: number,  contractConceptConceptId: number, contractConceptId: number): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {
                
                var state = getState();
                var token: string;

                if (state.oidc?.user?.access_token === undefined) {
                    dispatch({
                        type: 'CONTRACTCONCEPTCONCEPT_GET_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION',
                        error: {
                            ErrorCode: 401,
                            ErrorMessage: 'Not authorized',
                            Errors: []
                        }
                    });
                    return;
                } else {
                    token = state.oidc.user.access_token;
                };

                dispatch({
                    type: 'CONTRACTCONCEPTCONCEPT_DELETE_REQUEST_ACTION',
                    contractConceptConceptId: contractConceptConceptId
                });

                Services.DeleteContractConceptConcept(contractConceptConceptId, token)
                    .then((contractConceptConcept: ContractConceptConcept) => {
                        dispatch({
                            type: 'CONTRACTCONCEPTCONCEPT_DELETE_RECEIVE_ACTION',
                            contractConceptConceptId: contractConceptConceptId,
                            contractConceptId: contractConceptId,
                            contractId: contractId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTCONCEPTCONCEPT_DELETE_FAIL_ACTION',
                            error: error
                        })
                    );
            }
}
