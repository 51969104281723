import React from 'react';
import {
  DatePicker,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  FontSizes,
  IDropdownOption,
  IconButton,
  Label,
  MaskedTextField,
  MessageBarType,
  Modal,
  Panel,
  PanelType,
  PrimaryButton,
  Separator,
  TextField,
  Toggle
} from '@fluentui/react';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { DateTimeInput } from '../../../../commons/component/Inputs/DateTimeInput/DateTimeInput';
import { MessageComponent } from '../../../../commons/component/MessageComponent';
import { Contract } from '../../../../models/Entities/Contracts/Contract';

// functions
import * as Functions from './ProtocolContractFormFunctions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// styles
import './ProtocolContractFormComponent.css';
import { NewContractStage } from '../../../../models/Entities/Contracts/NewContractStage';
import { CurrencySelectorComponent } from '../../../../commons/component/Inputs/CurrencySelector/CurrencySelectorComponent';
import { ContractConcept } from '../../../../models/Entities/Contracts/ContractConcept';
import { SponsorSelectorComponent } from '../../../../commons/component/Inputs/SponsorSelector/SponsorSelectorComponet';
import { ContractStage } from '../../../../models/Entities/Contracts/ContractStage';
import PractitionerSelectorComponent from '../../../../commons/component/Inputs/PractitionerSelector/PractitionerSelectorComponent';
import { NewContractConcept } from '../../../../models/Entities/Contracts/NewContractConcept';
import { NewContract } from '../../../../models/Entities/Contracts/NewContract';
import { connect } from 'react-redux';

interface FormValidation {
  success: boolean;
  errors: string[];
}

export const ProtocolContractFormComponent = (props: Functions.Props) => {
  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
  >();

  const [contractNumber, setContractNumber] = useState<string>('');
  const [contractDescription, setContractDescription] = useState<string>('');
  const [contractStatus, setContractStatus] = useState<number>(0);
  const [contractStartDate, setContractStartDate] = useState<Date>();
  const [contractEndDate, setContractEndDate] = useState<Date>();
  const [contractCurrency, setContractCurrency] = useState<string>('');
  const [paymentScheduleType, setPaymentScheduleType] = useState<number>(0);
  const [paymentScheduleFrequency, setPaymentScheduleFrecuency] =
    useState<number>(0);
  const [firstSettlement, setFirstSettlement] = useState<Date>(new Date());
  const [ParentContractId, setParentContractId] = useState<number | undefined>();
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [useContractStages, setUseContractStages] = useState<boolean>(false);

  const [concepts, setConcepts] = useState<ContractConcept[]>([]);
  const [Stages, setStages] = useState<NewContractStage[] | ContractStage[]>(
    []
  );
  const [Participation, setParticipation] = useState<number>(0);

  //useTranslation()
  const [t] = useTranslation();

  // effects

  useEffect(() => {
    if(props.Contract) {
      setContractNumber(props.Contract.contractNumber);
      setContractDescription(props.Contract.contractDescription);
      setContractStatus(props.Contract.contractStatus);
      setContractStartDate(props.Contract.contractStartDate);
      setContractEndDate(props.Contract.contractEndDate);
      setContractCurrency(props.Contract.contractCurrency);
      setContractStatus(props.Contract.contractStatus);
      setPaymentScheduleType(props.Contract.paymentSheduleType);
      setParentContractId(props.Contract.parentContractId);
      setIsLocked(props.Contract.isLocked);
    }}, [props.Contract]);


  useEffect(() => {
    if (props.isAddedSuccessfully || props.isUpdatedSuccessfully) {
      ResetForm();
      props.dismissPanel();
    }
  } , [submittedForm, props.isAddedSuccessfully, props.isUpdatedSuccessfully]);

  // reset form
  const ResetForm = () => {
    setSubmittedForm(false);
    setContractNumber('');
    setContractDescription('');
    setContractStatus(0);
    setContractStartDate(undefined);
    setContractEndDate(undefined);
    setContractCurrency('');
    setPaymentScheduleType(0);
    setPaymentScheduleFrecuency(0);
    setFirstSettlement(new Date());
    setUseContractStages(false);
    setParentContractId(undefined);
    setIsLocked(false);
    setConcepts([]);
    setStages([]);
    setParticipation(0);
  };

  // form events
  const handleContractNumberChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    setContractNumber(newValue || '');
  };
  const handleContractDescriptionChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    setContractDescription(newValue || '');
  };
  const handleContractStatusChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => {
    setContractStatus(Number(option?.key) || 0);
  };
  const handleContractStartDateChange = (seletedDate: Date | undefined) => {
    if (seletedDate) setContractStartDate(seletedDate);
  };
  const handleContractEndDateChange = (seletedDate: Date | undefined) => {
    if (seletedDate) setContractEndDate(seletedDate);
  };
  const handleContractCurrencyChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    setContractCurrency(newValue || '');
  };
  
  const handlePaymentScheduleTypeChange = (
    event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined
  ) => {
    setPaymentScheduleType(Number(option?.key) || 0);
  };
  const handlePaymentScheduleFrequencyChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: number | undefined
  ) => {
    setPaymentScheduleFrecuency(newValue || 0);
  };

  const handleFirstSettlementChange = (seletedDate: Date | undefined) => {
    if (seletedDate) setFirstSettlement(seletedDate);
  };
  const handleParticipationChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    if (newValue && newValue.length < 4)
      setParticipation(Number(newValue) || 0);
  };

  const onChangeUseContractStages = (
    ev: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    checked?: boolean | undefined
  ) => {
    setUseContractStages(checked || false);
  };

  const HandleAddConcept = (concept: NewContractConcept) => {
    setConcepts([...concepts, concept as ContractConcept]);
  };

  const modalProps = useMemo(
    () => ({
      isBlocking: true
    }),
    []
  );

  const ValidateForm = (): boolean => {
    let validationResult: boolean = true;

    let FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
  };

  //events

  const HandleCancelForm = () => {
    ResetForm();
    props.dismissPanel();
  };
  const HandleSubmitForm = () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
      // submit form
      if (!props.Contract) {
        const newContract: NewContract = {
          contractNumber: contractNumber,
          contractDescription: contractDescription,
          contractStatus: contractStatus,
          contractStartDate: contractStartDate,
          contractEndDate: contractEndDate,
          contractCurrency: contractCurrency,
          concepts: concepts,
          feeDivision: Participation,
          useContractStages: useContractStages,
          paymentScheduleType: paymentScheduleType,
          paymentScheduleFrequency: paymentScheduleFrequency,
          commissionAgents: [],
          stages: [],
        };
        props.AddContract(newContract)
      } else {
        const Contract: Contract = {
          id: props.Contract.id,
          contractNumber: contractNumber,
          contractDescription: contractDescription,
          contractStatus: contractStatus,
          contractStartDate: contractStartDate,
          contractEndDate: contractEndDate,
          contractCurrency: contractCurrency,
          concepts: concepts,
          feeDivision: Participation,
          useContractStages: useContractStages,
          paymentSheduleType: paymentScheduleType,
          paymentScheduleFrequency: paymentScheduleFrequency,
          parentContractId: ParentContractId,
          isLocked: props.Contract.isLocked,
          commissionAgents: [],
          stages: [],
          cycles: [],
          branches: [],
          sponsors: [],
          practitioners: [],
          protocol: [],
          commissionConcepts: [],
          isSaving: false,
          isSavedSuccessfully: undefined,
          failOnSaving: false,
          isUpdating: false,
          isUpdatedSuccessfully: undefined,
          failOnUpdating: false,
          isRedreshing: false,
          isRefreshedSuccessfully: undefined,
          failOnRefreshing: false,
          isCloning: false,
          isClonedSuccessfully: undefined,
          failOnCloning: false,
          error: undefined,
          isGettingContractAddendums: false,
          isGettingContractAddendumsSuccess: undefined,
          FailGettingContractAddendums: false,
          isDeleting: false,
          isDeletedSuccessfully: undefined,
          failOnDeleting: false
        };
        props.UpdateContract(Contract);
      }
    }
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Missing Subject',
    closeButtonAriaLabel: 'Close',
    subText: 'Do you want to send this message without a subject?'
  };

  // footer
  const onRenderFooterContent = () => {
    return (
    <div className={Functions.contentStyles.footer}>
      <DefaultButton onClick={HandleCancelForm} className="OtherOption">
        <Trans i18nKey={'protocolContractForm.footer.cancelButton'}/>
      </DefaultButton>
      <PrimaryButton
        onClick={HandleSubmitForm}
        styles={Functions.buttonStyles}
        className="ConfirmAction"
      >
        <Trans i18nKey={'protocolContractForm.footer.saveButton'}/>
      </PrimaryButton>
    </div>
    );
  };

  // form fields
  const FormFields = (
    <>
      {props.mode === 'sponsor' && props.RefererId === undefined && (
        <>
          <Label>{t('protocolContractForm.props.sponsor')}</Label>
          <SponsorSelectorComponent selectionLimit={1} />
        </>
      )}
      {props.mode === 'practitioner' && props.RefererId === undefined && (
        <>
          <Label>{t('protocolContractForm.props.practitioner')}</Label>
          <PractitionerSelectorComponent  selectionLimit={1} />{' '}
        </>
      )}
      <TextField
        label={t('protocolContractForm.props.contractNumber')} 
        name="txt_contract_number"
        required
        autoComplete="off"
        onChange={handleContractNumberChange}
        errorMessage={
          (submittedForm === true && contractNumber === undefined) ||
          (submittedForm === true &&
            contractNumber !== undefined &&
            contractNumber.length === 0)
            ? t('protocolContractForm.props.requiredField')
            : undefined
        }
        value={contractNumber || ''}
      />
      <TextField
        label={t('protocolContractForm.props.comments')} 
        name="txt_contract_description"
        autoComplete="off"
        multiline={true}
        rows={3}
        onChange={handleContractDescriptionChange}
        value={contractDescription || ''}
      />
      <DateTimeInput
        label={t('protocolContractForm.props.effectiveDate')}
        placeHolder={undefined}
        selectedDate={contractStartDate ? new Date(contractStartDate) : undefined}
        isRequired={false}
        isSubmitted={submittedForm}
        errorMessage={
        undefined
        }
        onSelectDate={handleContractStartDateChange}
      />
      <DateTimeInput
        label={t('protocolContractForm.props.expirationDate')}
        placeHolder={undefined}
        selectedDate={contractEndDate ? new Date(contractEndDate) : undefined}
        isRequired={false}
        isSubmitted={submittedForm}
        errorMessage={
         undefined
        }
        onSelectDate={handleContractEndDateChange}
      />
      <Dropdown
        placeholder={t('protocolContractForm.props.selectState')}
        label={t('protocolContractForm.props.contractState')} 
        options={Functions.ContractStatusOptions}
        onChange={handleContractStatusChange}
        selectedKey={contractStatus.toString()}
      />
      <CurrencySelectorComponent
        errorMessage={undefined}
        defaultSelected={contractCurrency}
        onCurrencyChange={(currency: string) => setContractCurrency(currency)}
        label={t('settlementDetailForm.formData.currency')}
        isRequired={true}
      />
      {/* <Separator />
      <Toggle label="¿habilitar especificación de visitas protocolares?" checked={useContractStages} onChange={onChangeUseContractStages} onText="Sí" offText="No" /> */}
      <Separator />
      <label>{t('protocolContractForm.props.settlementType')} {paymentScheduleType}</label>
      <Dropdown
        label={t('protocolContractForm.props.settlementPeriod')} 
        required
        options={Functions.ContractPaymentScheduleTypeOptions}
        onChange={handlePaymentScheduleTypeChange}
        selectedKey={paymentScheduleType ? paymentScheduleType.toString() : undefined}
      />
      {/* {props.mode === 'sponsor' && (
        <TextField
          label="División de honorarios"
          name="txt_participation"
          required
          suffix="%"
          step={1}
          type="number"
          max={100}
          min={1}
          autoComplete="off"
          onChange={handleParticipationChange}
          errorMessage={
            (submittedForm === true && Participation === undefined) ||
            (submittedForm === true &&
              Participation !== undefined &&
              Participation === 0)
              ? 'Campo requerido'
              : undefined
          }
          value={Participation.toString() || ''}
        />
      )} */}
    </>
  );

  return (
    <Panel
      isOpen={props.isOpen}
      onDismiss={props.dismissPanel}
      type={PanelType.smallFixedFar}
      closeButtonAriaLabel="Close"
      headerText={t('protocolContractForm.panel.contractInformation')} 
      onRenderFooter={onRenderFooterContent}
      isFooterAtBottom={true}
    >
      {/* <div className={Functions.contentStyles.header}>
        <span>Información de contrato</span>
        <IconButton
          styles={Functions.iconButtonStyles}
          iconProps={Functions.cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.dismissPanel}
        />
      </div> */}
      {submittedForm && !FormValidationStatus?.success && (
        <MessageComponent
          type={MessageBarType.error}
          message={t('protocolContractForm.panel.reviewInformation')}
          itemList={FormValidationStatus?.errors}
        />
      )}
      {/* <MessageComponent
        message={'Error al intentar grabar'}
        type={MessageBarType.error}
      /> */}
      
       
          <div>{FormFields}</div>
          {/* <div>
            <Label>Conceptos a liquidar</Label>
            <ProtocolContractConceptListComponent concepts={concepts} />
            <Separator />
            <Label style={{ fontSize: FontSizes.mediumPlus }}>
              Agregar nuevo concepto
            </Label>
            <ProtocolContractConceptForm
              currentConcepts={concepts}
              stages={Stages}
              currency={contractCurrency}
              onAddConcept={HandleAddConcept}
              mode={props.mode}
            />
          </div>
          {props.mode === 'sponsor' && (
            <div>
              <Label>Equipo Médico</Label>
              <PrincipalResearcherFormComponent
                currentConcepts={concepts}
                maxAverage={100 - Participation}
              />
            </div>
          )} */}
   
     
    </Panel>
  );
};

export default connect(
  Functions.mapStateToProps,
  Functions.mapDispatchToProps
)(ProtocolContractFormComponent as any)
 
