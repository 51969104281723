import { AppThunkAction } from '../reducers';

// Actions
import * as Actions from '../actions';

// Services
import { Languaje } from '../../models/Entities/Settings/Languajes';


type KnownAction = 
  | Actions.UserSettings.UserSettings_Languaje_Request_Action
  | Actions.UserSettings.UserSettings_Languaje_Receive_Action
  | Actions.UserSettings.UserSettings_Languaje_Fail_Action
  | Actions.UserSettings.UserSettings_Languaje_Change_Request_Action
  | Actions.UserSettings.UserSettings_Languaje_Change_Receive_Action
  | Actions.UserSettings.UserSettings_Languaje_Change_Fail_Action;

 export const ActionCreators = {
    SetUserLanguaje:
      (newLanguaje: Languaje): AppThunkAction<KnownAction> => async (dispatch: any) => {
         dispatch({
           type: 'USER_SETTINGS_LANGUAJE_CHANGE_REQUEST_ACTION',
           Language: newLanguaje
         });
  
        // esto tiene que ser reemplazado por un servicio para persistir la informacion de lenguaje
        dispatch({
          type: 'USER_SETTINGS_LANGUAJE_CHANGE_RECEIVE_ACTION',
          Language: newLanguaje
        });
      }
}