import { useEffect, useState } from 'react';
import { ContractBranch } from '../../../../models/Entities/Contracts/ContractBranch';
import { useBoolean } from '@fluentui/react-hooks';


// component functions 
import * as ComponentFunctions from './BranchList.Functions';

//styles
import './BranchList.Styles.css';
import { DefaultButton, Label, PrimaryButton, Separator, Spinner } from '@fluentui/react';
import { ErrorMessageComponent } from '../../../Error/ErrorMessageComponent';
import { BranchItemComponent } from './BranchItem.Component';
import { connect } from 'react-redux';
import BranchFormComponent from '../BranchForm/BranchForm.Component';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const BranchListComponent = (props: ComponentFunctions.Props) => {
    // initial values
    const [isOpenInitialSettings, { setTrue: openPanelInitialSettings, setFalse: dismissPanelInitialSettings }] = useBoolean(false);
    const [selectedBranch, setSelectedBranch] = useState<ContractBranch | undefined>(undefined);

    // Add or eddit visit
    const [isOpenVisit, { setTrue: openPanelVisit, setFalse: dismissPanelVisit }] = useBoolean(false);

    const handleInitialSettingsClosePanel = () => {
        dismissPanelInitialSettings();
    };

    const handleVisitClosePanel = () => {
        dismissPanelVisit();
    };

    const handleChargeVisitInformation = () => {
        openPanelInitialSettings();
    };

    const HandleAddEvent = () => {
        openPanelVisit();
    };

    const HandleEditEvent = (Branch: ContractBranch) => {
        setSelectedBranch(Branch);
        openPanelVisit();
    }

    //useTranslation()
    const [t] = useTranslation();

    // efects   
    useEffect(() => {
        if(props.Contract) {
            props.GetAllContractsBranchs(props.ContractId);
        }
    }, [props.Contract === undefined]);

    // Loading
    const Loading = (props.isLoading || props.isUpdating) && (
        <div>
          <Spinner
            label={t('branchList.loading.carryingBranches')}
            ariaLive="assertive"
            labelPosition="top"
          />
        </div>
    );

    const ComponentActions = (
        <>
            <PrimaryButton
                secondaryText={t('branchList.componentActions.addBranch')}  
                onClick={HandleAddEvent}
                text={t('branchList.componentActions.addBranch')}
                iconProps={{ iconName: 'Add' }}
                className="actionButton"
            />
        </>
    )

    const ErrorMessage = (props.failOnLoading || props.failOnUpdating) && props.error && (
            <ErrorMessageComponent Error={props.error} />
        )

    const NoRegistries = (props.isLoadingSuccessfully || props.isUpdatedSuccessfully) && (props.branches?.length === 0 || props.branches?.length === undefined) && (
        <div className="NoDataSection">
            <img
                src={require('../../../../assets/img/empty_state.gif')}
                alt="Ichtys"
                style={{width:'4em', height:'4em', marginBottom:'1em'}}
            ></img>
            <Label>{t('branchList.noRegistries.registeredBranches')}</Label>
        </div>
    );

    const Forms = <>
        <BranchFormComponent 
            isOpen={isOpenVisit}
            openPanel={openPanelVisit}
            dismissPanel={handleVisitClosePanel}
            ContractId={props.ContractId}
            ContractBranchId={selectedBranch?.id}    
        />
    </>


    return (
        <>
            {ComponentActions}
            {ErrorMessage}
            {props.isLoading? Loading : null}
            {props.branches?.map((Branch, index) => (
                <BranchItemComponent 
                    Branch={Branch} index={index} 
                    onEdit={(Branch: ContractBranch) => HandleEditEvent(Branch)}
                    onDelete={(Branch: ContractBranch) => props.DeleteContractBranch(Branch.id, props.ContractId)}
                />
            ))}
            {NoRegistries}
            {Forms}
        </>
    )
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
  )(BranchListComponent as any);
