import { Reducer, Action } from 'redux';

// Actions
import * as Actions from '../actions';

// states
import { PractitionerProtocolVisitsState, PractitionerProtocolVisitsUnloadState } from '../states/PractitionerProtocolVisitsState';


type KnownAction =
    | Actions.PractitionerProtocolVisits.Pratitioner_Protocol_Visits_GetAll_Request_Action
    | Actions.PractitionerProtocolVisits.Pratitioner_Protocol_Visits_GetAll_Receive_Action
    | Actions.PractitionerProtocolVisits.Pratitioner_Protocol_Visits_GetAll_Fail_Action;

    export const PractitionerProtocolVisitsReducer: Reducer<PractitionerProtocolVisitsState> = (state: any, incomingAction: Action): PractitionerProtocolVisitsState => {

        if (state === undefined) {
            return PractitionerProtocolVisitsUnloadState as PractitionerProtocolVisitsState;
          }
        
          const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'PRECTITIONER_PROTOCOL_VISITS_GET_ALL_REQUEST_ACTION':
            return {
                ...state,
                isLoading: true,
                successLoading: false,
                failLoading: false,
                error: undefined
            };
        case 'PRECTITIONER_PROTOCOL_VISITS_GET_ALL_RECEIVE_ACTION':
            return {
                ...state,
                isLoading: false,
                successLoading: true,
                failLoading: false,
                list: action.visits
            };
        case 'PRECTITIONER_PROTOCOL_VISITS_GET_ALL_FAIL_ACTION':
            return {
                ...state,
                isLoading: false,
                successLoading: false,
                failLoading: true,
                error: action.error
            };
        default:
            return state;
    }
};