import { connect } from 'react-redux';
import { MessageBar, MessageBarType, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { ErrorMessageComponent } from '../../Error/ErrorMessageComponent';


// component functions 
import * as ComponentFunctions from './FeeDivisionContract.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// styles
import './FeeDivisionContract.Styles.css';


const FeeDivisionContractComponent = (props: ComponentFunctions.Props) => {

    //useTranslation()
    const [t] = useTranslation();
    // events
    const onChangeMaxCostPriceTextFieldValue = (newValue: number ) => {
        props.ChangeFeeDivision(props.ContractId, newValue); 
     }

    return (
        <div className='feeDivisionForm'>
           {props.FailChangingFeeDivision && <ErrorMessageComponent Error={props.error}  />}
           <div >
                <TextField
                    label={t('feeDivisionContract.textField.feeDivision')} 
                    type="number"
                    suffix={'%'}
                    step={1}
                    maxLength={3}
                    required
                    defaultValue={props.Contract?.feeDivision.toString()}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => onChangeMaxCostPriceTextFieldValue( newValue ? Number(newValue) : 0 )}
                />
                {props.isUpdatingFeeDivision && <Spinner size={SpinnerSize.medium} style={{marginBottom: 5}} />}
            </div>
            <p>
                <MessageBar
                    messageBarType={MessageBarType.warning}
                    isMultiline={true}
                >
                    {t('feeDivisionContract.message.feeDivisionMessage')} 
                </MessageBar>
            </p>
        </div>
    )

}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(FeeDivisionContractComponent as any)

