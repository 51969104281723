import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { StageCycle } from "../../../../models/Entities/Contracts/StageCycle";

import { ErrorType } from "../../../../models/HttpError";

// stores
import * as ContractCycleStore from '../../../../redux/middleware/ContractStageCycleMeddleware';

//State
import { AppState } from "../../../../redux/reducers";


interface IProps {
    ContractId: number;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    isDeleting: boolean;
    isDeletedSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    failOnDeleting: boolean;
    error: ErrorType | undefined;
    Contract: Contract | undefined;
    Cycles: StageCycle[];
  }
  
  interface DispatchProps {
    GetAllContractsStageCycles: typeof ContractCycleStore.actionCreators.GetAllContractsStageCycles;
    GetContractStageCycleById: typeof ContractCycleStore.actionCreators.GetContractStageCycleById;
    DeleteContractStageCycle: typeof ContractCycleStore.actionCreators.DeleteContractStageCycle;
  }

  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.ContractStageCycle?.isLoadingAll,
    isUpdating: state.ContractStageCycle?.isLoadingAll,
    isUpdatedSuccessfully: state.ContractStageCycle?.successLoadingAll,
    isLoadingSuccessfully: state.ContractStageCycle?.successLoadingAll,
    failOnUpdating: state.ContractStageCycle?.failOnLoadingAll,
    failOnLoading: state.ContractStageCycle?.failOnLoadingAll,
    isDeleting: state.ContractStageCycle?.isDeletingOne,
    isDeletedSuccessfully: state.ContractStageCycle?.successDeletingOne,
    failOnDeleting: state.ContractStageCycle?.failOnDeletingOne,
    error: state.ContractStageCycle?.error,
    Contract: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId ) : undefined,
    Cycles: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId )?.cycles : []
    
  });
  
  export const mapDispatchToProps = {
    ...ContractCycleStore.actionCreators
  };