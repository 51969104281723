import AccountBalanceComponent from "../AccountBalance/AccountBalance.Component";
import AccountTransactionComponent from "../AccountTransactions/AccountTransaction.component";

//styles 
import './PaymentAccountDetails.Styles.css';

export const PaymentAccountDetailsComponent = () => {
    return (
        <div className="BalanceDetails">
            <div>
                <AccountTransactionComponent />
            </div>
            <div className="Section interna">
                <AccountBalanceComponent />
            </div>
        </div>
    );
}

export default PaymentAccountDetailsComponent;