import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractStage } from "../../../../models/Entities/Contracts/ContractStage";
import { ErrorType } from "../../../../models/HttpError";

// stores
import * as ContractStageStore from '../../../../redux/middleware/ContractStageMiddleware';
import * as ContractStore from '../../../../redux/middleware/ContractMiddleware';

//State
import { AppState } from "../../../../redux/reducers";
import { StageCycle } from "../../../../models/Entities/Contracts/StageCycle";


interface IProps {
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    ContractId: number;
    ContractStageId: number | undefined;
    CurrentCycles: StageCycle[] | undefined;
}
  
interface ConnectedProps {
    // loading
    isLoading: boolean;
    isLoadingSuccessfully: boolean;
    failOnLoading: boolean;

    // adding
    isAddingNewOne: boolean;
    isAddedSuccessfully: boolean;
    failOnAdding: boolean;

    // Ading Range
    AddRangeFinalized: boolean | undefined;

    // updating
    isupdatingNewOne: boolean;
    isUpdatedNewOneSuccessfully: boolean;
    failOnUpdating: boolean;

    error: ErrorType | undefined;

    Contract: Contract;
    Stage: ContractStage;
}
  
interface DispatchProps {
    GetContractStageById: typeof ContractStageStore.actionCreators.GetContractStageById;
    AddContractStage: typeof ContractStageStore.actionCreators.AddContractStage;
    UpdateContractStage: typeof ContractStageStore.actionCreators.UpdateContractStage;
    GetContractById: typeof ContractStore.actionCreators.GetContractById;
}
  
export type Props = IProps & ConnectedProps & DispatchProps;
  
export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.ContractStage?.isLoadingAll,
    isUpdating: state.ContractStage?.isLoadingAll,
    isUpdatedSuccessfully: state.ContractStage?.isUpdatingAllSuccess,
    isLoadingSuccessfully: state.ContractStage?.successLoadingAll,
    isAddingNewOne: state.ContractStage?.isAddingNewOne,
    isAddedSuccessfully: state.ContractStage?.successAddingNewOne,
    isupdatingNewOne: state.ContractStage?.isUpdatingOne,
    isUpdatedNewOneSuccessfully: state.ContractStage?.successUpdatingOne,
    failOnUpdating: state.ContractStage?.failOnLoadingAll,
    failOnLoading: state.ContractStage?.failOnLoadingAll,
    AddRangeFinalized: state.ContractStage?.isAddingFinishedSuccessfully,
    isDeleting: state.ContractStage?.isDeletingOne,
    isDeletedSuccessfully: state.ContractStage?.successDeletingOne,
    failOnDeleting: state.ContractStage?.failOnDeletingOne,
    error: state.ContractStage?.error,
    Contract: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId ) : undefined,
    Stage: ownProps.ContractStageId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId )?.stages?.find((stage: ContractStage) => stage.id === ownProps.ContractStageId ) : undefined
});
  
export const mapDispatchToProps = {
    ...ContractStageStore.actionCreators,
    ...ContractStore.actionCreators
};