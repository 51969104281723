//functions 
import { connect } from 'react-redux';
import * as ComponentFunctions from './Settlement.Form.Functions';
import { useEffect, useState } from 'react';
import { DateTimeInput } from '../../../commons/component/Inputs/DateTimeInput/DateTimeInput';
import { DefaultButton, Label, Panel, PrimaryButton, TextField, Text, Separator, Toggle, Check, Checkbox, IconButton, ValidationState, MessageBarType } from '@fluentui/react';
import { HeaderPersonaComponent } from '../../../commons/component/headers/HeaderPersona/HeaderPersonaComponent';
import moment from 'moment';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

//styles
import './Settlement.Form.Styles.css';
import { NewAgreementSettlement, NewPractitionerSettlement, NewProtocolSettlement } from '../../../models/Entities/Settlements/NewSettlement';
import { NewMedicalInsurance } from '../../../models/Entities/MedicalInsurances/NewMedicalInsurance';
import ContractConceptSelectorComponent from '../../../commons/component/Inputs/ContractConceptSelector/ContractConceptSelector.Component';
import ContractSelectorComponent from '../../../commons/component/Inputs/ContractSelector/ContractSelectorComponent';
import { Contract } from '../../../models/Entities/Contracts/Contract';
import { MessageBarActions, MessageBarBody, MessageBarTitle, Spinner, Button, MessageBar} from '@fluentui/react-components';
import { MessageComponent } from '../../../commons/component/MessageComponent';
import { processSilentRenew } from 'redux-oidc';


interface FormValidation {
    success: boolean;
    errors: string[];
}


const SettlementFormComponent = (props: ComponentFunctions.Props) => {

    // form status
    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    // marks
    const [manualSettlement, setManualSettlement] = useState<boolean>(false);
    const [IncludeAditionalConcepts, setIncludeAditionalConcepts] = useState<boolean>(false);
    const [IncludeRefundableConcepts, setIncludeRefundableConcepts] = useState<boolean>(false);

    // form properties
    const [settlementName, setSettlementName] = useState<string>();
    const [settlementDescription, setSettlementDescription] = useState<string>();
    const [settlementStartDate, setSettlementStartDate] = useState<Date>();
    const [settlementEndDate, setSettlementEndDate] = useState<Date>();
    const [settlementContract, setSettlementContract] = useState<Contract | undefined>(props.Contract);
    const [settlementAgreementId, setSettlementAgreementId] = useState<number>();
    const [settlementMedicalInsuranceId, setSettlementMedicalInsuranceId] = useState<number | undefined>(props.MedicalInsuranceId);
    const [settlementProtocolId, setSettlementProtocolId] = useState<number | undefined>(props.ProtocolId);
    const [settlementPractitionerId, setSettlementPractitionerId] = useState<string | undefined>(props.PractitionerId);

    //useTranslation();
    const [t] = useTranslation();

    //efects 
    useEffect(() => {
        setSettlementName(ComponentFunctions.SuggestSettlementTitle(
            props.MedicalInsurance ? props.MedicalInsurance?.businessName :
                props.Practitioner ? props.Practitioner?.fullName :
                    props.Protocol ? props.Protocol?.title : '')
        );
    }, [settlementName === undefined]);

    useEffect(() => {
        if (props.ContractId) {
            props.GetContractById(props.ContractId);
        }
    }, [props.ContractId]);

    useEffect(() => {
        if (props.Contract) {
            setSettlementContract(props.Contract)
        }
    }, [props.Contract]);


    useEffect(() => {
        props.isSuccessfullyAdded && ResetForm();
        props.isSuccessfullyAdded && props.dismissPanel();
    }, [props.isSuccessfullyAdded === true]);

    // event handlers
    const onChangeSettlementName = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined) => {

        setSettlementName(newValue);
    }

    const onChangeSettlementDescription = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined) => {

        setSettlementDescription(newValue);
    }

    const onSelectStartDate = (date: Date | null | undefined) => {
        setSettlementStartDate(date!);
    }

    const onSelectEndDate = (date: Date | null | undefined) => {
        setSettlementEndDate(date!);
    }

    const HandleSaveForm = () => {
        setSubmittedForm(true);
        if (ValidateForm()) {

            if (props.ContractId) {

                // add protocol settlement
                if (props.Protocol) {
                    const protocolSettlement: NewProtocolSettlement = {
                        name: settlementName!,
                        contractId: props.ContractId!,
                        description: settlementDescription,
                        settlementType: 0,
                        settlementDateRangeInit: settlementStartDate!,
                        settlementDateRangeEnd: settlementEndDate!,
                        protocolId: props.Protocol.id,
                        isManualSettlement: manualSettlement,
                        isIncludedAdditionalConcepts: IncludeAditionalConcepts,
                        isIncludedRefundableConcepts: IncludeRefundableConcepts
                    }

                    props.AddProtocolSettlement(protocolSettlement);
                }

                // add practitioner settlement
                if (props.Practitioner) {

                    const practitionerSettlement: NewPractitionerSettlement = {
                        name: settlementName!,
                        contractId: props.ContractId!,
                        description: settlementDescription,
                        settlementType: 0,
                        settlementDateRangeInit: settlementStartDate!,
                        settlementDateRangeEnd: settlementEndDate!,
                        practitionerId: props.Practitioner.userId,
                        isManualSettlement: manualSettlement
                    }

                    props.AddPractitionerSettlement(practitionerSettlement);

                }
            }

            // add protocol settlement
            if (props.ProtocolId) {
                const protocolSettlement: NewProtocolSettlement = {
                    name: settlementName!,
                    contractId: props.Contract?.id,
                    description: settlementDescription,
                    settlementType: 0,
                    settlementDateRangeInit: settlementStartDate!,
                    settlementDateRangeEnd: settlementEndDate!,
                    protocolId: props.ProtocolId,
                    isManualSettlement: manualSettlement,
                    isIncludedAdditionalConcepts: IncludeAditionalConcepts,
                    isIncludedRefundableConcepts: IncludeRefundableConcepts
                }

                props.AddProtocolSettlement(protocolSettlement);
            }

            // add practitioner settlement
            if (props.PractitionerId) {

                const practitionerSettlement: NewPractitionerSettlement = {
                    name: settlementName!,
                    contractId: props.Contract?.id,
                    description: settlementDescription,
                    settlementType: 0,
                    settlementDateRangeInit: settlementStartDate!,
                    settlementDateRangeEnd: settlementEndDate!,
                    practitionerId: props.PractitionerId,
                    isManualSettlement: manualSettlement
                }

                props.AddPractitionerSettlement(practitionerSettlement);

            }

            // add medical insurance settlement
            if (props.MedicalInsuranceId) {

                const medicalInsuranceSettlement: NewAgreementSettlement = {
                    name: settlementName!,
                    description: settlementDescription,
                    settlementType: 0,
                    settlementDateRangeInit: settlementStartDate!,
                    settlementDateRangeEnd: settlementEndDate!,
                    medicalAgreementId: 0,
                    medicalInsuranceId: 0,
                    isManualSettlement: manualSettlement
                }

                props.AddAgreementSettlement(medicalInsuranceSettlement);
            }
        }
    };

    const HandleCancelForm = () => {
        ResetForm();
        props.dismissPanel();
    };

    // content

    const formData = (
        <>
            <TextField
                label={t('settlementForm.formData.settlementName')}
                name="settlement-name"
                required
                type="text"
                autoComplete="off"
                onChange={onChangeSettlementName}
                errorMessage={
                    submittedForm === true && settlementName === undefined
                        ? t('settlementForm.formData.requiredField')
                        : undefined
                }
                value={settlementName}
            />
            <TextField
                label={t('settlementForm.formData.settlementDescription')}
                name="settlement-description"
                type="text"
                multiline={true}
                rows={3}
                autoComplete="off"
                onChange={onChangeSettlementDescription}
                errorMessage={
                    submittedForm === true && settlementName === undefined
                        ? t('settlementForm.formData.requiredField')
                        : undefined
                }
                value={settlementDescription}
            />
            <Toggle
                label={'Generar una liquidación manual'}
                onText={'Si'}
                offText={'No'}
                defaultChecked={false}
                onChange={(ev, checked) => setManualSettlement(checked!)}
            />
            <Separator />
            {!manualSettlement &&
                <>
                    <DateTimeInput
                        label={t('settlementForm.formData.dateFrom')}
                        placeHolder={''}
                        selectedDate={settlementStartDate}
                        isSubmitted={submittedForm}
                        isRequired={true}
                        errorMessage={undefined}
                        onSelectDate={onSelectStartDate}
                    />
                    <DateTimeInput
                        label={t('settlementForm.formData.dateUtil')}
                        placeHolder={''}
                        selectedDate={settlementEndDate}
                        isSubmitted={submittedForm}
                        isRequired={true}
                        errorMessage={undefined}
                        onSelectDate={onSelectEndDate}
                    />
                    {props.ContractId && <ContractConceptSelectorComponent
                        ContractConceptsSelected={[]}
                        onSelectedContractConcept={() => undefined}
                        MultiselectEnable={true}
                        noteText={'Seleccione los conceptos a incluir en la liquidación, o deje en blanco para incluir todos los conceptos del contrato.'}
                        ContractId={props.ContractId}
                    />}
                    <Separator />
                </>
            }
            {!props.PractitionerId &&
                <>
                    <p>
                        <Checkbox
                            label={'Incluir conceptos adicionales / itemisables'}
                            defaultChecked={IncludeAditionalConcepts}
                            onChange={(ev, checked) => setIncludeAditionalConcepts(checked!)}
                        />
                    </p>
                    <p>
                        <Checkbox
                            label={'Incluir conceptos reembolsables'}
                            defaultChecked={IncludeRefundableConcepts}
                            onChange={(ev, checked) => setIncludeRefundableConcepts(checked!)}
                        />
                    </p>
                </>
            }

        </>
    )

    // form validation
    const ValidateForm = (): boolean => {
        let validationResult: boolean = true;

        let FormValidation: FormValidation = { success: true, errors: [] };
        setFormValidationStatus(FormValidation);

        if ((settlementName === undefined || settlementName === '')) {
            validationResult = false;
            FormValidation.errors.push(t('settlementForm.formValidation.requiredName'));
        }

        if (!manualSettlement) {

            if ((settlementStartDate === undefined || settlementStartDate === null)) {
                validationResult = false;
                FormValidation.errors.push(t('settlementForm.formValidation.requiredStartDate'));
            }

            if ((settlementEndDate === undefined || settlementEndDate === null)) {
                validationResult = false;
                FormValidation.errors.push(t('settlementForm.formValidation.requiredEndDate'));
            }

            if ((settlementStartDate && settlementEndDate) && moment(settlementStartDate).isAfter(settlementEndDate)) {
                validationResult = false;
                FormValidation.errors.push(t('settlementForm.formValidation.errorDate'));
            }
        }

        FormValidation.success = validationResult;
        setFormValidationStatus(FormValidation);
        return validationResult;
    };

    const ResetForm = () => {
        setSubmittedForm(false);
        setSettlementName(undefined);
        setSettlementContract(undefined);
        setSettlementDescription(undefined);
        setSettlementStartDate(undefined);
        setSettlementEndDate(undefined);
    }

    // form content

    // contract info 

    const ContractInfo = (
        <>
            <Text style={{ marginBottom: 10, display: 'block' }}>{t('settlementForm.information.contractInfo')}</Text>
            {(props.Contract === undefined && props.ProtocolId) && 
            <MessageBar intent={'warning'}>
                <MessageBarBody>
                    <MessageBarTitle>Protocolo sin contrato asociado</MessageBarTitle>
                </MessageBarBody>
            </MessageBar>}
            {settlementContract?.id !== undefined &&
                <h3 className='contractSelector'>
                    {settlementContract?.contractNumber}
                    {props.Contract === undefined && <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        onClick={() => setSettlementContract(undefined)}
                    />}
                </h3>}
            {settlementContract === undefined && <ContractSelectorComponent
                onSelectedContract={(contract: Contract[]) => setSettlementContract(contract[0])}
                ContractsSelected={undefined}
                MultiselectEnable={false} />
            }
            {(props.Contract === undefined && settlementContract?.id !== undefined && props.ProtocolId) && <MessageBar>
                <MessageBarBody>
                    <MessageBarTitle>Protocolo sin contrato asociado</MessageBarTitle>
                    <p>Ha seleccionado un contrato para un protocolo que no tiene un contrato asociado.
                    desea asociar este contrato al protocolo?</p>
                </MessageBarBody>
                <MessageBarActions>
                    <Button appearance='primary' onClick={() => props.AssociateContractToProtocol(settlementContract?.id!, props.ProtocolId!)}>Asociar</Button>
                </MessageBarActions>
            </MessageBar>}
            <Separator />
        </>
    );

    const ProtocolInfo = (
        <>
            <Text style={{ marginBottom: 10, display: 'block' }}>{t('settlementForm.information.protocolInfo')}</Text>
            <h3>{props.Protocol?.title}</h3>
            <Separator />
        </>
    );

    const PractitionerInfo = (
        <>
            <Text style={{ marginBottom: 10, display: 'block' }}>{t('settlementForm.information.practitionerInfo')}</Text>
            {props.Practitioner && <HeaderPersonaComponent PersonaName={props.Practitioner?.fullName} secondaryText={props.Practitioner?.speciality} />}
            <Separator />
        </>
    )

    const MedicalInsuranceInfo = (
        <>
            <Text>{t('settlementForm.medicalInsurance.healthProvider')}</Text>
            <h3>{props.MedicalInsurance?.businessName}</h3>
            <Text>{t('settlementForm.medicalInsurance.agreement')}</Text>
            <h3>{props.MedicalAgreement?.healthProviderId}</h3>
            <Separator />
        </>
    );

    const NoAssociations = (
        <>
            <p className="NoData">{t('settlementForm.medicalInsurance.noContractAssociations')}</p>
        </>
    )

    // footer
    const onRenderFooterContent = () => {
        return (
            <div className={ComponentFunctions.contentStyles.footer}>
                <DefaultButton onClick={HandleCancelForm} className="OtherOption">
                    <Trans i18nKey={'settlementForm.footer.cancelButton'} />
                </DefaultButton>
                {(props.Practitioner || props.Protocol || props.MedicalInsurance) && <PrimaryButton
                    onClick={HandleSaveForm}
                    styles={ComponentFunctions.buttonStyles}
                    className="ConfirmAction"
                    disabled={props.isAdding}
                >
                    {props.isAdding && <><Spinner appearance="inverted" size="tiny" />&nbsp;</>}
                    <Trans i18nKey={'settlementForm.footer.saveButton'} />
                </PrimaryButton>}

            </div>
        );
    };

    return (
        <Panel
            isOpen={props.isOpen}
            onDismiss={props.dismissPanel}
            headerText={t('settlementForm.panel.newSettlement')}
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
        >
            {props.failOnAdding && <MessageComponent
                message='Atención:'
                type={MessageBarType.error}
                subMessage={props.error?.ErrorMessage}
                itemList={props.error?.Errors}
             />}
            {(props.PractitionerId || props.Contract?.practitioners[0]?.practitionerId) && PractitionerInfo}
            {(props.ProtocolId || props.Contract?.protocol[0]?.protocolId) && ProtocolInfo}
            {ContractInfo}
            {(props.MedicalInsuranceId || props.MedicalInsurance) && MedicalInsuranceInfo}
            {submittedForm && !FormValidationStatus?.success && (
                <MessageComponent 
                type={MessageBarType.error} message={'Verifique la información suministrada.'}
                itemList={FormValidationStatus?.errors}                
               />
            )}
            {(props.Contract || props.MedicalInsuranceId) && (props.Practitioner || props.Protocol || props.MedicalInsurance) && formData}
            {!(!props.Contract || !props.MedicalInsuranceId) && !(!props.Practitioner && !props.Protocol && !props.MedicalInsurance) && NoAssociations}
        </Panel>
    )
}

export default connect(ComponentFunctions.mapStateToProps, ComponentFunctions.mapDispatchToProps)(SettlementFormComponent as any);