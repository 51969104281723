//#region Props

import { Contract } from "../../../models/Entities/Contracts/Contract";
import { ContractAddendum } from "../../../models/Entities/Contracts/ContractAddendum";
import { ErrorType } from "../../../models/HttpError";
import { AppState } from "../../../redux/reducers";

// stores
import * as ContractsStore from "../../../redux/middleware/ContractMiddleware";


interface IProps {
    ContractId: number;
}

interface ConnectedProps {
    isLoading: boolean;
    isLoadingSuccessfully: boolean | undefined;
    failOnLoading: boolean;
    isCreatingAddendum: boolean;
    isCreatingAddendumSuccessfully: boolean | undefined;
    failOnCreatingAddendum: boolean;
    isGettingAddendums: boolean;
    ObteinedAddendumsSuccessfully: boolean | undefined;
    FailGettingAddendums: boolean;
    error: ErrorType | undefined;
    Contract: Contract | undefined;
    Contracts : Contract[];
    Addendums: ContractAddendum | undefined;
}

interface MedicalServicesDispatchProps {
    GetContractById: typeof ContractsStore.actionCreators.GetContractById;
    GetContractAddendumns: typeof ContractsStore.actionCreators.GetContractAddendumns;
    CloneContract: typeof ContractsStore.actionCreators.CloneContract;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const ContractProtocolAssociated: number | undefined = state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.ContractId)?.protocol[0]?.protocolId;
    const ContractPractitionerAssociated: string | undefined = state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.ContractId)?.practitioners[0]?.practitionerId;

    return {
        isLoading: state.Contract?.isLoadingOne,
        isLoadingSuccessfully: state.Contract?.successLoadingOne,
        failOnLoading: state.Contract?.failOnLoadingOne,
        isCreatingAddendum: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.isCloning,
        isCreatingAddendumSuccessfully: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.isClonedSuccessfully,
        failOnCreatingAddendum: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.failOnCloning,
        isGettingAddendums: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.isGettingContractAddendums,
        ObteinedAddendumsSuccessfully: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.isGettingContractAddendumsSuccess,
        FailGettingAddendums: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.FailGettingContractAddendums,
        error: state.Contract?.error,
        Contract: state.Contract?.list?.find(c => c.id === ownProps.ContractId),
        Contracts: state.Contract?.list || [],
        Addendums: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.adendums
    }
};

export const mapDispatchToProps = {
    ...ContractsStore.actionCreators
};

//#endregion Props

export const isCurrentAddendum = (addendum: ContractAddendum | undefined) => {
    const today = new Date();
    if (!addendum) return false;
    if (!addendum.validityFrom && !addendum.validityTo) return false;
    if (addendum.validityFrom && !addendum.validityTo) {
        return today >= new Date(addendum.validityFrom);
    }
    if (!addendum.validityFrom && addendum.validityTo) {
        return today <= new Date(addendum.validityTo);
    }
    return today >= new Date(addendum.validityFrom!) && today <= new Date(addendum.validityTo!);
}

export const isExpiredAddendum = (addendum: ContractAddendum | undefined) => {
    const today = new Date();
    if (!addendum) return false;
    if (!addendum.validityFrom && !addendum.validityTo) return false;
    if (addendum.validityFrom && !addendum.validityTo) {
        return false;
    }
    if (!addendum.validityFrom && addendum.validityTo) {
        return today > new Date(addendum.validityTo);
    }
    return today > new Date(addendum.validityTo!);
}



export const GetContractStatusClass = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return "";
        case 1:
            return "Active";
        case 2:
            return "Inactive";
        case 3:
            return "Suspended";
        case 4:
            return "Expired";
        case 5:
            return "Canceled";
        case 6:
            return "Terminated";
        default:
            return "";
    }
};

export const GetContractStatusDescripcion = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return "Undefined";
        case 1:
            return "Active";
        case 2:
            return "Inactive";
        case 3:
            return "Suspended";
        case 4:
            return "Expired";
        case 5:
            return "Canceled";
        case 6:
            return "Finished";
        default:
            return "Undefined";
    }
};