import { AppState } from './../reducers/index';
import { NewPractitionerMedicalInsuranceAssociation } from './../../models/Entities/PractitionerAssociations/MedicalInsurances/NewPractitionerMedicalInsuranceAssociation';
import { AppThunkAction } from '../reducers';
import { PractitionerMedicalInsuranceAssociation } from './../../models/Entities/PractitionerAssociations/MedicalInsurances/PractitionerMedicalInsuranceAssociation';
import { Practitioner } from '../../models/Entities/Practitioners/Practitioner';
import { ErrorType } from '../../models/HttpError';
import { ContractPractitionerAssociation } from '../../models/Entities/Contracts/ContractPractitionerAssociation';

// services
import * as Services from '../../services/Practitioners.Service';

// actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.Practitioner.Practitioners_GetAll_Request_Action
  | Actions.Practitioner.Practitioners_GetAll_Receive_Action
  | Actions.Practitioner.Practitioners_GetAll_Fail_Action
  | Actions.Practitioner.Practitioner_UpdateAll_Request_Action
  | Actions.Practitioner.Practitioner_UpdateAll_Receive_Action
  | Actions.Practitioner.Practitioner_UpdateAll_Fail_Action
  | Actions.Practitioner.Practitioner_GetById_Request_Action
  | Actions.Practitioner.Practitioner_GetById_Receive_Action
  | Actions.Practitioner.Practitioner_GetById_Fail_Action
  | Actions.Practitioner.Practitioners_GetAll_MedicalInsurances_Request_Action
  | Actions.Practitioner.Practitioners_GetAll_MedicalInsurances_Receive_Action
  | Actions.Practitioner.Practitioners_GetAll_MedicalInsurances_Fail_Action
  | Actions.Practitioner.Practitioners_Add_MedicalInsurances_Request_Action
  | Actions.Practitioner.Practitioners_Add_MedicalInsurances_Receive_Action
  | Actions.Practitioner.Practitioners_Add_MedicalInsurances_Fail_Action
  | Actions.Practitioner.Practitioners_Delete_MedicalInsurances_Request_Action
  | Actions.Practitioner.Practitioners_Delete_MedicalInsurances_Receive_Action
  | Actions.Practitioner.Practitioners_Delete_MedicalInsurances_Fail_Action
  | Actions.Practitioner.Practitioners_GetAll_ContractsAssociations_Request_Action
  | Actions.Practitioner.Practitioners_GetAll_ContractsAssociations_Receive_Action
  | Actions.Practitioner.Practitioners_GetAll_ContractsAssociations_Fail_Action;

export const actionCreators = {
  GetAllPractitioners:
    (
      pageNumber: number,
      pageSize: number,
      searchText: string | undefined
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONERS_GETALL_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      if(state.Practitioner?.isLoadingAll) return;

      if (!state.Practitioner?.successLoadingAll) {
        dispatch({
          type: 'PRACTITIONERS_GETALL_REQUEST_ACTION'
        });

        Services.GetPractitioners(pageNumber, pageSize, searchText, token)
          .then((practitioners: Practitioner[]) => {
            dispatch({
              type: 'PRACTITIONERS_GETALL_RECEIVE_ACTION',
              practitioners: practitioners
            });
          })
          .catch((error: ErrorType) =>
            dispatch({
              type: 'PRACTITIONERS_GETALL_FAIL_ACTION',
              error: error
            })
          );
      } else {
        dispatch({
          type: 'PRACTITIONERS_UPDATE_ALL_REQUEST_ACTION'
        });

        Services.GetPractitioners(pageNumber, pageSize, undefined, token)
          .then((practitioners: Practitioner[]) => {
            dispatch({
              type: 'PRACTITIONERS_UPDATE_ALL_RECEIVE_ACTION',
              practitioners: practitioners
            });
          })
          .catch((error: ErrorType) =>
            dispatch({
              type: 'PRACTITIONERS_UPDATE_ALL_FAIL_ACTION',
              error: error
            })
          );
      }
    },

  GetPractitionerById:
    (practitionerId: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState: () => AppState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONERS_GETALL_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      const practitioner: Practitioner | undefined =
      state.Practitioner?.list?.find(
          (practitioner: Practitioner) => practitioner.userId === practitionerId
        );

      if (!practitioner) {
        dispatch({
          type: 'PRACTITIONERS_GETALL_REQUEST_ACTION'
        });

        Services.GetPractitioners(1, 1000, '', token)
          .then((practitioners: Practitioner[]) => {
            dispatch({
              type: 'PRACTITIONERS_GETALL_RECEIVE_ACTION',
              practitioners: practitioners
            });
          })
          .catch((error: ErrorType) =>
            dispatch({
              type: 'PRACTITIONERS_GETALL_FAIL_ACTION',
              error: error
            })
          );
      }
    },

  GetAllPractitionersMedicalInsurances:
    (practitionerId: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONERS_GETALL_MEDICALINSURANCES_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRACTITIONERS_GETALL_MEDICALINSURANCES_REQUEST_ACTION',
        practitionerId: practitionerId
      });

      Services.GetPractitionerMedicalInsurancesAssociated(practitionerId, token)
        .then(
          (
            MedicalInsuranceAssociations: PractitionerMedicalInsuranceAssociation[]
          ) => {
            dispatch({
              type: 'PRACTITIONERS_GETALL_MEDICALINSURANCES_RECEIVE_ACTION',
              MedicalInsuranceAssociations: MedicalInsuranceAssociations
            });
          }
        )
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRACTITIONERS_GETALL_MEDICALINSURANCES_FAIL_ACTION',
            error: error
          })
        );
    },

  AddPractitionerMedicalInsurance:
    (
      newMedicalInsuranceAssociation: NewPractitionerMedicalInsuranceAssociation
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONERS_ADD_MEDICALINSURANCES_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRACTITIONERS_ADD_MEDICALINSURANCES_REQUEST_ACTION',
        MedicalInsuranceAssociation: newMedicalInsuranceAssociation
      });

      Services.AddPractitionerMedicalInsuranceAssociation(
        newMedicalInsuranceAssociation,
        token
      )
        .then(
          (
            MedicalInsuranceAssociation: PractitionerMedicalInsuranceAssociation
          ) => {
            dispatch({
              type: 'PRACTITIONERS_ADD_MEDICALINSURANCES_RECEIVE_ACTION',
              MedicalInsuranceAssociation: MedicalInsuranceAssociation
            });
          }
        )
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRACTITIONERS_ADD_MEDICALINSURANCES_FAIL_ACTION',
            error: error
          })
        );
    },

  DeletePractitionerMedicalInsurance:
    (
      MedicalInsuranceAssociation: PractitionerMedicalInsuranceAssociation
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONERS_DELETE_MEDICALINSURANCES_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRACTITIONERS_DELETE_MEDICALINSURANCES_REQUEST_ACTION',
        MedicalInsuranceAssociation: MedicalInsuranceAssociation
      });

      Services.DeletePractitionerMedicalInsuranceAssociation(
        MedicalInsuranceAssociation.id,
        token
      )
        .then(() => {
          dispatch({
            type: 'PRACTITIONERS_DELETE_MEDICALINSURANCES_RECEIVE_ACTION',
            MedicalInsuranceAssociation: MedicalInsuranceAssociation
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRACTITIONERS_DELETE_MEDICALINSURANCES_FAIL_ACTION',
            error: error
          })
        );
    },
    GetAllContractAssociations: 
    (practitionerId: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONERS_GETALL_CONTRACTSASSOCIATIONS_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRACTITIONERS_GETALL_CONTRACTSASSOCIATIONS_REQUEST_ACTION',
        practitionerId: practitionerId
      });

      Services.GetPractitionerContracts(practitionerId, token)
        .then(
          (
            Contract: ContractPractitionerAssociation
          ) => {
            dispatch({
              type: 'PRACTITIONERS_GETALL_CONTRACTSASSOCIATIONS_RECEIVE_ACTION',
              Contract: Contract
            });
          }
        )
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRACTITIONERS_GETALL_CONTRACTSASSOCIATIONS_FAIL_ACTION',
            error: error
          })
        );
    },
};
