import { FontSizes, Label, Text } from "@fluentui/react";
import { ContractBranch } from "../../../../models/Entities/Contracts/ContractBranch";

// styles
import './BranchList.Styles.css';
import { ConfirmationButtonComponent } from "../../../../commons/component/ConfirmationButton/ConfirmationButtonComponent";
import { AppState } from "../../../../redux/reducers";
import { useSelector } from "react-redux";

////i18Next
import { useTranslation, Trans } from 'react-i18next';



export interface IProps {
    Branch: ContractBranch;
    index: number;
    onEdit?: (branch: ContractBranch) => void;
    onDelete?: (branch: ContractBranch) => void;
}

export const BranchItemComponent = (props: IProps) => {

    //useTranslation()
    const [t] = useTranslation();

    const BranchState = useSelector(
        (state: AppState) => state.ContractBranch
      );

    const onSelectBranch = (branch: ContractBranch) => {
        if(props.onEdit) {
            props.onEdit(branch);
        }
    }

    return (
        <>
            <div className="BranchListItem" key={props.Branch.id} onClick={() => onSelectBranch(props.Branch)}>
                <div className="BranchIndex">
                    <Text style={{fontSize: FontSizes.mini}}>{t('branchItem.textBranch')}{props.index + 1}</Text>
                </div>
                <div className="BranchLsitItemContent">
                    <Label style={{fontSize: FontSizes.size14}}>{props.Branch.branchName}</Label>
                    {props.Branch.branchDescription && <div>
                        <Text>{props.Branch.branchDescription}</Text>
                    </div>}
                </div>
                <div>
                <ConfirmationButtonComponent 
                ButtonId={"deletebranch" + props.Branch.id} 
                ButtonIconName={"Delete"} 
                ButtonText={""} 
                ButtonCssClass={"danger"} 
                ConfirmationButtonText={t('branchItem.confirmationButton.deleteButton')} 
                ConfirmationButtonCssClass={""} 
                CancelButtonText={t('branchItem.confirmationButton.cancelButton')} 
                CancelButtonCssClass={""} 
                Title={t('branchItem.confirmationButton.branchDeleteConfirm')} 
                Description={""} 
                ElementTextHeader={t('branchItem.confirmationButton.branchDelete')+ props.Branch.branchName} 
                ElementTextInformation={t('branchItem.confirmationButton.textInformation')} 
                onConfirmation={() =>
                    props.onDelete && props.onDelete(props.Branch)
                  }
                  onCancel={function (): void {
                    throw new Error('Function not implemented.');
                  }}
                  isOperationExecuting={
                    BranchState?.isDeletingOne || false
                  }
                  isOperationExecutedWithError={
                    BranchState?.failOnDeletingOne || false
                  }
                  OperationError={BranchState?.error}
                />
                </div>
            </div>
        </>
    );
}