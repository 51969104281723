// contract stage middleware

import { ErrorType } from '../../models/HttpError';
import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/ContractStage.Sevices';

// actions
import * as Actions from '../actions';
import { Contract } from '../../models/Entities/Contracts/Contract';
import { ContractStage } from '../../models/Entities/Contracts/ContractStage';
import { NewContractStage } from '../../models/Entities/Contracts/NewContractStage';


type KnownAction =
    | Actions.ContractStages.ContractStage_GetAllBycontractId_Request_Action
    | Actions.ContractStages.ContractStage_GetAllBycontractId_Receive_Action
    | Actions.ContractStages.ContractStage_GetAllBycontractId_Fail_Action
    | Actions.ContractStages.ContractStage_UpdateAllByContractId_Request_Action
    | Actions.ContractStages.ContractStage_UpdateAllByContractId_Receive_Action
    | Actions.ContractStages.ContractStage_UpdateAllByContractId_Fail_Action
    | Actions.ContractStages.ContractStage_GetById_Request_Action
    | Actions.ContractStages.ContractStage_GetById_Receive_Action
    | Actions.ContractStages.ContractStage_GetById_Fail_Action
    | Actions.ContractStages.ContractStage_Add_Request_Action
    | Actions.ContractStages.ContractStage_Add_Receive_Action
    | Actions.ContractStages.ContractStage_AddRange_Finalized_Action
    | Actions.ContractStages.ContractStage_Add_Fail_Action
    | Actions.ContractStages.ContractStage_Update_Request_Action
    | Actions.ContractStages.ContractStage_Update_Receive_Action
    | Actions.ContractStages.ContractStage_Update_Fail_Action
    | Actions.ContractStages.ContractStage_Delete_Request_Action
    | Actions.ContractStages.ContractStage_Delete_Receive_Action
    | Actions.ContractStages.ContractStage_Delete_Fail_Action;


export const actionCreators = {
    GetAllContractsStage:
        (contractId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'CONTRACTSTAGE_GET_ALL_BY_CONTRACTID_FAIL_ACTION',
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            if (state.Contract?.successLoadingAll && 
                state.Contract?.list?.find((contract: Contract) => contract.id === contractId)?.stages?.length === 0 ) {
                dispatch({
                    type: 'CONTRACTSTAGE_GET_ALL_BY_CONTRACTID_REQUEST_ACTION',
                    contractId: contractId
                });

                Services.GetContractsStages(contractId, token)
                    .then((contractStages: ContractStage[]) => {
                        dispatch({
                            type: 'CONTRACTSTAGE_GET_ALL_BY_CONTRACTID_RECEIVE_ACTION',
                            contractStages: contractStages,
                            contractId: contractId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTSTAGE_GET_ALL_BY_CONTRACTID_FAIL_ACTION',
                            error: error
                        })
                    );
            } else {
                dispatch({
                    type: 'CONTRACTSTAGE_UPDATE_ALL_BY_CONTRACTID_REQUEST_ACTION',
                    contractId: contractId
                });

                Services.GetContractsStages(contractId, token)
                    .then((contractStages: ContractStage[]) => {
                        dispatch({
                            type: 'CONTRACTSTAGE_UPDATE_ALL_BY_CONTRACTID_RECEIVE_ACTION',
                            contractStages: contractStages,
                            contractId: contractId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTSTAGE_UPDATE_ALL_BY_CONTRACTID_FAIL_ACTION',
                            error: error
                        })
                    );
            }
    },
    GetContractStageById:
        (id: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACTSTAGE_GET_BY_ID_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACTSTAGE_GET_BY_ID_REQUEST_ACTION',
                contractStageId: id
            });

            Services.GetContractStageById(id, token)
                .then((contractStage: ContractStage) => {
                    dispatch({
                        type: 'CONTRACTSTAGE_GET_BY_ID_RECEIVE_ACTION',
                        contractStage: contractStage
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACTSTAGE_GET_BY_ID_FAIL_ACTION',
                        error: error
                    })
                );

    },
    AddContractStage:
        (newContractStage: NewContractStage): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACTSTAGE_ADD_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACTSTAGE_ADD_REQUEST_ACTION',
                newContractStage: newContractStage
            });

            Services.CreateContractStage(newContractStage, token)
                .then((contractStage: ContractStage) => {
                    dispatch({
                        type: 'CONTRACTSTAGE_ADD_RECEIVE_ACTION',
                        contractStage: contractStage
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACTSTAGE_ADD_FAIL_ACTION',
                        error: error
                    })
                );

    },
    AddContractStageRange:
    (contractId: number, newContractStages: NewContractStage[]): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
        
        var state = getState();
        var token: string = '';

        if (state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACTSTAGE_ADD_FAIL_ACTION',
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };

        let StageCounter = 1;

        newContractStages.forEach((ContractStageItem : NewContractStage)  => {

            ContractStageItem.contractId = contractId;
            ContractStageItem.stageOrder = StageCounter;

            dispatch({
                type: 'CONTRACTSTAGE_ADD_REQUEST_ACTION',
                newContractStage: ContractStageItem
            });

            Services.CreateContractStage(ContractStageItem, token)
                .then((contractStage: ContractStage) => {
                    dispatch({
                        type: 'CONTRACTSTAGE_ADD_RECEIVE_ACTION',
                        contractStage: contractStage
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACTSTAGE_ADD_FAIL_ACTION',
                        error: error
                    })
                );
            
            StageCounter++;
        });

        dispatch({
            type: 'CONTRACTSTAGE_ADD_RANGE_FINALIZED_ACTION',
        })
    },
    UpdateContractStage:
        (contractStage: ContractStage): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACTSTAGE_UPDATE_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACTSTAGE_UPDATE_REQUEST_ACTION',
                contractStage: contractStage
            });
            Services.UpdateContractStage(contractStage, token)
                .then((contractStage: ContractStage) => {
                    dispatch({
                        type: 'CONTRACTSTAGE_UPDATE_RECEIVE_ACTION',
                        contractStage: contractStage
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACTSTAGE_UPDATE_FAIL_ACTION',
                        error: error
                    })
                );
    },
    DeleteContractStage:
        (contractId: number, id: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACTSTAGE_DELETE_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'CONTRACTSTAGE_DELETE_REQUEST_ACTION',
                contractId: contractId,
                contractStageId: id
            });

            Services.DeleteContractStage(id, token)
                .then((stageId: number) => {                     
                        dispatch({
                            type: 'CONTRACTSTAGE_DELETE_RECEIVE_ACTION',
                            contractId: contractId,
                            contractStageId: stageId
                        });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACTSTAGE_DELETE_FAIL_ACTION',
                        error: error
                    })
                );
    }
};
