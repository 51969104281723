import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractBranch } from "../../../../models/Entities/Contracts/ContractBranch";
import { ErrorType } from "../../../../models/HttpError";

// stores
import * as ContractBranchStore from '../../../../redux/middleware/ContractBranchsMiddleware';
import * as ContractStore from '../../../../redux/middleware/ContractMiddleware';

//State
import { AppState } from "../../../../redux/reducers";


interface IProps {
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    ContractId: number;
    ContractBranchId: number | undefined;
}
  
interface ConnectedProps {
    // loading
    isLoading: boolean;
    isLoadingSuccessfully: boolean;
    failOnLoading: boolean;

    // adding
    isAddingNewOne: boolean;
    isAddedSuccessfully: boolean;
    failOnAdding: boolean;

    // updating
    isupdatingNewOne: boolean;
    isUpdatedNewOneSuccessfully: boolean;
    failOnUpdating: boolean;

    error: ErrorType | undefined;

    Contract: Contract;
    Branch: ContractBranch;
}
  
interface DispatchProps {
    GetContractBranchById: typeof ContractBranchStore.actionCreators.GetContractBranchById;
    CreateContractBranch: typeof ContractBranchStore.actionCreators.CreateContractBranch;
    UpdateContractBranch: typeof ContractBranchStore.actionCreators.UpdateContractBranch;
    GetContractById: typeof ContractStore.actionCreators.GetContractById;
}
  
export type Props = IProps & ConnectedProps & DispatchProps;
  
export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.ContractBranch?.isLoadingAll,
    isUpdating: state.ContractBranch?.isLoadingAll,
    isUpdatedSuccessfully: state.ContractBranch?.isUpdatingAllSuccess,
    isLoadingSuccessfully: state.ContractBranch?.successLoadingAll,
    isAddingNewOne: state.ContractBranch?.isAddingNewOne,
    isAddedSuccessfully: state.ContractBranch?.successAddingNewOne,
    isupdatingNewOne: state.ContractBranch?.isUpdatingOne,
    isUpdatedNewOneSuccessfully: state.ContractBranch?.successUpdatingOne,
    failOnUpdating: state.ContractBranch?.failOnLoadingAll,
    failOnLoading: state.ContractBranch?.failOnLoadingAll,
    AddRangeFinalized: state.ContractBranch?.isAddingFinishedSuccessfully,
    isDeleting: state.ContractBranch?.isDeletingOne,
    isDeletedSuccessfully: state.ContractBranch?.successDeletingOne,
    failOnDeleting: state.ContractBranch?.failOnDeletingOne,
    error: state.ContractBranch?.error,
    Contract: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId ) : undefined,
    Branch: ownProps.ContractBranchId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId )?.branches?.find((Branch: ContractBranch) => Branch.id === ownProps.ContractBranchId ) : undefined
});
  
export const mapDispatchToProps = {
    ...ContractBranchStore.actionCreators,
    ...ContractStore.actionCreators
};