import {
    DefaultButton, FontWeights, getTheme, IIconProps, IStackProps,
    mergeStyleSets,  MessageBar,  MessageBarType, Panel, PanelType,
    PrimaryButton, Spinner, SpinnerSize, TextField
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ErrorMessageComponent } from '../../../Error/ErrorMessageComponent';
import { ContractBranch } from '../../../../models/Entities/Contracts/ContractBranch';
import { NewContractBranch } from '../../../../models/Entities/Contracts/NewContractBranch';

// component functions
import * as ComponentFunctions from './BranchForm.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';
  
interface FormValidation {
    success: boolean;
    errors: string[];
}
    
export const BranchFormComponent = (props: ComponentFunctions.Props) => {

const [submittedForm, setSubmittedForm] = useState<boolean>(false);
const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
>();

const [branchName, setBranchName] = useState<string>();
const [branchDescription, setBranchDescription] = useState<string>();

//useTranslation()
const [t] = useTranslation();

useEffect(() => {
    props.GetContractById(props.ContractId);
}, []);

useEffect(() => {
    props.ContractBranchId && props.GetContractBranchById(props.ContractBranchId);
}, [props.Contract !== undefined, props.ContractBranchId]);


useEffect(() => {
    
    setBranchName(props.Branch?.branchName);
    setBranchDescription(props.Branch?.branchDescription);

}, [props.Branch]);

// save control
useEffect(() => {   
    if (submittedForm && (props.isAddedSuccessfully || !props.isUpdatedNewOneSuccessfully)) {
        ResetForm();
        props.dismissPanel();
    }
}, [submittedForm, props.isAddedSuccessfully, props.isUpdatedNewOneSuccessfully]);


const ResetForm = () => {
    setBranchName(undefined);
    setBranchDescription(undefined);
};

// Events
const onChangeBranchNameTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
) => {
    setBranchName(newValue);
};

const onChangeBranchDescriptionTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
) => {
    setBranchDescription(newValue);
};

const HandleSaveForm = () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
    let CurrentValues: ContractBranch | NewContractBranch;
    if (props.Branch) {
      CurrentValues = {
        id: props.Branch.id,
        contractId: props.ContractId,
        branchName: branchName!,
        branchDescription: branchDescription!
      };
      props.UpdateContractBranch(CurrentValues as ContractBranch);
    } else {
      CurrentValues = {
        contractId: props.ContractId,
        branchName: branchName!,
        branchDescription: branchDescription!
      };
      props.CreateContractBranch(CurrentValues as NewContractBranch);
    }
    }
};

const HandleCancelForm = () => {
    ResetForm();
    props.dismissPanel();
};

// footer
const onRenderFooterContent = () => {
    return (
    <div className={contentStyles.footer}>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
         <Trans i18nKey={'branchForm.footer.cancelButton'}/>  
        </DefaultButton>
        <PrimaryButton
        onClick={HandleSaveForm}
        styles={buttonStyles}
        disabled={props.isAddingNewOne || props.isupdatingNewOne}
        className="ConfirmAction"
        >
        {props.isAddingNewOne || props.isupdatingNewOne? <><Spinner size={SpinnerSize.xSmall} />&nbsp;<span><Trans i18nKey={'branchForm.footer.saving'}/></span></> : 'Guardar'}
        </PrimaryButton>
    </div>
    );
};

const formFields = (
    <div {...columnProps}>
    <TextField
        label={t('branchForm.formFields.branchName')}
        required
        value={branchName}
        onChange={onChangeBranchNameTextFieldValue}
        errorMessage={
        submittedForm &&
        !branchName ?
         // 'Por favor ingrese el nombre de la rama' : ''
         t('branchForm.formFields.branchNameRequired') : ''
        }
    />
    <TextField
        label={t('branchForm.formFields.branchDescription')} 
        value={branchDescription}
        multiline={true}
        onChange={onChangeBranchDescriptionTextFieldValue}
    />  
    </div>
    
);

// form validation

const ValidateForm = (): boolean => {
    let validationResult: boolean = true;

    let FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
};

return (
    <Panel
    isOpen={props.isOpen}
    type={PanelType.medium}
    headerText={t('branchForm.headerText.branchInformation')} 
    onDismiss={props.dismissPanel}
    closeButtonAriaLabel="Close"
    onRenderFooter={onRenderFooterContent}
    isFooterAtBottom={true}
    >
    {/* <div className={contentStyles.header}>
        <span>Información del Proveedor</span>
        <IconButton
        styles={iconButtonStyles}
        iconProps={cancelIcon}
        ariaLabel="Close popup modal"
        onClick={props.dismissPanel}
        />
    </div> */}
    {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
        {t('branchForm.messageBar.reviewInformation')} 
        <ul>
            {FormValidationStatus?.errors.map(
            (Error: string, index: number) => (
                <li key={index}>{Error}</li>
            )
            )}
        </ul>
        </MessageBar>
    )}
    <div className={contentStyles.body}>
        {props.failOnAdding &&
        props.error && (
            <ErrorMessageComponent Error={props.error} />
        )}
        {formFields}
    </div>
    </Panel>
);
};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(BranchFormComponent as any);

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
tokens: { childrenGap: 15 }
// styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
},
header: [
    theme.fonts.mediumPlus,
    {
    flex: '1 1 auto',
    color: theme.palette.neutralPrimary,
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '0px 12px 12px 12px',
    backgroundColor: 'lightgray',
    borderRadius: '10px 10px 0 0'
    }
],
body: {
    flex: '4 4 auto',
    overflowY: 'auto',
    selectors: {
    'p:first-child': { marginTop: 0 },
    'p:last-child': { marginBottom: 0 }
    },
    padding: '0 1em 0 0',
    height: '85vh'
},
footer: {
    //flex: '4 4 auto',
    padding: '0 24px 24px 24px'
}
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
},
rootHovered: {
    color: theme.palette.neutralDark
}
};